/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import fuzzysort from 'fuzzysort'

/* Module imports ----------------------------------------------------------- */
import { handleNumberVerification } from 'helpers/numberUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Menu,
  MenuItem,
} from '@mui/material'
import {
  DragIndicatorRounded,
  MoreVertRounded,
} from '@mui/icons-material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import { Draggable } from 'react-beautiful-dnd'
import NumberField from 'components/FieldWithInputAdornment/NumberField'
import PriceField from 'components/FieldWithInputAdornment/PriceField'

/* Type imports ------------------------------------------------------------- */
import type { GexsiWebAssembly } from 'helpers/webAssembly'
import type {
  InvoiceLineRequest,
  ValueFields,
} from 'types/QuoteInvoice'
import type {
  Bordereau,
  CodeLabel,
  TauxTVA,
} from 'API/__generated__/Api'
import type { InvoiceForm } from '../InvoicePage'

/* Styled components -------------------------------------------------------- */
interface GridContainerProps {
  grid?: 'title';
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: 40px 120px 90px ${(props) => props.grid === 'title' ? '8.2fr' : '2fr repeat(6, 1fr)'} 20px;
  gap: 5px;
  padding: 10px 10px 10px 0px;
  align-items: center;
`

const CardLineContainer = styled(Card)`
  margin-bottom: 5px;
`

const IconButton = styled(Button)`
  align-self: center;
  min-width: auto;
  width: 20px !important;
`

const MenuOverflow = styled(Menu)`
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    overflow-x: auto;
    max-height: 30vh;
  }
`

const Table = styled.table`
  padding: 0px 10px;
  border-spacing: 0px;

  thead {
    td {
      font-weight: bold;
    }
  }

  td {
    border: 1px solid lightgray;
    padding: 5px 10px;
  }
`

const TableRow = styled.tr`
  font-size: 14px;
  height: 30px;

  &:hover {
    background-color: lightgray;
    cursor: pointer;
  }

  td:first-of-type {
    font-weight: bold;
    color: ${(props) => props.theme.palette.primary.main};
  }
`

/* Component declaration ---------------------------------------------------- */
interface InvoiceLineProps {
  formikForm: InvoiceForm;
  tvaRateList: TauxTVA[];
  lineTypeList: CodeLabel[];
  measureUnitList: CodeLabel[];
  updateCounter: () => void;
  index: number;
  calculate: GexsiWebAssembly | null;
  articleBorderauList: Bordereau[];
  diverseBorderauList: Bordereau[];
}

const InvoiceLine: React.FC<InvoiceLineProps> = ({
  formikForm,
  tvaRateList,
  lineTypeList,
  measureUnitList,
  updateCounter,
  index,
  calculate,
  articleBorderauList,
  diverseBorderauList,
}) => {
  const [ anchorLineMenu, setAnchorLineMenu ] = useState<null | SVGElement>(null)
  const [ anchorBordereauMenu, setAnchorBordereauMenu ] = useState<null | HTMLElement>(null)
  const [ results, setResults ] = useState<Bordereau[]>([])

  const filterOptions = (options: Bordereau[], inputValue: string) => {
    const opt = {
      limit: 100, // don't return more results than you need!
      threshold: -100, // don't return bad results
      all: true,
      keys: [ 'code', 'libelle', 'description' ],
    }
    const fuzzyResults = fuzzysort.go(inputValue, options, opt)
    const results = fuzzyResults.map(({ obj }) => obj)

    setResults(results)
    return results
  }

  const searchBordereau = (value: string) => {
    formikForm.setFieldValue(`lignesFacture[${index}].codeArticle`, value)

    if (value && value.length > 2) {
      if (formikForm.values.lignesFacture?.[index].type === 'A') {
        filterOptions(articleBorderauList, value)
      } else {
        filterOptions(diverseBorderauList, value)
      }
      const field = document.getElementById(`lignesFacture[${index}].codeArticle`)
      setAnchorBordereauMenu(field)
    }
  }

  const handleBordereauMenuClose = () => {
    setAnchorBordereauMenu(null)
  }

  const handleLineMenuClick = (event: React.MouseEvent<SVGElement>): void => {
    event.stopPropagation()
    setAnchorLineMenu(event.currentTarget)
  }

  const handleLineMenuClose = (): void => {
    setAnchorLineMenu(null)
  }

  const handleLineValueChange = (type: ValueFields, value: string, lineIndex: number): void => {
    if (calculate === null || !formikForm.values.lignesFacture?.[lineIndex]) {
      return
    }
    const newValue = handleNumberVerification(value, 2)
    const valeur = formikForm.values.lignesFacture[lineIndex]

    const modifyAmount = (quantity: number = 0, unitPrice: number = 0, rate: number = 0): void => {
      formikForm.setFieldValue(`lignesFacture[${lineIndex}].prixHT`, calculate.getMontantHT(quantity, unitPrice).toFixed(2))
      formikForm.setFieldValue(`lignesFacture[${lineIndex}].prixTTC`, calculate.getMontantTTC(quantity, unitPrice, rate).toFixed(2))
    }

    if (type === 'tva') {
      const newRate = tvaRateList.find((rate) => rate.tva.code === value)
      if (newRate === undefined) {
        return
      }
      formikForm.setFieldValue(`lignesFacture[${lineIndex}].tva`, newRate.tva.code)
      modifyAmount(valeur.quantite || 0, valeur.prixUnitaire || 0, newRate.taux)
    }

    const rate = tvaRateList.find((rate) => rate.tva.code === valeur.tva)?.taux || 0

    if (type === 'quantite') {
      formikForm.setFieldValue(`lignesFacture[${lineIndex}].quantite`, newValue)
      modifyAmount(newValue, valeur.prixUnitaire || 0, rate)
    }
    if (type === 'prixUnitaire') {
      formikForm.setFieldValue(`lignesFacture[${lineIndex}].prixUnitaire`, newValue)
      modifyAmount(valeur.quantite || 0, newValue, rate)
    }

    updateCounter()
  }

  const onSelectBordereauClick = (bordereau: Bordereau) => {
    const newLine: InvoiceLineRequest = {
      ...structuredClone(formikForm.values.lignesFacture?.[index] as InvoiceLineRequest),
      codeArticle: bordereau.code,
      libelle: formikForm.values.lignesFacture?.[index].type === 'A' ? bordereau.description : bordereau.libelle,
      prixUnitaire: bordereau.prixUnitaire,
      type: formikForm.values.lignesFacture?.[index].type ?? 'A',
      disabled: true,
      quantite: 1,
    }

    formikForm.setFieldValue(`lignesFacture[${index}]`, newLine)
    handleBordereauMenuClose()
    updateCounter()
  }

  const getArticleTitle = () => {
    if (formikForm.values.lignesFacture?.[index].type === 'COM') return 'Commentaire'
    if (formikForm.values.lignesFacture?.[index].type === 'TI') return 'Titre'
    if (formikForm.values.lignesFacture?.[index].type === 'ST') return 'Sous-titre'
    return 'Article'
  }

  const onDuplicateClick = () => {
    if (!formikForm.values.lignesFacture?.length) return
    const newArray = [ ...formikForm.values.lignesFacture ]
    const duplicateItem = { ...formikForm.values.lignesFacture[index] }

    newArray.splice(index + 1, 0, duplicateItem)

    formikForm.setFieldValue('lignesFacture', newArray)
    handleLineMenuClose()
  }

  const onDeleteClick = () => {
    if (!formikForm.values.lignesFacture?.length) return
    const newArray = [ ...formikForm.values.lignesFacture ]

    newArray.splice(index, 1)

    formikForm.setFieldValue('lignesFacture', newArray)
    handleLineMenuClose()
  }

  return (
    <Draggable
      key={`drag-${index}`}
      draggableId={`drag-${index}`}
      index={index}
    >
      {
        (provided) => (
          <CardLineContainer
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <GridContainer grid={
              formikForm.values.lignesFacture?.[index].type === 'COM' ||
              formikForm.values.lignesFacture?.[index].type === 'ST' ||
              formikForm.values.lignesFacture?.[index].type === 'TI' ?
                'title' :
                undefined
            }
            >
              <DragIndicatorRounded
                fontSize="large"
                color="secondary"
              />
              <Field
                component={Select}
                name={`lignesFacture[${index}].type`}
                displayEmpty
                onBlur={updateCounter}
                size="small"
              >
                {
                  lineTypeList.map((value, index) => (
                    <MenuItem
                      value={value.code}
                      key={`${value.code}-${index}`}
                    >
                      {value.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
              {
                formikForm.values.lignesFacture?.[index].type !== 'TST' && formikForm.values.lignesFacture?.[index].type !== 'TTI' ?
                  <Field
                    component={TextField}
                    id={`lignesFacture[${index}].codeArticle`}
                    name={`lignesFacture[${index}].codeArticle`}
                    value={formikForm.values.lignesFacture?.[index].codeArticle || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchBordereau(e.target.value)}
                    placeholder="Code"
                    size="small"
                  /> :
                  <div />
              }
              <Field
                component={TextField}
                name={`lignesFacture[${index}].libelle`}
                placeholder={getArticleTitle()}
                size="small"
                onBlur={updateCounter}
                disabled={formikForm.values.lignesFacture?.[index].type === 'TST' || formikForm.values.lignesFacture?.[index].type === 'TTI' || formikForm.values.lignesFacture?.[index].disabled}
              />
              {
                formikForm.values.lignesFacture?.[index].type === 'A' &&
                  <React.Fragment>
                    <NumberField
                      name={`lignesFacture[${index}].quantite`}
                      value={formikForm.values.lignesFacture?.[index].quantite}
                      onChange={(e) => handleLineValueChange('quantite', e.target.value, index)}
                      size="small"
                    />
                    <Field
                      component={Select}
                      name={`lignesFacture[${index}].unite`}
                      displayEmpty
                      size="small"
                      disabled={formikForm.values.lignesFacture?.[index].disabled}
                    >
                      {
                        measureUnitList.map((value, index) => (
                          <MenuItem
                            value={value.code}
                            key={`${value.code}-${index}`}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                    <PriceField
                      name={`lignesFacture[${index}].prixUnitaire`}
                      onChange={(e) => handleLineValueChange('prixUnitaire', e.target.value, index)}
                      disabled={formikForm.values.lignesFacture?.[index].disabled}
                      size="small"
                    />
                    <PriceField
                      name={`lignesFacture[${index}].prixHT`}
                      size="small"
                      disabled
                    />
                    <Field
                      component={Select}
                      name={`lignesFacture[${index}].tva`}
                      value={formikForm.values.lignesFacture[index].tva || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleLineValueChange('tva', e.target.value, index)}
                      displayEmpty
                      size="small"
                    >
                      {
                        !formikForm.values.lignesFacture[index].tva &&
                          <MenuItem value="">
                            Sélectionner
                          </MenuItem>
                      }
                      {
                        tvaRateList.map((value, index) => (
                          <MenuItem
                            value={value.tva.code}
                            key={`${value.tva.code}-${index}`}
                          >
                            {value.tva.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                    <PriceField
                      name={`lignesFacture[${index}].prixTTC`}
                      size="small"
                      disabled
                    />
                  </React.Fragment>
              }
              {
                (formikForm.values.lignesFacture?.[index].type === 'TST' || formikForm.values.lignesFacture?.[index].type === 'TTI') &&
                  <React.Fragment>
                    <div />
                    <div />
                    <div />
                    <PriceField
                      name={`lignesFacture[${index}].prixHT`}
                      size="small"
                      disabled
                    />
                    <div />
                    <PriceField
                      name={`lignesFacture[${index}].prixTTC`}
                      disabled
                      size="small"
                    />
                  </React.Fragment>
              }
              <IconButton
                variant="text"
                size="small"
              >
                <MoreVertRounded
                  fontSize="large"
                  onClick={handleLineMenuClick}
                />
              </IconButton>
              <MenuOverflow
                anchorEl={anchorBordereauMenu}
                open={Boolean(anchorBordereauMenu)}
                onClose={handleBordereauMenuClose}
                disableAutoFocus
              >
                {
                  formikForm.values.lignesFacture?.[index].type === 'A' ?
                    <Table>
                      <thead>
                        <tr>
                          <td>
                            Article
                          </td>
                          <td>
                            Prix Unitaire
                          </td>
                          <td>
                            Description
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          results.map((bordereau, index) => (
                            <TableRow
                              key={`${bordereau.code}-${index}`}
                              onClick={() => onSelectBordereauClick(bordereau)}
                            >
                              <td>
                                {bordereau.code}
                              </td>
                              <td>
                                {`${bordereau.prixUnitaire?.toFixed(2)}€`}
                              </td>
                              <td>
                                {bordereau.description}
                              </td>
                            </TableRow>
                          ))
                        }
                      </tbody>
                    </Table> :
                    <Table>
                      <thead>
                        <tr>
                          <td>
                            Code
                          </td>
                          <td>
                            Description
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          results.map((bordereau, index) => (
                            <TableRow
                              key={`${bordereau.code}-${index}`}
                              onClick={() => onSelectBordereauClick(bordereau)}
                            >
                              <td>
                                {bordereau.code}
                              </td>
                              <td>
                                {bordereau.libelle}
                              </td>
                            </TableRow>
                          ))
                        }
                      </tbody>
                    </Table>
                }
              </MenuOverflow>
              <Menu
                anchorEl={anchorLineMenu}
                open={Boolean(anchorLineMenu)}
                onClose={handleLineMenuClose}
                onClick={(e): void => e.stopPropagation()}
              >
                <MenuItem onClick={onDuplicateClick}>
                  Dupliquer
                </MenuItem>
                <MenuItem onClick={onDeleteClick}>
                  Supprimer
                </MenuItem>
              </Menu>
            </GridContainer>
          </CardLineContainer>
        )
      }
    </Draggable>
  )
}

export default InvoiceLine
