/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostMeasureRdvMutation,
  usePatchMeasureRdvMutation,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'
import {
  isApiError,
  type ApiResponse,
} from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Dialog } from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  RendezVousMetreur,
  RendezVousMetreurRequest,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type RdvMetreur = RendezVousMetreurRequest

const rdvSchema = Yup.object().shape<Shape<RdvMetreur>>({
  dateDebut: Yup.string().nullable().required('La date de début est obligatoire'),
  dateFin: Yup.string().nullable()
    .test('dateDebut',
      "L'heure de fin ne peut pas être inférieure à celle de début",
      (dateFin = '', { parent }: {parent: RdvMetreur}) => {
        const dates = DateUtils.formatStartEndDate({ dateDebut: parent.dateDebut, dateFin: dateFin || '' })
        return new Date(dates.dateFin) > new Date(dates.dateDebut)
      },
    ).required('La date de fin est obligatoire'),
}).required()

type RdvForm = FormikContextType<RdvMetreur>

/* Internal variables ------------------------------------------------------- */
const initialDay: RendezVousMetreurRequest = {
  dateDebut: new Date().toISOString(),
  dateFin: new Date().toISOString(),
}

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowMeasureRdvButtonProps {
  caseId: string;
  initialRdv?: RendezVousMetreur | null;
}

const CaseWorkflowMeasureRdvButton: React.FC<CaseWorkflowMeasureRdvButtonProps> = ({ caseId, initialRdv }) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)

  const [
    submitNewRdv,
  ] = usePostMeasureRdvMutation()
  const [
    submitEditRdv,
  ] = usePatchMeasureRdvMutation()

  const onSubmit = (values: RdvMetreur, { setSubmitting, resetForm }: FormikHelpers<RdvMetreur>) => {
    const onFinish = (response: ApiResponse<void>) => {
      if (!isApiError(response)) {
        setOpen(false)
        setSubmitting(false)
        resetForm()
      } else {
        setSubmitting(false)
      }
    }

    if (initialRdv) {
      submitEditRdv({
        id: initialRdv.id,
        data: DateUtils.formatStartEndDate(values),
      }).then(onFinish).catch(console.error)
    } else {
      submitNewRdv({
        caseId,
        data: DateUtils.formatStartEndDate(values),
      }).then(onFinish).catch(console.error)
    }
  }

  const formikForm: RdvForm = useForm<RdvMetreur>(
    {
      initialValues: initialDay,
      onSubmit: onSubmit,
      validationSchema: rdvSchema,
    },
  )

  useEffect(() => {
    if (!initialRdv) return
    formikForm.setValues(initialRdv)
  }, [ initialRdv ])

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CaseWorkflowStyledComponents.Button
        variant="contained"
        onClick={onClick}
        disabled={isReadOnly}
      >
        {initialRdv ? 'Modifier le RDV' : 'Choisir une date de RDV'}
      </CaseWorkflowStyledComponents.Button>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xl"
      >
        <CaseWorkflowStyledComponents.DialogTitle>
          {initialRdv ? 'Modifier Rdv métré' : 'Nouveau Rdv métré' }
          <CloseButton handleClose={onClose} />
        </CaseWorkflowStyledComponents.DialogTitle>
        <Form form={formikForm}>
          <CaseWorkflowStyledComponents.DialogContent>
            <FormBoldTitle required>
              Date
            </FormBoldTitle>
            <FormikDatePicker name="dateDebut" />
            <GridContainer>
              <div>
                <FormBoldTitle required>
                  Heure de début
                </FormBoldTitle>
                <FormikDatePicker
                  name="dateDebut"
                  time
                />
              </div>
              <div>
                <FormBoldTitle required>
                  Heure de fin
                </FormBoldTitle>
                <FormikDatePicker
                  name="dateFin"
                  time
                />
              </div>
            </GridContainer>
          </CaseWorkflowStyledComponents.DialogContent>
          <CaseWorkflowStyledComponents.DialogAction>
            <LongButton
              variant="outlined"
              onClick={onClose}
            >
              Annuler
            </LongButton>
            <LongButton
              type="submit"
              variant="contained"
              disabled={formikForm.isSubmitting}
            >
              Valider
            </LongButton>
          </CaseWorkflowStyledComponents.DialogAction>
        </Form>
      </Dialog>
    </>
  )
}

export default CaseWorkflowMeasureRdvButton
