/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowWorksRdvButton from '../CaseWorflowButtons/CaseWorkflowWorksRdvButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonRendezVousTravaux } from 'API/__generated__/Api'
import { WorkflowEtat } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowWorksRdvJalonCardProps {
  jalon: JalonRendezVousTravaux;
  caseId: string;
}

const CaseWorkflowWorksRdvJalonCard: React.FC<CaseWorkflowWorksRdvJalonCardProps> = ({
  jalon,
  caseId,
}) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          RDV travaux
        </CaseWorkflowStyledComponents.Title>
        {
          jalon.etat === WorkflowEtat.EnAttente ?
            <CaseWorkflowWorksRdvButton
              caseId={caseId}
              initialValues={jalon.rendezVousTravaux}
            /> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.rendezVousTravaux[0]?.dateDebut || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowWorksRdvJalonCard
