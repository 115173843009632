/* Framework imports -------------------------------------------------------- */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseInfosQuery,
  useGetDisasterNatureListQuery,
  useGetIncreasedRiskListQuery,
  useGetSinappsSelectListQuery,
  usePostSinappsReportMutation,
} from 'store/api'
import { isApiResponse } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import { toast } from 'react-toastify'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import LongButton from 'components/LongButton/LongButton'
import CheckableButton from 'components/CheckableButton/CheckableButton'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  RefDossier,
  CompteRenduVisiteSinappsCreatePayload,
  SinappsSinistreRequest,
  QualificationDDERequest,
  TauxHumidite,
  SinappsRisqueRequest,
  SuppressionCause,

  RisqueAggraveIdentifie,
} from 'API/__generated__/Api'
import { EnumerationsMetier } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const sinappsReportSchema = Yup.object().shape<Shape<CompteRenduVisiteSinappsCreatePayload>>({
  sinistre: Yup.object().shape<Shape<SinappsSinistreRequest>>({
    sinistreCorrect: Yup.boolean(),
    dateSinistre: Yup.string().nullable(),
    heure: Yup.string().nullable(),
    natureSinistre: Yup.string().nullable(),
    causeSinistre: Yup.string().nullable(),
    reserve: Yup.string().nullable(),
    qualificationDDERequest: Yup.object().shape<Shape<QualificationDDERequest>>({
      tauxHumidite: Yup.object().shape<Shape<TauxHumidite>>({
        assechementEngage: Yup.boolean(),
        valeur: Yup.number(),
        localisationDuReleve: Yup.string(),
      }).required(),
      suppressionCause: Yup.object().shape<Shape<SuppressionCause>>({
        motif: Yup.string().nullable(),
        precision: Yup.string().nullable(),
        dateReparation: Yup.string(),
        causeSupprimee: Yup.boolean(),
      }),
    }),
  }).required(),
  risque: Yup.object().shape<Shape<SinappsRisqueRequest>>({
    risqueCorrect: Yup.boolean(),
    typeRisque: Yup.string().nullable(),
    usageAssure: Yup.string().nullable(),
    relationAuRisque: Yup.string().nullable(),
    incoherenceRisqueDeclare: Yup.string().nullable(),
  }),
  risquesAggravesIdentifies: Yup.array(Yup.mixed<RisqueAggraveIdentifie>()).nullable(),
}).required()

type NewSinappsReportForm = FormikContextType<CompteRenduVisiteSinappsCreatePayload>

/* Styled components -------------------------------------------------------- */
const CardContentContainer = styled.div`
  padding: 0px 20px 20px;
`

const BoldTitle = styled(FormBoldTitle)`
  font-size: 1.15rem;
  margin-bottom: 15px;
`

const CardContainer = styled(Card)`
  margin-top: 20px;
`

const InfoContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`

const FieldContainer = styled.div`
  width: 100%;
`

const DuoButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column-reverse;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: stretch;
  justify-content: stretch;

  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 1fr;
  }
`

/* Component declaration ---------------------------------------------------- */
interface SinappsReportPageProps {}

const SinappsReportPage: React.FC<SinappsReportPageProps> = () => {
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()

  const { currentData: caseInfos } = useGetCaseInfosQuery(caseId)
  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterNatureListQuery(caseId)
  const {
    currentData: riskTypeList = [],
    isFetching: isFetchingRiskTypeList,
  } = useGetSinappsSelectListQuery({ caseId, listType: EnumerationsMetier.TypeRisque })
  const {
    currentData: riskUsageList = [],
    isFetching: isFetchingRiskUsageList,
  } = useGetSinappsSelectListQuery({ caseId, listType: EnumerationsMetier.TypeUsageAssure })
  const {
    currentData: riskRelationList = [],
    isFetching: isFetchingRiskRelationList,
  } = useGetSinappsSelectListQuery({ caseId, listType: EnumerationsMetier.RelationAuRisque })
  const {
    currentData: increasedRiskList = [],
    isFetching: isFetchingIncreasedRiskList,
  } = useGetIncreasedRiskListQuery()
  const [
    submitNewSinappsReport,
  ] = usePostSinappsReportMutation()

  const onSubmit = async (values: CompteRenduVisiteSinappsCreatePayload, { setSubmitting, resetForm }: FormikHelpers<CompteRenduVisiteSinappsCreatePayload>): Promise<void> => {
    const data = structuredClone(values)
    const response = await submitNewSinappsReport({ caseId, data })

    if (isApiResponse<RefDossier>(response)) {
      toast.success('Le compte-rendu à bien été envoyé.')
      resetForm()
    } else {
      toast.error("Une erreur est survenue lors de l'envoi du compte-rendu.")
    }
    setSubmitting(false)
  }

  const formikForm: NewSinappsReportForm = useForm<CompteRenduVisiteSinappsCreatePayload>(
    {
      initialValues: {
        risque: {
          risqueCorrect: true,
          incoherenceRisqueDeclare: '',
          relationAuRisque: '',
          typeRisque: '',
          usageAssure: '',
        },
        risquesAggravesIdentifies: [],
        sinistre: {
          sinistreCorrect: true,
          causeSinistre: '',
          dateSinistre: '',
          heure: '',
          natureSinistre: '',
          qualificationDDERequest: {
            suppressionCause: {
              motif: '',
              precision: '',
              dateReparation: '',
              causeSupprimee: false,
            },
            tauxHumidite: {
              assechementEngage: false,
              localisationDuReleve: '',
              valeur: 0,
            },
          },
          reserve: '',
        },
      },
      onSubmit: onSubmit,
      validationSchema: sinappsReportSchema,
    },
  )

  useEffect(() => {
    if (caseInfos) {
      formikForm.setFieldValue('sinistre.dateSinistre', caseInfos.dateSinistre)
      formikForm.setFieldValue('risque.typeRisque', caseInfos.risque?.typeRisque || '')
      formikForm.setFieldValue('risque.usageAssure', caseInfos.risque?.usageRisque || '')
    }
  }, [ caseInfos ])

  useEffect(() => {
    formikForm.setFieldValue('sinistre.causeSinistre', '')
  }, [ formikForm.values.sinistre.natureSinistre ])

  const onRisqueClick = (value: string, checked: boolean) => {
    if (checked) {
      formikForm.setFieldValue('risquesAggravesIdentifies', [ ...formikForm.values.risquesAggravesIdentifies || [], value ])
    } else {
      formikForm.setFieldValue('risquesAggravesIdentifies', formikForm.values.risquesAggravesIdentifies?.filter((v) => v as string !== value))
    }
  }

  const booleanOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Déposer un compte-rendu
        <DuoButtonContainer>
          <LongButton
            variant="outlined"
            onClick={() => navigate(-1)}
          >
            Annuler
          </LongButton>
          <LongButton
            variant="contained"
            type="submit"
          >
            Valider
          </LongButton>
        </DuoButtonContainer>
      </LargeTitle>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Sinsitre contasté
          </BoldTitle>
          <FieldContainer>
            <FormBoldTitle
              smaller
              required
            >
              Les éléments du sinistre sont-ils corrects ?
            </FormBoldTitle>
            <SegmentedButtons
              options={booleanOptions}
              selectedOption={formikForm.values.sinistre.sinistreCorrect}
              setSelectedOption={(newVal) => formikForm.setFieldValue('sinistre.sinistreCorrect', newVal)}
              smaller
            />
          </FieldContainer>
          {
            !formikForm.values.sinistre.sinistreCorrect &&
              <>
                <InfoContainer>
                  <FieldContainer>
                    <FormBoldTitle
                      smaller
                      required
                    >
                      Date du sinistre
                    </FormBoldTitle>
                    <FormikDatePicker
                      name="sinistre.dateSinistre"
                      size="small"
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <FormBoldTitle smaller>
                      Heure du sinistre
                    </FormBoldTitle>
                    <FormikDatePicker
                      time
                      name="sinistre.heureSinistre"
                      size="small"
                    />
                  </FieldContainer>
                </InfoContainer>
                <InfoContainer>
                  <FieldContainer>
                    <FormBoldTitle
                      smaller
                      required
                    >
                      Nature du sinistre
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      name="sinistre.natureSinistre"
                      displayEmpty
                      disabled={isFetchingDisasterNatureList}
                      size="small"
                    >
                      <MenuItem value="">
                        Sélectionner
                      </MenuItem>
                      {
                        disasterNatureList.map((value, index) => (
                          <MenuItem
                            value={value.code || ''}
                            key={`${value.code}-${index}`}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </FieldContainer>
                  <FieldContainer>
                    <FormBoldTitle smaller>
                      Cause du sinistre
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      name="sinistre.causeSinistre"
                      displayEmpty
                      disabled={isFetchingDisasterNatureList || !formikForm.values.sinistre.natureSinistre}
                      size="small"
                    >
                      <MenuItem value="">
                        Sélectionner
                      </MenuItem>
                      {
                        disasterNatureList.find((value) => value.code === formikForm.values.sinistre.natureSinistre)?.nestedCodeLabels?.map((value, index) => (
                          <MenuItem
                            value={value.code || ''}
                            key={`${value.code}-${index}`}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </FieldContainer>
                </InfoContainer>
                <FieldContainer>
                  <FormBoldTitle smaller>
                    Réserve
                  </FormBoldTitle>
                  <Field
                    component={TextField}
                    placeholder="Réserve"
                    name="sinistre.reserve"
                    multiline
                    rows={3}
                    size="small"
                  />
                </FieldContainer>
              </>
          }
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Risque contasté
          </BoldTitle>
          <FieldContainer>
            <FormBoldTitle
              smaller
              required
            >
              Les éléments du risque sont-ils corrects ?
            </FormBoldTitle>
            <SegmentedButtons
              options={booleanOptions}
              selectedOption={formikForm.values.risque.risqueCorrect}
              setSelectedOption={(newVal) => formikForm.setFieldValue('risque.risqueCorrect', newVal)}
              smaller
            />
          </FieldContainer>
          {
            !formikForm.values.risque.risqueCorrect &&
              <>
                <InfoContainer>
                  <FieldContainer>
                    <FormBoldTitle smaller>
                      Type de risque
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      name="risque.typeRisque"
                      displayEmpty
                      disabled={isFetchingRiskTypeList}
                      size="small"
                    >
                      <MenuItem value="">
                        Sélectionner
                      </MenuItem>
                      {
                        riskTypeList.map((value, index) => (
                          <MenuItem
                            value={value.code || ''}
                            key={`${value.code}-${index}`}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </FieldContainer>
                  <FieldContainer>
                    <FormBoldTitle smaller>
                      Usage du risque
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      name="risque.usageAssure"
                      displayEmpty
                      disabled={isFetchingRiskUsageList}
                      size="small"
                    >
                      <MenuItem value="">
                        Sélectionner
                      </MenuItem>
                      {
                        riskUsageList.map((value, index) => (
                          <MenuItem
                            value={value.code || ''}
                            key={`${value.code}-${index}`}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </FieldContainer>
                  <FieldContainer>
                    <FormBoldTitle smaller>
                      Relation au risque
                    </FormBoldTitle>
                    <Field
                      component={Select}
                      name="risque.relationAuRisque"
                      displayEmpty
                      disabled={isFetchingRiskRelationList}
                      size="small"
                    >
                      <MenuItem value="">
                        Sélectionner
                      </MenuItem>
                      {
                        riskRelationList.map((value, index) => (
                          <MenuItem
                            value={value.code || ''}
                            key={`${value.code}-${index}`}
                          >
                            {value.libelle}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  </FieldContainer>
                </InfoContainer>
                <FieldContainer>
                  <FormBoldTitle
                    smaller
                    required
                  >
                    Signaler une incohérence sur le risque déclaré
                  </FormBoldTitle>
                  <Field
                    component={TextField}
                    placeholder="Signaler une incohérence"
                    name="risque.incoherenceRisqueDeclare"
                    multiline
                    rows={3}
                    size="small"
                  />
                </FieldContainer>
              </>
          }
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Risque aggravé identifié
          </BoldTitle>
          <FieldContainer>
            <FormBoldTitle smaller>
              Sélectionner le(s) risque(s) aggravé(s)
            </FormBoldTitle>
            <Grid>
              {
                increasedRiskList.map((value, index) => (
                  <CheckableButton
                    key={`${value.code}-${index}`}
                    checked={formikForm.values.risquesAggravesIdentifies?.some((v) => v as string === value.code) || false}
                    label={value.libelle}
                    onChange={(e, c) => onRisqueClick(value.code, c)}
                    disabled={isFetchingIncreasedRiskList}
                  />
                ))
              }
            </Grid>
          </FieldContainer>
        </CardContentContainer>
      </CardContainer>
      <Footer />
    </Form>
  )
}

export default SinappsReportPage
