/* Framework imports -------------------------------------------------------- */
import { useMemo } from 'react'
import * as Redux from 'react-redux'

/* Module imports ----------------------------------------------------------- */
import {
  persistor,
  store,
} from 'store/store'
import { getAuthFromLocalStorage } from 'helpers/localStorageHelpers'
import {
  setAuthInfo,
  selectAuthInfo,
  resetAuthInfo,
} from './slices/authSlice'

/* Type imports ------------------------------------------------------------- */
import type {
  RootState,
  AppDispatch,
} from 'store/store'
import type { Nullable } from 'types/Nullable'
import type { Login } from './slices/authSlice'

/* Generic Redux store hooks ------------------------------------------------ */
export const useAppDispatch: () => AppDispatch = Redux.useDispatch
export const useAppSelector: Redux.TypedUseSelectorHook<RootState> = Redux.useSelector

/* Specific Redux store hooks ----------------------------------------------- */
export const useAuthInfo = (): Nullable<Login> | null => {
  const dispatch = useAppDispatch()

  const lLoginInfo: Nullable<Login> = useAppSelector(
    selectAuthInfo,
    (pLeft: Nullable<Login>, pRight: Nullable<Login>): boolean => pLeft.token === pRight.token,
  )

  return useMemo<Nullable<Login> | null>((): Nullable<Login> | null => {
    if (lLoginInfo.token === null) {
      const lLocalStorageAuthInfo = getAuthFromLocalStorage()

      if (lLocalStorageAuthInfo !== null) {
        dispatch(setAuthInfo(lLocalStorageAuthInfo))
        return lLocalStorageAuthInfo
      }

      return null
    }

    return lLoginInfo
  }, [ lLoginInfo, dispatch ])
}

export const useIsReadOnly = (): boolean => {
  const dispatch = useAppDispatch()

  const lLoginInfo: Nullable<Login> = useAppSelector(
    selectAuthInfo,
    (pLeft: Nullable<Login>, pRight: Nullable<Login>): boolean => pLeft.lectureSeule === pRight.lectureSeule,
  )

  return useMemo<boolean>((): boolean => {
    if (lLoginInfo.token === null) {
      const lLocalStorageAuthInfo = getAuthFromLocalStorage()

      if (lLocalStorageAuthInfo !== null) {
        dispatch(setAuthInfo(lLocalStorageAuthInfo))
        return lLocalStorageAuthInfo.lectureSeule
      }

      return false
    }

    return lLoginInfo.lectureSeule || false
  }, [ lLoginInfo, dispatch ])
}

export const onLogoutReset = () => {
  persistor.pause()
  persistor.flush().then(() => {return persistor.purge()}).catch(console.error)
  store.dispatch(resetAuthInfo())
}
