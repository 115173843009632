/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  useGetCaseWorkflowQuery,
  useGetIssuesListQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import CaseWorkflowJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflowJalonCard'
import CaseWorkflowWorkEndJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow8WorkDoneJalonCard'
import CaseWorkflowDot from './CaseWorkflowComponents/CaseWorkflowDot'
import CaseWorkflowInterventionJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow2InterventionJalonCard'
import CaseWorkflowPostInterventionJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow3PostInterventionJalonCard'
import CaseWorkflowMissionJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow1MissionJalonCard'
import CaseWorkflowMeasureJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow5PostMeasureJalonCard'
import CaseWorkflowContactJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow2ContactJalonCard'
import CaseWorkflowMeasureRdvJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow3MeasureRdvJalonCard'
import CaseWorkflowWorksRdvJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow7WorksRdvJalonCard'
import CaseWorkflowIssueJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflowIssueJalonCard'
import CaseWorkflowReportIssueButton from './CaseWorkflowComponents/CaseWorflowButtons/CaseWorkflowReportIssueButton'

/* Type imports ------------------------------------------------------------- */
import {
  WorkflowEtat,
  EtatMissionnement,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface LineContainerProps {
  last?: boolean;
}

const LineContainer = styled.div<LineContainerProps>`
  display: flex;
  align-items: center;
  border-left: 2px solid ${(props) => props.theme.colors.grey};
  margin-left: 10px;
  padding-left: 20px;
  padding-bottom: ${(props) => props.last ? '0px' : '30px'};

  .MuiPaper-root {
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowPageProps {}

const CaseWorkflowPage: React.FC<CaseWorkflowPageProps> = () => {
  const { caseId = '' } = useParams<{ caseId: string }>()

  const {
    currentData: workflow,
    isFetching: isFetchingWorkflow,
  } = useGetCaseWorkflowQuery(caseId)
  const {
    currentData: issues = [],
    isFetching: isFetchingIssue,
  } = useGetIssuesListQuery(caseId)

  return (
    <div>
      <LargeTitle>
        Suivi Dossier
        {
          workflow && workflow.jalonMissionnement.missionnement?.etatMissionnement === EtatMissionnement.Accepte &&
            <CaseWorkflowReportIssueButton caseId={caseId} />
        }
      </LargeTitle>
      {
        (isFetchingWorkflow || isFetchingIssue) ?
          <CircularProgress /> :
          workflow && (
            <>
              <LineContainer>
                <CaseWorkflowDot state={workflow.jalonMissionnement.etat} />
                <CaseWorkflowMissionJalonCard
                  jalon={workflow.jalonMissionnement}
                  caseId={caseId}
                  isUrgentCase={workflow.dossierUrgent}
                />
              </LineContainer>
              {
                workflow.jalonMissionnement.missionnement?.etatMissionnement === EtatMissionnement.Accepte &&
                  <React.Fragment>
                    {
                      workflow.jalonIntervention && workflow.jalonPostIntervention &&
                        <>
                          <LineContainer>
                            <CaseWorkflowDot state={workflow.jalonIntervention.etat} />
                            <CaseWorkflowInterventionJalonCard
                              jalon={workflow.jalonIntervention}
                              caseId={caseId}
                            />
                          </LineContainer>
                          <LineContainer>
                            <CaseWorkflowDot state={workflow.jalonPostIntervention.etat} />
                            <CaseWorkflowPostInterventionJalonCard
                              jalon={workflow.jalonPostIntervention}
                              caseId={caseId}
                            />
                          </LineContainer>
                        </>
                    }
                    {
                      !workflow.jalonIntervention &&
                        <>
                          <LineContainer>
                            <CaseWorkflowDot state={workflow.jalonPriseDeContact.etat} />
                            <CaseWorkflowContactJalonCard
                              jalon={workflow.jalonPriseDeContact}
                              caseId={caseId}
                            />
                          </LineContainer>
                          <LineContainer>
                            <CaseWorkflowDot state={workflow.jalonRDVMetre.etat} />
                            <CaseWorkflowMeasureRdvJalonCard
                              jalon={workflow.jalonRDVMetre}
                              caseId={caseId}
                            />
                          </LineContainer>
                          <LineContainer>
                            <CaseWorkflowDot state={workflow.jalonPostRdvMetre.etat} />
                            <CaseWorkflowMeasureJalonCard
                              caseId={caseId}
                              jalon={workflow.jalonPostRdvMetre}
                            />
                          </LineContainer>
                        </>
                    }
                    {
                      issues?.map((issue, index: number) => (
                        <LineContainer key={index}>
                          <CaseWorkflowDot state={WorkflowEtat.Anomalie} />
                          <CaseWorkflowIssueJalonCard
                            issue={issue}
                          />
                        </LineContainer>
                      ))
                    }
                    {/* {
                      workflow.jalonCompteRenduVisite &&
                        <LineContainer>
                          <CaseWorkflowDot state={workflow.jalonCompteRenduVisite?.etat} />
                          <CaseWorkflowJalonCard
                            jalon={
                              {
                                ...workflow.jalonCompteRenduVisite,
                                libelle: 'Compte-rendu de visite Sinapps',
                                date: workflow.jalonCompteRenduVisite.date || '',
                              }
                            }
                          />
                        </LineContainer>
                    } */}
                    <LineContainer>
                      <CaseWorkflowDot state={workflow.jalonDevis.etat} />
                      <CaseWorkflowJalonCard
                        jalon={
                          {
                            ...workflow.jalonDevis,
                            libelle: 'Devis',
                            date: workflow.jalonDevis.dateAffichee || workflow.jalonDevis.dateModification || '',
                          }
                        }
                        quoteState={workflow.jalonDevis.devis?.etatDuDevis?.code}
                        comment={workflow.jalonDevis.devis?.motifRefusDevis || ''}
                      />
                    </LineContainer>
                    {
                      !workflow.jalonIntervention &&
                        <>
                          <LineContainer>
                            <CaseWorkflowDot state={workflow.jalonRDVTravaux.etat} />
                            <CaseWorkflowWorksRdvJalonCard
                              jalon={workflow.jalonRDVTravaux}
                              caseId={caseId}
                            />
                          </LineContainer>
                          <LineContainer>
                            <CaseWorkflowDot state={workflow.jalonFinDeTravaux.etat} />
                            <CaseWorkflowWorkEndJalonCard
                              jalon={workflow.jalonFinDeTravaux}
                              caseId={caseId}
                            />
                          </LineContainer>
                        </>
                    }
                    <LineContainer>
                      <CaseWorkflowDot state={workflow.jalonFacture.etat} />
                      <CaseWorkflowJalonCard
                        jalon={
                          {
                            ...workflow.jalonFacture,
                            libelle: 'Facture',
                            date: workflow.jalonFacture.dateAffichee || workflow.jalonFacture.dateModification || '',
                          }
                        }
                        invoiceState={workflow.jalonFacture.facture?.etatDeLaFacture?.code}
                        comment={workflow.jalonFacture.facture?.motifRefusFacture || ''}
                      />
                    </LineContainer>
                    <LineContainer last>
                      <CaseWorkflowDot state={workflow.jalonDossierTermine.etat} />
                      <CaseWorkflowJalonCard
                        jalon={workflow.jalonDossierTermine}
                      />
                    </LineContainer>
                  </React.Fragment>
              }
            </>
          )
      }
      <Footer />
    </div>
  )
}

export default CaseWorkflowPage
