/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostWorksRdvMutation,
  usePatchWorksRdvMutation,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'
import {
  isApiError,
  type ApiResponse,
} from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
} from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  RendezVousTravaux,
  RendezVousTravauxRequest,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface RdvRequest {
  data: (RendezVousTravauxRequest | RendezVousTravaux)[];
}

const rdvSchema = Yup.object().shape<Shape<RdvRequest>>({
  data: Yup.array(Yup.object().shape<Shape<RendezVousTravauxRequest>>({
    dateDebut: Yup.string().nullable().required('La date de début est obligatoire'),
    dateFin: Yup.string().nullable()
      .test('dateDebut',
        "L'heure de fin ne peut pas être inférieure à celle de début",
        (dateFin = '', { parent }: {parent: RendezVousTravaux}) => {
          const dates = DateUtils.formatStartEndDate({ dateDebut: parent.dateDebut, dateFin: dateFin || '' })
          return new Date(dates.dateFin) > new Date(dates.dateDebut)
        },
      ).required('La date de fin est obligatoire'),
  })),
}).required()

type RdvForm = FormikContextType<RdvRequest>

/* Internal variables ------------------------------------------------------- */
const initialDay: RendezVousTravauxRequest = {
  dateDebut: new Date().toISOString(),
  dateFin: new Date().toISOString(),
}

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

const DayContainer = styled(FormBoldTitle)`
  margin-bottom: -10px;
  font-size: 1.1rem;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowWorksRdvButtonProps {
  caseId: string;
  initialValues?: RendezVousTravaux[];
}

const CaseWorkflowWorksRdvButton: React.FC<CaseWorkflowWorksRdvButtonProps> = ({ caseId, initialValues = []}) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)

  const [
    submitNewRdv,
  ] = usePostWorksRdvMutation()
  const [
    submitEditRdv,
  ] = usePatchWorksRdvMutation()

  const onSubmit = async (values: RdvRequest, { setSubmitting, resetForm, setFieldError }: FormikHelpers<RdvRequest>) => {
    const promises: Promise<void | ApiResponse<void>>[] = []
    let error = false

    values.data.forEach((data) => {
      if ('id' in data) {
        promises.push(
          submitEditRdv({
            id: data.id,
            data: DateUtils.formatStartEndDate(data),
          }).catch(console.error),
        )
      } else {
        promises.push(
          submitNewRdv({
            caseId,
            data: DateUtils.formatStartEndDate(data),
          }).catch(console.error),
        )
      }
    })

    await Promise.all(promises).then((reponses) => {
      setSubmitting(false)

      reponses.forEach((response, index) => {
        if (isApiError(response)) {
          error = true
          setFieldError(`data[${index}].date`, 'Une erreur est survenue sur ce RDV.')
        }
      })
    })

    if (!error) {
      resetForm()
      setOpen(false)
    }
  }

  const formikForm: RdvForm = useForm<RdvRequest>(
    {
      initialValues: { data: [ initialDay ]},
      onSubmit: onSubmit,
      validationSchema: rdvSchema,
    },
  )

  useEffect(() => {
    if (initialValues.length === 0) return
    formikForm.setValues({ data: initialValues.map((v) => ({ ...v, date: v.dateDebut, heureDebut: v.dateDebut, heureFin: v.dateFin })) })
  }, [ initialValues ])

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onAddDayClick = () => {
    formikForm.setFieldValue('data', [ ...formikForm.values.data, initialDay ])
  }

  return (
    <>
      <CaseWorkflowStyledComponents.Button
        variant="contained"
        onClick={onClick}
        disabled={isReadOnly}
      >
        {initialValues.length === 0 ? 'Choisir une date de RDV' : 'Modifier les rendez-vous'}
      </CaseWorkflowStyledComponents.Button>
      {
        open &&
          <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
          >
            <CaseWorkflowStyledComponents.DialogTitle>
              {initialValues.length === 0 ? 'Nouveaux RDV travaux' : 'Modification des rendez-vous'}
              <CloseButton handleClose={onClose} />
            </CaseWorkflowStyledComponents.DialogTitle>
            <Form form={formikForm}>
              <CaseWorkflowStyledComponents.DialogContent>
                {
                  formikForm.values.data.map((value, index) => (
                    <React.Fragment key={index}>
                      <DayContainer>
                        {`JOUR ${index + 1}`}
                      </DayContainer>
                      <GridContainer>
                        <div>
                          <FormBoldTitle required>
                            Date de début
                          </FormBoldTitle>
                          <FormikDatePicker name={`data[${index}].dateDebut`} />
                        </div>
                        <div>
                          <FormBoldTitle required>
                            Date de fin
                          </FormBoldTitle>
                          <FormikDatePicker name={`data[${index}].dateFin`} />
                        </div>
                      </GridContainer>
                      <GridContainer>
                        <div>
                          <FormBoldTitle required>
                            Heure de début
                          </FormBoldTitle>
                          <FormikDatePicker
                            name={`data[${index}].dateDebut`}
                            time
                          />
                        </div>
                        <div>
                          <FormBoldTitle required>
                            Heure de fin
                          </FormBoldTitle>
                          <FormikDatePicker
                            name={`data[${index}].dateFin`}
                            time
                          />
                        </div>
                      </GridContainer>
                    </React.Fragment>
                  ))
                }
                <br />
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={onAddDayClick}
                >
                  Ajouter une journée
                </Button>
              </CaseWorkflowStyledComponents.DialogContent>
              <CaseWorkflowStyledComponents.DialogAction>
                <LongButton
                  variant="outlined"
                  onClick={onClose}
                >
                  Annuler
                </LongButton>
                <LongButton
                  type="submit"
                  variant="contained"
                  disabled={formikForm.isSubmitting}
                >
                  Valider
                </LongButton>
              </CaseWorkflowStyledComponents.DialogAction>
            </Form>
          </Dialog>
      }
    </>
  )
}

export default CaseWorkflowWorksRdvButton
