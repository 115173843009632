/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { usePostMediaFilesMutation } from 'store/api'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { AddPhotoAlternate } from '@mui/icons-material'
import ImportFilesModal from 'components/ImportFilesModal/ImportFilesModal'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type {
  MediathequeDossierCreatePayload,
  MediathequeType,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const AddButton = styled(Button)`
  width: 40px;
  min-width: 40px !important;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowAddImagesButtonProps {
  caseId: string;
  type: MediathequeType;
  small?: boolean;
}

const CaseWorkflowAddImagesButton: React.FC<CaseWorkflowAddImagesButtonProps> = ({ caseId, type, small }) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)

  const [
    submitNewDocument,
    { isLoading: isSendingFile },
  ] = usePostMediaFilesMutation()

  const onClose = (files?: File[]) => {
    setOpen(false)
    const data: MediathequeDossierCreatePayload = { elementMediathequeRequest: []}

    if (files && files.length > 0) {
      files.forEach((file) => {
        if (file !== undefined) {
          data.elementMediathequeRequest?.push({
            fichier: file,
            nom: file.name,
            type,
          })
        }
      })

      submitNewDocument({ caseId, data }).catch(console.error)
    }
  }

  return (
    <React.Fragment>
      {
        small ?
          <AddButton
            variant="contained"
            onClick={() => setOpen(true)}
            disabled={isSendingFile || isReadOnly}
          >
            <AddPhotoAlternate />
          </AddButton> :
          <CaseWorkflowStyledComponents.Button
            variant="contained"
            disabled={isSendingFile || isReadOnly}
            fullWidth
            onClick={() => setOpen(true)}
          >
            Ajouter les photos
          </CaseWorkflowStyledComponents.Button>
      }
      {
        open &&
          <ImportFilesModal
            handleClose={onClose}
            accept="image/*"
          />
      }
    </React.Fragment>
  )
}

export default CaseWorkflowAddImagesButton
