/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostIssueMutation,
  useGetIssueTypesListQuery,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  CircularProgress,
  Dialog,
} from '@mui/material'
import { toast } from 'react-toastify'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import CheckableButton from 'components/CheckableButton/CheckableButton'
import ErrorMessage from 'components/ErrorMessage/ErrorMessage'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  AnomalieRequest,
  AnomalieType,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DarkCheckableButton = styled(CheckableButton)`
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
    fill: ${(props) => props.theme.colors.dark};
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

/* Type declarations -------------------------------------------------------- */
const issueSchema = Yup.object().shape<Shape<AnomalieRequest>>({
  anomalieType: Yup.number().moreThan(-1, 'Le type de la difficulté est obligatoire')
    .required('Le type de la difficulté est obligatoire'),
  tauxHumidite: Yup.number()
    .typeError("Le taux d'humidité doit être un nombre entier")
    .integer("Le taux d'humidité doit être un nombre entier")
    .min(0, "Le taux d'humidité doit être supérieur ou égal à 0")
    .max(100, "Le taux d'humidité doit être inférieur ou égal à 100"),
  dateFin: Yup.string()
    .test('dateFin',
      "La date de fin ne peut pas être inférieure à aujourd'hui",
      (dateFin) => new Date() < new Date(dateFin || ''),
    ).required('La date de fin est obligatoire'),
}).required()

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowReportIssueButtonProps {
  caseId: string;
}

const CaseWorkflowReportIssueButton: React.FC<CaseWorkflowReportIssueButtonProps> = ({ caseId }) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)

  const {
    currentData: issueTypes = [],
    isFetching: isFetchingIssueTypes,
  } = useGetIssueTypesListQuery()
  const [ submitCreateIssue ] = usePostIssueMutation()

  const onSubmit = async (values: AnomalieRequest, { setSubmitting, resetForm }: FormikHelpers<AnomalieRequest>) => {
    values.dateDebut = new Date().toUTCString()
    setSubmitting(false)
    const response = await submitCreateIssue({ caseId: caseId, data: values })
    if (isApiError(response)) {
      toast.error("Une erreur est survenue lors de l'enregistrement de la difficulté.")
    } else {
      resetForm()
      setOpen(false)
    }
  }

  const formikForm: FormikContextType<AnomalieRequest> = useForm<AnomalieRequest>(
    {
      onSubmit: onSubmit,
      validationSchema: issueSchema,
      initialValues: {
        statut: false,
        dateDebut: '',
        dateFin: '',
        commentaire: '',
        etapeSuivi: null,
        anomalieType: -1,
        tauxHumidite: 0,
      },
    },
  )

  const showTauxHumidite = useMemo(() => issueTypes.find((t) => t.id === formikForm.values.anomalieType)?.hasTauxHumidite || false, [ formikForm.values.anomalieType, issueTypes ])

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      {
        isFetchingIssueTypes ?
          <CircularProgress /> :
          <CaseWorkflowStyledComponents.Button
            variant="outlined"
            onClick={onClick}
            disabled={isReadOnly}
          >
            Signaler une difficulté
          </CaseWorkflowStyledComponents.Button>
      }
      {
        open &&
          <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
          >
            <CaseWorkflowStyledComponents.DialogTitle>
              Signaler une difficulté
              <CloseButton handleClose={onClose} />
            </CaseWorkflowStyledComponents.DialogTitle>
            <Form form={formikForm}>
              <CaseWorkflowStyledComponents.DialogContent>
                <GridContainer>
                  {
                    issueTypes && issueTypes.map((type: AnomalieType, index) => (
                      <DarkCheckableButton
                        key={`${type.id}-${index}`}
                        checked={formikForm.values.anomalieType === type.id}
                        onChange={(e, c) => formikForm.setFieldValue('anomalieType', c ? type.id : -1)}
                        label={type.nom}
                        type="radio"
                      />
                    ))
                  }
                </GridContainer>
                <ErrorMessage name="anomalieType" />
                <GridContainer>
                  <div>
                    <FormBoldTitle required>
                      Date de fin
                    </FormBoldTitle>
                    <FormikDatePicker name="dateFin" />
                  </div>
                  <div>
                    {
                      showTauxHumidite && (
                        <React.Fragment>
                          <FormBoldTitle required>
                            Taux d'humidité
                          </FormBoldTitle>
                          <Field
                            component={TextField}
                            name="tauxHumidite"
                            placeholder="0"
                          />
                        </React.Fragment>
                      )
                    }
                  </div>
                </GridContainer>
              </CaseWorkflowStyledComponents.DialogContent>
              <CaseWorkflowStyledComponents.DialogAction>
                <LongButton
                  variant="outlined"
                  onClick={onClose}
                >
                  Annuler
                </LongButton>
                <LongButton
                  type="submit"
                  variant="contained"
                  disabled={formikForm.isSubmitting}
                >
                  Valider
                </LongButton>
              </CaseWorkflowStyledComponents.DialogAction>
            </Form>
          </Dialog>
      }
    </>
  )
}

export default CaseWorkflowReportIssueButton
