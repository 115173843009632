/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Adresse {
  /** @maxLength 32 */
  adresse1?: string | null;
  /** @maxLength 32 */
  adresse2?: string | null;
  /** @maxLength 32 */
  adresse3?: string | null;
  /** @maxLength 10 */
  codePostal?: string | null;
  /** @maxLength 26 */
  ville?: string | null;
  /** @maxLength 50 */
  codePays?: string | null;
  nomAdresse?: string | null;
  digicode?: string | null;
}

export interface AdresseAssure {
  telephoneBureau?: string | null;
  telephoneDomicile?: string | null;
  telephonePortable?: string | null;
  fax?: string | null;
  email?: string | null;
  /**
   * @minLength 1
   * @maxLength 32
   */
  adresse1: string;
  /** @maxLength 32 */
  adresse2?: string | null;
  /** @maxLength 32 */
  adresse3?: string | null;
  /**
   * @minLength 1
   * @maxLength 10
   */
  codePostal: string;
  /**
   * @minLength 1
   * @maxLength 26
   */
  ville: string;
  /** @maxLength 25 */
  codePays?: string | null;
}

export interface AdresseRequest {
  /**
   * @minLength 1
   * @maxLength 32
   */
  adresse1: string;
  /** @maxLength 32 */
  adresse2?: string | null;
  /** @maxLength 32 */
  adresse3?: string | null;
  /**
   * @minLength 1
   * @maxLength 10
   */
  codePostal: string;
  /**
   * @minLength 1
   * @maxLength 26
   */
  ville: string;
  /** @maxLength 25 */
  codePays?: string | null;
}

export interface AdresseSinistre {
  /**
   * @format double
   * @min 0
   * @max 999.999999
   */
  sinistreLatitude?: number | null;
  /**
   * @format double
   * @min 0
   * @max 999.999999
   */
  sinistreLongitude?: number | null;
  sinistreDigiCode?: string | null;
  telephone?: string | null;
  fax?: string | null;
  /** @maxLength 32 */
  adresse1?: string | null;
  /** @maxLength 32 */
  adresse2?: string | null;
  /** @maxLength 32 */
  adresse3?: string | null;
  /** @maxLength 10 */
  codePostal?: string | null;
  /** @maxLength 26 */
  ville?: string | null;
  /** @maxLength 50 */
  codePays?: string | null;
  nomAdresse?: string | null;
  digicode?: string | null;
}

export interface AffectationMetier {
  /** @format int32 */
  id: number;
  corpsMetier: IdLabel;
  autoriseMissionClassique: boolean;
  autoriseMissionUrgente: boolean;
}

export interface AffectationMetierRequest {
  /** @format int32 */
  idCorpsMetier: number;
  autoriseMissionClassique: boolean;
  autoriseMissionUrgente: boolean;
}

export interface AnnuaireLoginExtranetAssureur {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  idAnnuaire: string;
  /** @minLength 1 */
  login: string;
  password?: string | null;
}

export interface AnnuaireLoginExtranetAssureurResume {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  idAnnuaire: string;
  /** @minLength 1 */
  nom: string;
  /** @minLength 1 */
  famille: string;
  /** @minLength 1 */
  login: string;
  /** @minLength 1 */
  password: string;
}

export interface AnnuaireResume {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nomFamille: string;
}

export interface Anomalie {
  /** @format int32 */
  id: number;
  anomalieType: AnomalieType;
  statut: boolean;
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin?: string | null;
  etapeSuivi?: WorkflowJalon | null;
  commentaire?: string | null;
  /** @format int32 */
  tauxHumidite?: number | null;
  /** @format date-time */
  dateCreation: string;
  /** @format date-time */
  dateModification: string;
  /** @minLength 1 */
  dossierID: string;
}

export interface AnomalieModificationRequest {
  /** @format int32 */
  anomalieType?: number | null;
  statut?: boolean | null;
  /** @format date-time */
  dateFin?: string | null;
  commentaire?: string | null;
  /** @format int32 */
  tauxHumidite?: number | null;
}

export interface AnomalieRequest {
  /** @format int32 */
  anomalieType: number;
  statut: boolean;
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  etapeSuivi?: WorkflowJalon | null;
  commentaire?: string | null;
  /** @format int32 */
  tauxHumidite?: number | null;
}

export interface AnomalieType {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  nom: string;
  hasTauxHumidite: boolean;
}

export interface AnomalieTypeModificationRequest {
  nom?: string | null;
}

export interface AnomalieTypeRequest {
  /** @minLength 1 */
  nom: string;
}

export interface Article {
  id?: string | null;
  /** @format int32 */
  articlePieceIndex?: number;
  isParticulier: boolean;
  code?: string | null;
  /** @format double */
  quantite: number;
  infos?: string | null;
  unite?: string | null;
  deleteOperation?: boolean;
}

export interface AskForPasswordResetEntrepriseRequest {
  /** @minLength 1 */
  login: string;
}

export interface Assure {
  /** @minLength 1 */
  id: string;
  /** @maxLength 32 */
  nom?: string | null;
  /** @maxLength 32 */
  prenom?: string | null;
  adresse?: Adresse | null;
  email?: string | null;
  telephone?: string | null;
}

export interface AssureRequest {
  id?: string | null;
  civilite?: string | null;
  politesse?: string | null;
  /** @minLength 1 */
  nom: string;
  prenom?: string | null;
  reference?: string | null;
  policeAssure?: string | null;
  adresse?: AdresseAssure | null;
}

export interface AuthenticationProperties {
  items?: Record<string, string>;
  parameters?: Record<string, any>;
  isPersistent?: boolean;
  redirectUri?: string | null;
  /** @format date-time */
  issuedUtc?: string | null;
  /** @format date-time */
  expiresUtc?: string | null;
  allowRefresh?: boolean | null;
}

export interface Banque {
  /** @minLength 1 */
  id: string;
  libelle?: string | null;
  etablissement?: string | null;
  beneficaire?: string | null;
  guichet?: string | null;
}

export interface BibliothequeDivers {
  categorie: BibliothequeDiversCategorie;
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
}

export enum BibliothequeDiversCategorie {
  Titre = "Titre",
  SousTitre = "SousTitre",
  Commentaire = "Commentaire",
  Autre = "Autre",
}

export interface BibliothequeDiversCategorieCodeLabelEnumRequest {
  enumType: BibliothequeDiversCategorie;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface Bordereau {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  description?: string | null;
  /** @format double */
  prixUnitaire?: number | null;
  unite?: string | null;
}

export interface CacheDataBase {
  /** @format double */
  tailleTotale?: number;
  database?: string | null;
  entrees?: Record<string, number | null>;
}

export interface CacheInfo {
  type?: CacheType;
  /** @format double */
  tailleTotale?: number;
  cachesDataBase?: CacheDataBase[] | null;
}

export enum CacheType {
  Database = "Database",
  Traveller = "Traveller",
  Document = "Document",
}

export interface ChangeSet {
  dossier?: string | null;
  /** @format date-time */
  dateDernierChangement?: string;
  typeModif?: TypeModif;
}

export interface ChangerStatutRequest {
  factureEtat: EtatFacture;
  /** @format int32 */
  motifRefus?: number | null;
  commentaire?: string | null;
}

export interface CodeLabel {
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface CodeLabelCouleur {
  couleur?: CouleurPack | null;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface CodeLabelRequest {
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface Compagnie {
  nom?: string | null;
  numeroContrat?: string | null;
  reference?: string | null;
}

export interface CompagnieAssurance {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export enum ComplexiteDDE {
  Simple = "Simple",
  Technique = "Technique",
}

export interface ComplexiteDDEEnumLabel {
  code: ComplexiteDDE;
  libelle?: string | null;
}

export interface CompteEchange {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  motDePasse: string;
  active: boolean;
}

export interface CompteRenduNonSuppressionCause {
  /** @minLength 1 */
  motif: string;
  precision?: string | null;
  /** @format date-time */
  dateReparation?: string | null;
}

export interface CompteRenduQualificationDDE {
  tauxHumidite?: CompteRenduTauxHumidite | null;
  causeNonSuppression?: CompteRenduNonSuppressionCause | null;
  causeSupprimee: boolean;
}

export interface CompteRenduRDF {
  /** @minLength 1 */
  idMission: string;
  /** @minLength 1 */
  idPrestation: string;
  statut: StatutCommandeSinappsEnumLabel;
  intervention: InterventionRDF;
  sinistreDDE?: DonneesSinistreDDE | null;
  commentaire?: string | null;
  /** @minLength 1 */
  conclusion: string;
  raisonCauseNonIdentifiee?: string | null;
  raisonCauseIdentifieeNonReparee?: string | null;
  precisionRaisonCauseNonIdentifiee?: string | null;
  precisionRaisonCauseIdentifieeNonReparee?: string | null;
  precisionCausePonctuelle?: string | null;
  rapportRDF?: Document | null;
}

export interface CompteRenduRDFRequest {
  intervention: InterventionRDfRequest;
  sinistreDDE?: DonneesSinistreDDERequest | null;
  /** @minLength 1 */
  commentaire: string;
  /** @minLength 1 */
  conclusion: string;
  raisonCauseNonIdentifiee?: string | null;
  raisonCauseIdentifieeNonReparee?: string | null;
  precision?: string | null;
}

export interface CompteRenduSinappsRisque {
  typeRisque?: string | null;
  usageAssure?: string | null;
  relationAuRisque?: string | null;
  /** @minLength 1 */
  incoherenceRisqueDeclare: string;
}

export interface CompteRenduSinistre {
  /** @minLength 1 */
  dateSinistre: string;
  /** @minLength 1 */
  heure: string;
  /** @minLength 1 */
  natureSinistre: string;
  /** @minLength 1 */
  causeSinistre: string;
  reserve?: string | null;
}

export interface CompteRenduTauxHumidite {
  /** @format int32 */
  valeur: number;
  localisationDuReleve?: string | null;
  assechementEngage?: boolean;
}

export interface CompteRenduVisite {
  /** @minLength 1 */
  idMission: string;
  /** @minLength 1 */
  idPrestation: string;
  statut: StatutCommandeSinappsEnumLabel;
  sinistre?: CompteRenduSinistre | null;
  risque?: CompteRenduSinappsRisque | null;
  qualificationDDE?: CompteRenduQualificationDDE | null;
  risquesAggravesIdentifies?: RisqueAggraveIdentifie[] | null;
}

export interface CompteRenduVisiteRequest {
  sinistre: SinappsSinistreRequest;
  risque: SinappsRisqueRequest;
  risquesAggravesIdentifies?: RisqueAggraveIdentifie[] | null;
}

export interface ContactEntreprise {
  /** @format int32 */
  id: number;
  /** @format int32 */
  idEntreprise: number;
  nom?: string | null;
  prenom?: string | null;
  email?: string | null;
  telFixe?: string | null;
  telPortable?: string | null;
  role?: string | null;
}

export interface ContactEntrepriseRequest {
  /** @format int32 */
  idEntreprisePartenaire: number;
  /** @minLength 1 */
  nom: string;
  prenom?: string | null;
  email?: string | null;
  telFixe?: string | null;
  telPortable?: string | null;
  /** @minLength 1 */
  role: string;
}

export interface CoordoneesRequest {
  adresse?: AdresseRequest | null;
  email?: string | null;
  telephonePortable?: string | null;
  telephoneFixe?: string | null;
}

export interface Coordonnees {
  adresse?: Adresse | null;
  email?: string | null;
  telephone?: string | null;
}

export interface CouleurPack {
  fond?: string | null;
  police?: string | null;
}

export interface CreateBanqueRequest {
  /** @format int32 */
  agence: number;
  codeLong?: string | null;
  codeEtablissement?: string | null;
  nomBeneficiaire?: string | null;
  /** @minLength 1 */
  code: string;
  codeGuichet?: string | null;
  compteBeneficiaire?: string | null;
  libelle?: string | null;
}

export interface CreateBordereauRequest {
  /**
   * @minLength 1
   * @pattern ^[a-zA-Z0-9]{1,5}$
   */
  famille: string;
  /**
   * @minLength 1
   * @maxLength 80
   */
  codeArticle: string;
  /** @minLength 1 */
  libelle: string;
  description?: string | null;
  /** @maxLength 5 */
  unite?: string | null;
  /** @maxItems 15 */
  tarifs?: number[] | null;
}

export interface CreateCompteEchange {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  motDePasse: string;
}

export interface CreateFamilleRequest {
  /**
   * @minLength 1
   * @pattern ^[a-zA-Z0-9]{2}$
   */
  code: string;
  professionnel: boolean;
  /** @minLength 1 */
  libelle: string;
  typePersonne: TypePersonneEnum;
  visible?: boolean;
}

export interface CreateIntervenantRequest {
  /**
   * @minLength 1
   * @maxLength 32
   */
  nom: string;
  /**
   * @minLength 1
   * @maxLength 32
   */
  prenom: string;
  civilite?: string | null;
  formulePolitesse?: string | null;
  qualite?: string | null;
  adresse?: AdresseRequest | null;
  email?: string | null;
  telephone?: string | null;
}

export interface CreateModelEmail {
  /**
   * @minLength 1
   * @maxLength 5
   */
  code: string;
  /** @minLength 1 */
  objet: string;
  /** @minLength 1 */
  libelle: string;
  /** @minLength 1 */
  texte: string;
  sources?: TypeCondition | null;
  typesSinistre?: TypeCondition | null;
  companies?: TypeCondition | null;
}

export interface CreateModeleSMSRequest {
  /**
   * @minLength 1
   * @maxLength 5
   */
  code: string;
  /** @minLength 1 */
  libelle: string;
  /** @minLength 1 */
  texte: string;
  typeSMS?: TypeSMS | null;
  /** @format int32 */
  delai?: number | null;
  sources?: TypeCondition | null;
  typesSinistre?: TypeCondition | null;
  companies?: TypeCondition | null;
}

export interface CreateParametrageLogicielRequest {
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
  version?: string | null;
  nom?: string | null;
  chemin?: string | null;
}

export interface CreateSourceMandant {
  /**
   * @minLength 1
   * @pattern ^[a-zA-Z0-9]{2}$
   */
  codeSource: string;
  /**
   * @minLength 1
   * @pattern ^[a-zA-Z0-9]{2}$
   */
  codeFamille: string;
  /**
   * @minLength 1
   * @pattern ^[a-zA-Z0-9]{2}$
   */
  codeAnnuaire: string;
  professionnel: boolean;
  codePrestataire?: string | null;
  /** @format int32 */
  tarification: number;
}

export interface CreateTVARequest {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  /** @format float */
  taux: number;
  dE0235?: string | null;
}

export interface CreationDossierDommageRequest {
  /** @format double */
  montant?: number | null;
  commentaire?: string | null;
}

export interface CreationDossierIrenRequest {
  /**
   * @minLength 1
   * @pattern ^[0-9]{4}$
   */
  annee: string;
  /**
   * @minLength 1
   * @pattern ^[a-zA-Z0-9]{2}$
   */
  etablissement: string;
  mandants?: MandantRequest[] | null;
  sinistre: SinistreRequest;
  mission: MissionRequest;
  assure: AssureRequest;
}

export interface CreationDossierPersonneRequest {
  /** @minLength 1 */
  nom: string;
  prenom?: string | null;
  telephoneMobile?: string | null;
  telephoneFixe?: string | null;
  adresse1?: string | null;
  adresse2?: string | null;
  adresse3?: string | null;
  /** @minLength 1 */
  codePostal: string;
  /** @minLength 1 */
  ville: string;
  /** @minLength 1 */
  email: string;
}

export interface CreationDossierRequest {
  typeDePrestation: TypePrestation;
  urgence: boolean;
  /** @minLength 1 */
  numeroContrat: string;
  /** @format double */
  montantFranchise?: number | null;
  assure: CreationDossierPersonneRequest;
  sinistre: CreationDossierSinistreRequest;
  dommage: CreationDossierDommageRequest;
  messagePrivePrestataire?: string | null;
}

export interface CreationDossierSinistreRequest {
  /** @minLength 1 */
  numero: string;
  /** @minLength 1 */
  nature: string;
  /** @format date-time */
  date?: string | null;
  nom?: string | null;
  adresse1?: string | null;
  adresse2?: string | null;
  /** @minLength 1 */
  codePostal: string;
  /** @minLength 1 */
  ville: string;
}

export enum CurrentCollaborateurType {
  ExtranetAssure = "ExtranetAssure",
  ExtranetEntreprise = "ExtranetEntreprise",
  ExtranetAssureur = "ExtranetAssureur",
  Iren = "Iren",
  IrenMobile = "IrenMobile",
  Generic = "Generic",
}

export interface DashboardCommande {
  /** @minLength 1 */
  dossier: string;
  /** @minLength 1 */
  assure: string;
  /** @minLength 1 */
  donneurDordre: string;
  /** @minLength 1 */
  refMendant: string;
  /** @minLength 1 */
  ville: string;
  /** @format int64 */
  numeroCommande: number;
  /** @format double */
  montantHT: number;
  etat: EtatCommandeEnumLabel;
}

export interface DashboardFacture {
  /** @minLength 1 */
  dossier: string;
  /** @minLength 1 */
  assure: string;
  /** @minLength 1 */
  donneurDordre: string;
  /** @minLength 1 */
  refMendant: string;
  /** @minLength 1 */
  ville: string;
  /** @format int64 */
  numeroFacture: number;
  /** @minLength 1 */
  type: string;
  /** @format double */
  montantHT: number;
  etat: EtatFactureEnumLabel;
  /** @format date-time */
  dateEtat?: string | null;
}

export interface DateRange {
  /** @format date-time */
  dateDebut?: string;
  /** @format date-time */
  dateFin?: string;
}

export interface Decote {
  deleteOperation?: boolean;
  id?: string | null;
  libelle?: string | null;
  /** @format double */
  longueur?: number | null;
  /** @format double */
  largeur?: number | null;
}

export interface Devis {
  deductionFranchise: boolean;
  /** @format int32 */
  sequence?: number;
  ordre?: string | null;
  /** @format double */
  montantFranchise?: number | null;
  rse: boolean;
  /** @format float */
  tauxRSE?: number | null;
  remise: boolean;
  /** @format float */
  tauxRemise?: number | null;
  commentaire?: string | null;
  etatDuDevis?: EtatDevisEnumLabel | null;
  motifRefusDevis?: string | null;
  lignesDevis?: LigneDevis[] | null;
  piedDuDevis?: PiedDevis | null;
}

export interface DevisAvecPiecesJointes {
  pieceJointeSimplifie: PieceJointeSimplifie;
  deductionFranchise: boolean;
  /** @format int32 */
  sequence?: number;
  ordre?: string | null;
  /** @format double */
  montantFranchise?: number | null;
  rse: boolean;
  /** @format float */
  tauxRSE?: number | null;
  remise: boolean;
  /** @format float */
  tauxRemise?: number | null;
  commentaire?: string | null;
  etatDuDevis?: EtatDevisEnumLabel | null;
  motifRefusDevis?: string | null;
  lignesDevis?: LigneDevis[] | null;
  piedDuDevis?: PiedDevis | null;
}

export interface DevisRequest {
  deductionFranchise: boolean;
  /** @format int32 */
  sequence?: number;
  /** @format double */
  montantFranchise?: number | null;
  rse: boolean;
  /** @format float */
  tauxRSE?: number | null;
  remise: boolean;
  /** @format float */
  tauxRemise?: number | null;
  commentaire?: string | null;
  lignesDevis?: LigneDevis[] | null;
}

export interface Document {
  id?: string | null;
  categorie?: CodeLabel | null;
  /** @minLength 1 */
  contentType: string;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  dateEdition?: string | null;
  /** @minLength 1 */
  nomDuFichier: string;
  /** @minLength 1 */
  origine: string;
  /** @minLength 1 */
  emetteur: string;
  url?: string | null;
  urlVignette?: string | null;
  /**
   * @minLength 1
   * @maxLength 30
   */
  libelle: string;
  familleAction?: CodeLabel | null;
  action?: CodeLabel | null;
}

export interface DocumentJuridique {
  /** @format int32 */
  id: number;
  typeDocument?: IdLabel | null;
  nom?: string | null;
  isObligatoire?: boolean;
  /** @format date-time */
  dateDemande?: string | null;
  /** @format date-time */
  dateDepot?: string | null;
  /** @format date-time */
  dateValiditeMax?: string | null;
}

export interface DocumentJuridiqueRequest {
  typeDocument: IdLabel;
  /** @minLength 1 */
  nom: string;
  isObligatoire: boolean;
  /** @format date-time */
  dateDemande?: string;
  /** @format date-time */
  dateDepot: string;
  /** @format date-time */
  dateValiditeMax: string;
}

export interface DocumentJuridiqueType {
  /** @format int32 */
  id: number;
  libelle?: string | null;
  isObligatoire?: boolean;
  isPermanent?: boolean;
  /** @format int32 */
  dureeValiditeJour?: number;
}

export interface DocumentRequest {
  /** @format int32 */
  action?: number | null;
  type?: TypeDocument | null;
  /** @format binary */
  fichier: File;
}

export interface DonneesAvancees {
  natureInscription?: CodeLabel | null;
  statutJuridique?: CodeLabel | null;
  codificationAssureur?: string | null;
  numeroRCSRDM?: string | null;
}

export interface DonneesAvanceesRequest {
  natureInscription?: string | null;
  statutJuridique?: string | null;
  codificationAssureur?: string | null;
  numeroRCSRDM?: string | null;
}

export interface DonneesSinistreDDE {
  /** @minLength 1 */
  cause: string;
  detail?: string | null;
  precisionSinistreDDE?: string | null;
  sinistreToiture?: SinistreToiture | null;
}

export interface DonneesSinistreDDERequest {
  sinistreCorrect: boolean;
  cause?: string | null;
  detail?: string | null;
  precisionSinistreDDE?: string | null;
  sinistreToiture?: SinistreToitureRequest | null;
}

export interface Dossier {
  /** @minLength 1 */
  id: string;
  natureSinistre?: NatureSinistre | null;
  assure?: Personne | null;
  risque?: Risque | null;
  /** @format date-time */
  dateSinistre?: string | null;
  descriptionDommages?: string | null;
  /** @format double */
  montantEstime?: number | null;
  adresseSinistre: Adresse;
  statutDossier?: StatutDossier | null;
  compagnieAssurance?: CompagnieAssurance | null;
  /** @minLength 1 */
  numeroContrat: string;
  /** @minLength 1 */
  referenceCompagnie: string;
  typePrestation?: TypePrestationEnumLabel | null;
  gestionnaire?: string | null;
  /** @format date-time */
  dateMissionnement?: string | null;
  isSinapps: boolean;
  causeSinistre?: CodeLabel | null;
  detailSinistre?: CodeLabel | null;
  hasCompteRenduVisite: boolean;
  /** @format date-time */
  dateCreation?: string | null;
  statutComposeDossier: StatutComposeDossier;
  etatMissionnement?: EtatMissionnement | null;
}

export interface DossierRecherche {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  assure: string;
  /** @minLength 1 */
  referenceSinistre: string;
  /** @minLength 1 */
  referenceCompagnie: string;
  /** @minLength 1 */
  numeroContrat: string;
  natureSinistre: NatureSinistre;
  entreprisePartenaire?: EntreprisePartenaire | null;
  /** @minLength 1 */
  adresse: string;
  /** @minLength 1 */
  codePostal: string;
  /** @minLength 1 */
  ville: string;
  missionUrgente: boolean;
  /** @format date-time */
  dateMissionnement?: string | null;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  dateModification?: string | null;
  /** @format date-time */
  dateSinistre?: string | null;
  compagnieDAssurance?: CompagnieAssurance | null;
  /** @format date-time */
  dateCharte: string;
  statut: StatutDossier;
  statutComposeDossier: StatutComposeDossier;
  etatMissionnement: EtatMissionnement;
  derniereAnomalieNonLevee?: Anomalie | null;
  isSinapps: boolean;
}

export interface EchangeInterne {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  idDossier: string;
  /** @minLength 1 */
  objet: string;
  /** @minLength 1 */
  message: string;
  /** @format date-time */
  date: string;
  lu: boolean;
  emetteur: EchangeInterneEmetteur;
  destinataire: EchangeInterneDestinataire;
}

export interface EchangeInterneDestinataire {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export interface EchangeInterneEmetteur {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export interface EchangeInterneRequest {
  /**
   * Le ou les destinataire de l'EI
   * Pourraient être récupérés de la route :  Dossier/{dossier}/EchangeInterne/Destinataires
   * Un destinataire doit avoir le format RegEx : RefAnnuaire
   */
  destinataires?: string[] | null;
  /**
   * L'objet de l'EI
   * @minLength 1
   */
  objet: string;
  /**
   * Message de l'EI
   * @minLength 1
   */
  message: string;
}

export interface ElementMediatheque {
  id?: string | null;
  categorie?: CodeLabel | null;
  sousCategorie?: CodeLabel | null;
  /** @minLength 1 */
  contentType: string;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  dateEdition?: string | null;
  /** @minLength 1 */
  fileName: string;
  /** @minLength 1 */
  origine: string;
  /** @minLength 1 */
  emetteur: string;
  /** @minLength 1 */
  url: string;
  /** @minLength 1 */
  urlVignette: string;
  /**
   * @minLength 1
   * @maxLength 30
   */
  libelle: string;
  type: TypeElementMediatheque;
}

export interface ElementMediathequeRequest {
  /** @format binary */
  fichier: File;
  categorie?: string | null;
  sousCategorie?: string | null;
  type?: MediathequeType | null;
  /** @minLength 1 */
  nom: string;
}

export interface EntreprisePartenaire {
  /** @format int32 */
  id: number;
  raisonSociale?: string | null;
  donneesAvancees?: DonneesAvancees | null;
  dirigeantNom?: string | null;
  dirigeantPrenom?: string | null;
  dirigeantTel?: string | null;
  /** @format date-time */
  dateImmatriculation?: string | null;
  siegeSocialCoordonees: Coordonnees;
  siren?: string | null;
  codeApe?: string | null;
  numeroTvaIntracommunication?: string | null;
  codeEntreprise?: string | null;
  codeFormeJuridique?: string | null;
  /** @format double */
  capitalSocial?: number | null;
  cheminLogo?: string | null;
  typeContratPartenaire?: IdLabel | null;
  archive: boolean;
  actif: boolean;
  etablissements: Etablissement[];
  documentJuridiques: DocumentJuridique[];
}

export interface EntreprisePartenaireRequest {
  /** @minLength 1 */
  raisonSociale: string;
  dirigeantNom?: string | null;
  dirigeantPrenom?: string | null;
  dirigeantTel?: string | null;
  /** @format date-time */
  dateImmatriculation?: string | null;
  siegeSocialCoordonees?: CoordoneesRequest | null;
  siren?: string | null;
  codeApe?: string | null;
  donneesAvancees?: DonneesAvanceesRequest | null;
  numeroTvaIntracommunication?: string | null;
  codeEntreprise?: string | null;
  codeFormeJuridique?: string | null;
  /** @format double */
  capitalSocial?: number | null;
  cheminLogo?: string | null;
  /** @format int32 */
  idTypeContratPartenaire: number;
  archive: boolean;
  actif: boolean;
}

export enum EnumerationsMetier {
  TypeRisque = "TypeRisque",
  TypeUsageAssure = "TypeUsageAssure",
  RelationAuRisque = "RelationAuRisque",
  MotifNonSuppressionDeCause = "MotifNonSuppressionDeCause",
  CauseSinistre = "CauseSinistre",
  NatureSinistre = "NatureSinistre",
  Localisation = "Localisation",
  MotifDeModificationDevis = "MotifDeModificationDevis",
  MotifModificationPlanification = "MotifModificationPlanification",
  ConclusionCompteRenduRDF = "ConclusionCompteRenduRDF",
  RaisonCauseNonIdentifiee = "RaisonCauseNonIdentifiee",
  RaisonCauseIdentifieeNonReparee = "RaisonCauseIdentifieeNonReparee",
  MotifFinPrestation = "MotifFinPrestation",
  CanalCommunicationPrivilegier = "CanalCommunicationPrivilegier",
  ParticulariteClient = "ParticulariteClient",
  ResponsabiliteSinistre = "ResponsabiliteSinistre",
  QualiteJuridique = "QualiteJuridique",
  TypeActeur = "TypeActeur",
  MotifModificationFactureIntervention = "MotifModificationFactureIntervention",
  LocalisationOrigineFuite = "LocalisationOrigineFuite",
  MotifDifficulteAnomalie = "MotifDifficulteAnomalie",
  MotifDifficulteDelai = "MotifDifficulteDelai",
  TypeTauxTVA = "TypeTauxTVA",
  ApplicationTauxTVA = "ApplicationTauxTVA",
  TypePieceJointe = "TypePieceJointe",
}

export interface Etablissement {
  /** @format int32 */
  id: number;
  refAnnuaire: RefAnnuaire;
  raisonSociale?: string | null;
  entreprise?: IdLabel | null;
  coordonnees: Coordonnees;
  siret?: string | null;
  archive: boolean;
  actif: boolean;
  affectationCorpsMetiers: AffectationMetier[];
  contratEntreprise?: IdLabel | null;
  /** @format double */
  score?: number | null;
  /** @format int32 */
  volumeRestant?: number;
  contacts: ContactEntreprise[];
  conges: EtablissementConge[];
  horaires: EtablissementHoraire[];
  zones: ZoneDeCouverture[];
}

export interface EtablissementConge {
  /** @format int32 */
  id: number;
  /** @format date-time */
  dateDebut?: string | null;
  /** @format date-time */
  dateFin?: string | null;
}

export interface EtablissementCongeRequest {
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
}

export interface EtablissementHoraire {
  /** @format int32 */
  id: number;
  /** @format int32 */
  dayOfWeek: number;
  /** @format int64 */
  heureDebut: number;
  /** @format int64 */
  heureFin: number;
}

export interface EtablissementHoraireRequest {
  /** @format int32 */
  dayOfWeek: number;
  /** @format int64 */
  heureDebut: number;
  /** @format int64 */
  heureFin: number;
}

export interface EtablissementRequest {
  refAnnuaire: RefAnnuaire;
  /** @minLength 1 */
  raisonSociale: string;
  coordonees?: Coordonnees | null;
  siret?: string | null;
  archive: boolean;
  actif: boolean;
}

export enum EtatCategorie {
  Cachee = "Cachee",
  Affiche = "Affiche",
}

export interface EtatCategorieCodeLabelEnum {
  enum: EtatCategorie;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface EtatCategorieCodeLabelEnumRequest {
  enumType: EtatCategorie;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export enum EtatCommande {
  Brouillon = "Brouillon",
  Transmis = "Transmis",
  Creation = "Creation",
  Regle = "Regle",
}

export interface EtatCommandeEnumLabel {
  code: EtatCommande;
  libelle?: string | null;
}

export enum EtatDevis {
  Valide = "Valide",
  Refuse = "Refuse",
  Brouillon = "Brouillon",
  Transmis = "Transmis",
  Retransmis = "Retransmis",
  Creation = "Creation",
  Regle = "Regle",
}

export interface EtatDevisEnumLabel {
  code: EtatDevis;
  libelle?: string | null;
}

export enum EtatEvenementExterieur {
  X = "X",
  M = "M",
  T = "T",
}

export interface EtatEvenementExterieurCodeLabelEnum {
  enum: EtatEvenementExterieur;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface EtatEvenementExterieurCodeLabelEnumRequest {
  enumType: EtatEvenementExterieur;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export enum EtatFacture {
  Brouillon = "Brouillon",
  Creation = "Creation",
  Valide = "Valide",
  Refuse = "Refuse",
  Envoye = "Envoye",
  Renvoye = "Renvoye",
  Regle = "Regle",
  Partiel = "Partiel",
}

export interface EtatFactureEnumLabel {
  code: EtatFacture;
  libelle?: string | null;
}

export enum EtatMissionnement {
  EnAttente = "EnAttente",
  Accepte = "Accepte",
  Refuse = "Refuse",
  Annule = "Annule",
}

export enum EtatRendezVous {
  Annule = "Annule",
  Active = "Active",
}

export interface Eurogex {
  /** @minLength 1 */
  section: string;
  /** @minLength 1 */
  rubrique: string;
  valeur?: string | null;
  /** @minLength 1 */
  description: string;
}

export interface ExpAssureReponsesRequest {
  /**
   * Code de la question
   * @minLength 1
   */
  codeQuestion: string;
  /**
   * Note
   * @format int32
   */
  note: number;
}

export interface Expert {
  id?: string | null;
  nom?: string | null;
  prenom?: string | null;
  isTechnecien?: boolean;
  isMetreur?: boolean;
}

export interface ExtranetEntrepriseUser {
  /** @minLength 1 */
  login: string;
  active: boolean;
  invitationUrl?: string | null;
  /** @format int32 */
  idEntreprise?: number | null;
  /** @format int32 */
  idEtablissement?: number | null;
}

export interface FactureAPI {
  deductionFranchise: boolean;
  /** @format int32 */
  sequence?: number;
  /** @format double */
  montantFranchise?: number | null;
  rse: boolean;
  /** @format float */
  tauxRSE?: number | null;
  remise: boolean;
  /** @format float */
  tauxRemise?: number | null;
  commentaire?: string | null;
  etatDeLaFacture: EtatFactureEnumLabel;
  lignesFacture?: LigneFacture[] | null;
  piedDeLaFacture?: PiedFacture | null;
  motifRefusFacture?: string | null;
}

export interface FactureItem {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateCreation: string;
  /** @format date-time */
  dateMiseEnDefinitif: string;
  /** @format double */
  montantHT: number;
  /** @format double */
  montantTVA: number;
  /** @format double */
  montantTTC: number;
  statut: EtatFactureEnumLabel;
}

export interface FactureRequest {
  deductionFranchise: boolean;
  /** @format int32 */
  sequence?: number;
  /** @format double */
  montantFranchise?: number | null;
  rse: boolean;
  /** @format float */
  tauxRSE?: number | null;
  remise: boolean;
  /** @format float */
  tauxRemise?: number | null;
  commentaire?: string | null;
  lignesFacture?: LigneFacture[] | null;
}

export interface FamilleAnnuaire {
  /** @minLength 1 */
  code: string;
  professionnel: boolean;
  /** @minLength 1 */
  libelle: string;
  typePersonne: TypePersonneEnum;
  visible: boolean;
}

export interface ForbidResult {
  authenticationSchemes?: string[] | null;
  properties?: AuthenticationProperties | null;
}

export interface Franchise {
  /** @format double */
  montant?: number;
  recuperee?: boolean;
  /** @format date-time */
  dateRecuperation?: string | null;
  repreneur?: string | null;
}

export interface IdLabel {
  /** @format int32 */
  id: number;
  libelle?: string | null;
}

export interface IntervenantAdresse {
  /** @maxLength 32 */
  adresse1?: string | null;
  /** @maxLength 10 */
  codePostal?: string | null;
  /** @maxLength 26 */
  ville?: string | null;
}

export interface Intervention {
  /** @format int32 */
  id: number;
  /** @format date-time */
  dateDebut?: string | null;
  /** @format date-time */
  dateFin?: string | null;
  resultat?: ResultatInterventionEnumLabel | null;
  motifNonReparation?: InterventionMotifNonReparation | null;
  commentaire?: string | null;
  differee: boolean;
  origineDemandeInterventionDiffere?: OrigineDemandeInterventionDiffereeEnumLabel | null;
  /** @format date-time */
  dateCreation: string;
  /** @format date-time */
  dateModification?: string | null;
}

export interface InterventionMotifNonReparation {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  libelle: string;
}

export interface InterventionRDF {
  /** @minLength 1 */
  dateIntervention: string;
  /** @minLength 1 */
  heureDebut: string;
  /** @minLength 1 */
  heureFin: string;
  /** @minLength 1 */
  natureOperation: string;
  precisionNatureOperation?: string | null;
  /** @minLength 1 */
  complexiteOperation: string;
  localisationOrigineFuite: string[];
  precisionLocalisationOrigineFuite?: string | null;
  tauxHumidite: CompteRenduTauxHumidite;
}

export interface InterventionRDfRequest {
  /** @minLength 1 */
  dateIntervention: string;
  /** @minLength 1 */
  heureDebut: string;
  /** @minLength 1 */
  heureFin: string;
  natureOperation: NatureOperation;
  /** Obligatoire si NatureOperation = "Destructive" */
  precisionNatureOperation?: string | null;
  complexiteDDE: ComplexiteDDE;
  localisationOrigineFuite: string[];
  precisionLocalisationOrigineFuite?: string | null;
  tauxHumidite: CompteRenduTauxHumidite;
}

export interface InterventionRequest {
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  resultat: ResultatIntervention;
  /** @format int32 */
  motifNonReparation?: number | null;
  differee?: boolean;
  origineDemandeInterventionDifferee?: OrigineDemandeInterventionDifferee | null;
  commentaire?: string | null;
}

export interface JalonCompteRenduVisite {
  /** @format date-time */
  date?: string | null;
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
}

export interface JalonCreation {
  type: WorkflowJalonEnumLabel;
  /** @format date-time */
  dateCreation: string;
  /** @format date-time */
  dateAnnulation?: string | null;
  etat: WorkflowEtat;
}

export interface JalonDevis {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateAffichee?: string | null;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  dateModification?: string | null;
  /** @format date-time */
  dateBrouillon?: string | null;
  devis?: Devis | null;
  /** @format int32 */
  nombreDevisRefuses?: number;
  pieceJointe?: PieceJointe | null;
}

export interface JalonFacture {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateAffichee?: string | null;
  /** @format date-time */
  dateCreation?: string | null;
  /** @format date-time */
  dateModification?: string | null;
  facture?: FactureAPI | null;
  /** @format int32 */
  nombreFacturesRefusees?: number;
  piecejointe?: PieceJointe | null;
}

export interface JalonFinDeTravaux {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateValidation?: string | null;
  sousJalonAttestationTva: SousJalonAttestationTva;
  sousJalonPhotoApresTravaux: SousJalonPhotoApresTravaux;
  sousJalonAttestationFinDeTravaux: SousJalonAttestationFinDeTravaux;
  sousJalonRapportRechercheDeFuite?: SousJalonRapportRechercheDeFuite | null;
}

export interface JalonIntervention {
  type: WorkflowJalonEnumLabel;
  intervention?: Intervention | null;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface JalonMissionnement {
  type: WorkflowJalonEnumLabel;
  missionnement?: Missionnement | null;
  etat: WorkflowEtat;
}

export interface JalonPostIntervention {
  type: WorkflowJalonEnumLabel;
  sousJalonPhotoAvantTravaux: SousJalonPhotoAvantTravaux;
  sousJalonPhotoApresTravaux: SousJalonPhotoApresTravaux;
  sousJalonAttestationTva: SousJalonAttestationTva;
  sousJalonBonDeCommande: SousJalonBonDeCommande;
  sousJalonAttestationFinDeTravaux: SousJalonAttestationFinDeTravaux;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface JalonPostRdvMetre {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateValidation?: string | null;
  sousJalonPhotoAvantTravaux: SousJalonPhotoAvantTravaux;
  sousJalonConfirmationDeVisite: SousJalonConfirmationDeVisite;
  sousJalonBonDeCommande: SousJalonBonDeCommande;
}

export interface JalonPriseDeContact {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateValidation?: string | null;
  priseDeContact?: PriseDeContact | null;
}

export interface JalonRendezVousMetre {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  dateRendezVous?: string | null;
  rendezVousMetre?: RendezVousMetreur | null;
}

export interface JalonRendezVousTravaux {
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  rendezVousTravaux: RendezVousTravaux[];
}

export interface JalonSimplifie {
  /** @minLength 1 */
  libelle: string;
  type: WorkflowJalonEnumLabel;
  etat: WorkflowEtat;
  /** @format date-time */
  date: string;
  actionDate?: string | null;
  pieceJointe?: PieceJointe | null;
}

export interface LigneDevis {
  /**
   * @minLength 1
   * @maxLength 3
   */
  type: string;
  /** @maxLength 80 */
  codeArticle?: string | null;
  libelle?: string | null;
  /** @maxLength 1 */
  unite?: string | null;
  /** @format double */
  quantite?: number | null;
  /** @format double */
  prixUnitaire?: number | null;
  /** @format double */
  prixHT?: number | null;
  /** @maxLength 5 */
  tva?: string | null;
  /** @format double */
  prixTTC?: number | null;
}

export interface LigneFacture {
  /** @maxLength 80 */
  codeArticle?: string | null;
  libelle?: string | null;
  /** @format double */
  quantite?: number | null;
  /** @format double */
  prixUnitaire?: number | null;
  /** @format double */
  prixHT?: number | null;
  /** @maxLength 5 */
  tva?: string | null;
  /** @format double */
  prixTTC?: number | null;
  /**
   * @minLength 1
   * @maxLength 3
   */
  type: string;
  /** @maxLength 1 */
  unite?: string | null;
}

export interface LigneTVAPiedDevis {
  codeTVA?: string | null;
  /** @format double */
  montantHT?: number | null;
  /** @format double */
  montantTVA?: number | null;
  /** @format double */
  montantTTC?: number | null;
}

export interface LigneTVAPiedFacture {
  codeTVA?: string | null;
  /** @format double */
  montantHT?: number | null;
  /** @format double */
  montantTVA?: number | null;
  /** @format double */
  montantTTC?: number | null;
}

export interface LoginRequest {
  /** @minLength 1 */
  username: string;
  /** @minLength 1 */
  password: string;
}

export interface LoginResponse {
  /** @minLength 1 */
  token: string;
  /** @format date-time */
  dateValidite: string;
  /** @minLength 1 */
  nom: string;
  /** @minLength 1 */
  idAnnuaire: string;
  /** @format int32 */
  id?: number | null;
}

export interface LoginResponseDossier {
  referenceDossier: RefDossier;
  lectureSeule: boolean;
  /** @minLength 1 */
  token: string;
  /** @format date-time */
  dateValidite: string;
  /** @minLength 1 */
  nom: string;
  /** @minLength 1 */
  idAnnuaire: string;
  /** @format int32 */
  id?: number | null;
}

export interface Mail {
  /**
   * Id Du mail
   * @minLength 1
   */
  id: string;
  /**
   * Emetteur du mail
   * @minLength 1
   */
  emetteur: string;
  /** Le ou les emails des destinataires */
  destinataires: string[];
  /**
   * L'objet du mail
   * @minLength 1
   */
  objet: string;
  /**
   * Message du mail
   * @minLength 1
   */
  message: string;
  /** Mail brouillon */
  brouillon: boolean;
  /** Demander une confirmation de réception */
  confirmationReception: boolean;
  /** Réception d'une copie dans votre boite mail */
  receptionCopie: boolean;
  /** pièces jointes passées en PDF */
  pdf: boolean;
  /** Documents */
  idsDocuments?: string[] | null;
}

export interface MailDestinataire {
  /** @minLength 1 */
  nom: string;
  role?: string | null;
  type: TypeDestinataireEmetteur;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
}

export interface MailEmetteur {
  /** @minLength 1 */
  nom: string;
  type: TypeDestinataireEmetteur;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  signature?: string | null;
}

export interface MailRequest {
  idBrouillon?: string | null;
  /** Emetteur du mail (Ref Annuaire) */
  emetteur: MailEmetteur;
  /** Le ou les emails des destinataires */
  destinataires?: MailDestinataire[] | null;
  /** Le ou les emails des destinataires libres */
  destinatairesLibres?: string[] | null;
  /** Le ou les emails des copies */
  destinatairesCC?: MailDestinataire[] | null;
  destinatairesLibresCC?: string[] | null;
  /** Le ou les emails des copies cachées */
  destinatairesCCI?: MailDestinataire[] | null;
  destinatairesLibresCCI?: string[] | null;
  /**
   * L'objet du mail
   * @minLength 1
   */
  objet: string;
  /**
   * Message du mail
   * @minLength 1
   */
  message: string;
  /** Signature du mail */
  signature?: string | null;
  /** Mail brouillon */
  brouillon?: boolean;
  /** Demander une confirmation de réception */
  confirmationReception?: boolean;
  /** Réception d'une copie dans votre boite mail */
  receptionCopie?: boolean;
  /** Ne pas laisser les pièces jointes dans leur format original (donc passé en PDF) */
  passerEnPDF?: boolean;
  idsDocuments?: string[] | null;
}

export interface MailType {
  /** @minLength 1 */
  object: string;
  /** @minLength 1 */
  message: string;
}

export interface Mandant {
  id?: string | null;
  nom?: string | null;
  referance?: string | null;
}

export interface MandantRequest {
  id?: string | null;
  referance?: string | null;
  isMandatire: boolean;
}

export enum MediathequeType {
  PhotoAvantTravaux = "PhotoAvantTravaux",
  PhotoApresTravaux = "PhotoApresTravaux",
}

export interface Message {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  objet: string;
  /** @minLength 1 */
  commentaire: string;
  /** @format date-time */
  date: string;
  lu: boolean;
  emetteur: MessageEmetteur;
  destinataire: MessageDestinataire;
}

export interface MessageDestinataire {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export interface MessageEmetteur {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  nom: string;
}

export interface MessageRequest {
  /** Le ou les destinataire de l'EI */
  destinataires: string[];
  /**
   * L'objet de l'EI
   * @minLength 1
   */
  objet: string;
  /**
   * Message de l'EI
   * @minLength 1
   */
  message: string;
}

export interface Metre {
  /** @format double */
  humidite: number;
  commentaire?: string | null;
  /** @format date-time */
  dateCreation: string;
  /** @minLength 1 */
  metreur: string;
  pieces: Piece[];
  articles: Article[];
  pieceJointes: PieceJointeSimplifieModel[];
}

export interface MetreDossier {
  /** @minLength 1 */
  refMetre: string;
  /** @format double */
  humidite: number;
  commentaire?: string | null;
  /** @format date-time */
  dateCreation: string;
  /** @minLength 1 */
  metreur: string;
}

export interface MetreRequest {
  /** @format double */
  humidite?: number | null;
  commentaire?: string | null;
  pieces?: Piece[] | null;
  articles?: Article[] | null;
}

export interface MetreSimplifie {
  metreur?: CodeLabel | null;
  commentaire?: string | null;
}

export interface Mission {
  entiteMissionnee?: CodeLabel | null;
  entiteTraintante?: string | null;
  /** @format date-time */
  dateReception?: string | null;
  /** @format double */
  montantOuverture?: number | null;
  codeEtat?: CodeLabel | null;
  typeActivite?: CodeLabel | null;
  /** @format double */
  thInitial?: number | null;
  gestionnaire?: CodeLabel | null;
  missionUrgente: boolean;
  franchise?: Franchise | null;
  autreReferance?: string | null;
  metreSimplifie?: MetreSimplifie | null;
  travauxSimplifie?: TravauxSimplifie | null;
}

export interface MissionRequest {
  /** @format date-time */
  dateReception: string;
  /** @format double */
  montantOuverture?: number | null;
  typeActivite?: string | null;
  /** @format int32 */
  typeAssure: number;
  /** @minLength 1 */
  gestionnaire: string;
  /** @format double */
  montantFranchise: number;
  autreReferance?: string | null;
}

export interface Missionnement {
  /** @minLength 1 */
  dossier: string;
  /** @format date-time */
  dateMissionnement: string;
  etatMissionnement: EtatMissionnement;
  motifRefus?: MissionnementMotifRefus | null;
  commentaire?: string | null;
  lectureSeule?: boolean | null;
  /** @format date-time */
  dateModificationEtat: string;
  /** @format double */
  franchise?: number | null;
}

export interface MissionnementEtatRequest {
  etat: EtatMissionnement;
  /** @format int32 */
  motifRefus?: number | null;
  commentaire?: string | null;
}

export interface MissionnementMotifRefus {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  libelle: string;
  urgentUniqement: boolean;
  typeRefus: boolean;
}

export interface MissionnementRequest {
  /** @format int32 */
  idEtablissement: number;
}

export interface ModeleMail {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  objet: string;
  /** @minLength 1 */
  libelle: string;
  /** @minLength 1 */
  texte: string;
  sources?: TypeCondition | null;
  typesSinistre?: TypeCondition | null;
  compagnies?: TypeCondition | null;
}

export interface ModeleSMS {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  /** @minLength 1 */
  texte: string;
  typeSMS?: TypeSMS | null;
  /** @format int32 */
  delai?: number | null;
  sources?: TypeCondition | null;
  typesSinistre?: TypeCondition | null;
  compagnies?: TypeCondition | null;
}

export interface ModifierMandantRequest {
  /**
   * @minLength 1
   * @pattern ^[a-zA-Z0-9]{2}$
   */
  source: string;
  /**
   * @minLength 1
   * @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$
   */
  id: string;
  isMandataire: boolean;
  referance?: string | null;
}

export enum NatureOperation {
  Destructive = "Destructive",
  NonDestructive = "NonDestructive",
}

export interface NatureOperationEnumLabel {
  code: NatureOperation;
  libelle?: string | null;
}

export interface NatureSinistre {
  code?: string | null;
  libelle?: string | null;
}

export interface NestedCodeLabel {
  nestedCodeLabels?: CodeLabel[] | null;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface NombreResultatParStatut {
  statutComposeDossier?: StatutComposeDossier | null;
  /** @format int32 */
  nombreResultat?: number;
}

export interface NombreResultatParStatutCommande {
  etat?: EtatCommandeEnumLabel | null;
  /** @format int32 */
  nombreResultat?: number;
}

export interface NombreResultatParStatutFacture {
  etat?: EtatFactureEnumLabel | null;
  /** @format int32 */
  nombreResultat?: number;
}

export enum OrigineDemandeInterventionDifferee {
  NonPrecise = "NonPrecise",
  Beneficiaire = "Beneficiaire",
  Prestataire = "Prestataire",
}

export interface OrigineDemandeInterventionDiffereeEnumLabel {
  code: OrigineDemandeInterventionDifferee;
  libelle?: string | null;
}

export interface ParametrageLogiciel {
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
  version?: string | null;
  nom?: string | null;
  chemin?: string | null;
}

export interface PeiRole {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  /** @minLength 1 */
  qualite: string;
}

export interface PeiRoleRequest {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  /** @minLength 1 */
  qualite: string;
}

export interface Personne {
  /** @minLength 1 */
  id: string;
  /** @maxLength 32 */
  nom?: string | null;
  /** @maxLength 32 */
  prenom?: string | null;
  adresse?: Adresse | null;
  email?: string | null;
  /** @minLength 1 */
  telephonePortable: string;
  /** @minLength 1 */
  telephoneFixe: string;
}

export interface PersonneRequest {
  /** @pattern ^A¤[0-9]{4}¤[a-zA-Z0-9]{2}¤[0-9]{8}¤[a-zA-Z0-9]{1,32}¤[0-9]+¤[0,1]¤[0-9]+$ */
  id?: string | null;
  /**
   * @minLength 1
   * @maxLength 32
   */
  nom: string;
  /**
   * @minLength 1
   * @maxLength 32
   */
  prenom: string;
  adresse: AdresseRequest;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  telephone: string;
}

export interface Piece {
  deleteOperation?: boolean;
  id?: string | null;
  libelle?: string | null;
  /** @minLength 1 */
  type: string;
  commentaire?: string | null;
  /** @format double */
  largeur?: number | null;
  /** @format double */
  longeur?: number | null;
  /** @format double */
  hauteur?: number | null;
  /** @format double */
  volume?: number | null;
  /** @format double */
  superficie?: number | null;
  surfaces?: Surface[] | null;
  articles?: Article[] | null;
}

export interface PieceJointe {
  id?: string | null;
  /** @minLength 1 */
  fileName: string;
  url?: string | null;
  urlVignette?: string | null;
  /**
   * @minLength 1
   * @maxLength 30
   */
  libelle: string;
}

export interface PieceJointeSimplifie {
  libelle?: string | null;
  fileName?: string | null;
  url?: string | null;
}

export interface PieceJointeSimplifieModel {
  libelle?: string | null;
  fileName?: string | null;
  url?: string | null;
}

export interface PiedDevis {
  /** @format double */
  montantBrutHT?: number | null;
  /** @format double */
  montantRemise?: number | null;
  /** @format double */
  montantHT?: number | null;
  /** @format double */
  montantTVA?: number | null;
  /** @format double */
  montantTTC?: number | null;
  /** @format double */
  montantFranchise?: number | null;
  /** @format double */
  montantNetAPayer?: number | null;
  lignesTVAPiedDevis?: LigneTVAPiedDevis[] | null;
}

export interface PiedFacture {
  /** @format double */
  montantBrutHT?: number | null;
  /** @format double */
  montantRemise?: number | null;
  /** @format double */
  montantHT?: number | null;
  /** @format double */
  montantTVA?: number | null;
  /** @format double */
  montantTTC?: number | null;
  /** @format double */
  montantFranchise?: number | null;
  /** @format double */
  montantNetAPayer?: number | null;
  lignesTVAPiedFacture?: LigneTVAPiedFacture[] | null;
}

export interface PriseDeContact {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  date: string;
  /** @format date-time */
  dateCreation: string;
  libelle?: string | null;
  commentaire?: string | null;
  aboutie: boolean;
}

export interface PriseDeContactRequest {
  /** @format date-time */
  date: string;
  libelle?: string | null;
  commentaire?: string | null;
  aboutie: boolean;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface QualificationDDERequest {
  tauxHumidite?: TauxHumidite | null;
  suppressionCause?: SuppressionCause | null;
}

export interface Qualite {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  /** @minLength 1 */
  codeQualite: string;
}

export interface QualiteRequest {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
  /** @minLength 1 */
  qualite: string;
}

/** Represente une récurrence */
export interface Recurrence {
  type?: TypeRecurrenceEnumLabel | null;
  /**
   * Intervalle de la recurrence (tous les 2 jours, intervalle=2)
   * @format int32
   * @default 1
   */
  intervalle?: number | null;
  /** Jour(s) de la semaine (0 = dimanche..., lun. et jeu. = 14) */
  jours?: string | null;
  /**
   * Date de fin de la récurrence
   * @format date-time
   */
  finRecurrence?: string | null;
  /**
   * Date de fin de la récurrence
   * @format date-time
   */
  debutRecurrence?: string | null;
}

export interface RefAnnuaire {
  /** @minLength 1 */
  refComplete: string;
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  famille: string;
  professionnel: boolean;
}

export interface RefDossier {
  /** @minLength 1 */
  annee: string;
  /** @minLength 1 */
  source: string;
  /** @minLength 1 */
  numero: string;
  /** @minLength 1 */
  refComplete: string;
}

export interface RegisterEntrepriseRequest {
  /** @minLength 1 */
  username: string;
  /** @format int32 */
  etablissement: number;
  /** @format int32 */
  entreprise: number;
}

export interface RegisterRequest {
  /** @minLength 1 */
  username: string;
  /** @minLength 1 */
  password: string;
  /** @minLength 1 */
  codeAnnuaire: string;
  /** @minLength 1 */
  codeFamille: string;
}

export interface RendezVous {
  type: TypeRendezVousEnumLabel;
  expert: Expert;
  /** Represente une récurrence */
  recurrence?: Recurrence | null;
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  /** @minLength 1 */
  assure: string;
  adresseRendezVous: Adresse;
  /** @minLength 1 */
  referenceSinistre: string;
  natureSinistre: CodeLabel;
  etatRendezVous?: EtatRendezVous;
}

export interface RendezVousFilter {
  dateRange?: DateRange | null;
  metreurs?: string[] | null;
  rendezVousFilters?: TypeRendezVous[] | null;
}

export interface RendezVousMetreur {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  journeeComplete: boolean;
  dossierSinapps: boolean;
}

export interface RendezVousMetreurRequest {
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
}

export interface RendezVousTravaux {
  /** @minLength 1 */
  id: string;
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  journeeComplete: boolean;
  dossierSinapps: boolean;
}

export interface RendezVousTravauxRequest {
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
}

export interface RendezVousTravauxUpdateRequest {
  /** @format date-time */
  dateDebut: string;
  /** @format date-time */
  dateFin: string;
  motif?: string | null;
  commentaire?: string | null;
}

export interface RepondreEchangeInterneRequest {
  /**
   * L'objet de l'EI
   * @minLength 1
   */
  objet: string;
  /**
   * Message de l'EI
   * @minLength 1
   */
  message: string;
}

export interface RepondreMessageRequest {
  /**
   * L'objet de l'EI
   * @minLength 1
   */
  objet: string;
  /**
   * Message de l'EI
   * @minLength 1
   */
  message: string;
}

export interface ResetPasswordEntrepriseRequest {
  /** @minLength 1 */
  token: string;
  /** @minLength 1 */
  newPassword: string;
}

export interface ResetPasswordEntrepriseResponse {
  /** @minLength 1 */
  url: string;
  /** @minLength 1 */
  email: string;
}

export interface ResultatDashboardCommande {
  nombreResultatParStatut: NombreResultatParStatutCommande[];
  resultats: DashboardCommande[];
  /** @format int32 */
  totalResultats: number;
}

export interface ResultatDashboardFacture {
  nombreResultatParStatut: NombreResultatParStatutFacture[];
  resultats: DashboardFacture[];
  /** @format int32 */
  totalResultats: number;
}

export enum ResultatIntervention {
  Repare = "Repare",
  ReparePartiellement = "ReparePartiellement",
  NonRepare = "NonRepare",
}

export interface ResultatInterventionEnumLabel {
  code: ResultatIntervention;
  libelle?: string | null;
}

export interface ResultatRechercheDossier {
  nombreResultatParStatut?: NombreResultatParStatut[] | null;
  /** @format int32 */
  totalResultats?: number;
  resultats?: DossierRecherche[] | null;
}

export interface Risque {
  typeRisque?: string | null;
  usageRisque?: string | null;
  risqueId?: string | null;
}

export enum RisqueAggrave {
  DefautEntretienManifeste = "DefautEntretienManifeste",
  InstallationElectriqueVetuste = "InstallationElectriqueVetuste",
  OccupationARisque = "OccupationARisque",
  MultiplesSinistresOuTraces = "MultiplesSinistresOuTraces",
  EnvironnementARisque = "EnvironnementARisque",
  ElementPotentiellementAmiante = "ElementPotentiellementAmiante",
  Autre = "Autre",
}

export interface RisqueAggraveEnumLabel {
  code: RisqueAggrave;
  libelle?: string | null;
}

export enum RisqueAggraveIdentifie {
  DefautEntretienManifeste = "DefautEntretienManifeste",
  InstallationElectriqueVetuste = "InstallationElectriqueVetuste",
  OccupationARisque = "OccupationARisque",
  MultiplesSinistresOuTraces = "MultiplesSinistresOuTraces",
  EnvironnementARisque = "EnvironnementARisque",
  ElementPotentiellementAmiante = "ElementPotentiellementAmiante",
  Autre = "Autre",
}

export interface SinappsRisqueRequest {
  risqueCorrect: boolean;
  typeRisque?: string | null;
  usageAssure?: string | null;
  relationAuRisque?: string | null;
  incoherenceRisqueDeclare?: string | null;
}

export interface SinappsSinistreRequest {
  sinistreCorrect: boolean;
  /** @format date-time */
  dateSinistre?: string | null;
  heure?: string | null;
  natureSinistre?: string | null;
  causeSinistre?: string | null;
  reserve?: string | null;
  qualificationDDERequest?: QualificationDDERequest | null;
}

export interface Sinistre {
  referanceSinistre?: string | null;
  nature?: string | null;
  /** @format date-time */
  date?: string;
  adresse?: Adresse | null;
  /** @format double */
  sinistreLatitude?: number | null;
  /** @format double */
  sinistreLongitude?: number | null;
  sinistreCommentaire?: string | null;
}

export interface SinistreRequest {
  referanceSinistre?: string | null;
  nature?: string | null;
  /** @format date-time */
  date?: string;
  adresse?: AdresseSinistre | null;
  sinistreCommentaire?: string | null;
}

export interface SinistreToiture {
  etatToiture?: string | null;
  typeToiture?: string | null;
  precisionTypeToiture?: string | null;
}

export interface SinistreToitureRequest {
  etatToiture?: string | null;
  typeToiture?: string | null;
  precisionTypeToiture?: string | null;
}

export interface SourceMandant {
  /** @minLength 1 */
  id: string;
  /** @format int32 */
  tarification: number;
}

export interface SousJalonAttestationFinDeTravaux {
  attestationFinDeTravaux?: Document | null;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface SousJalonAttestationTva {
  etat: WorkflowEtat;
  /** @format date-time */
  dateValidation?: string | null;
  attestationTva?: Document[] | null;
}

export interface SousJalonBonDeCommande {
  bonDeCommande?: Document | null;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface SousJalonConfirmationDeVisite {
  confirmationDeVisite?: Document | null;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface SousJalonPhotoApresTravaux {
  photoApresTravaux: ElementMediatheque[];
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface SousJalonPhotoAvantTravaux {
  photoAvantTravaux: ElementMediatheque[];
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface SousJalonRapportRechercheDeFuite {
  rapportRechercheDeFuite?: Document | null;
  /** @format date-time */
  dateValidation?: string | null;
  etat: WorkflowEtat;
}

export interface StatusAvecCouleurRequest {
  code?: string | null;
  libelle?: string | null;
  couleurPack?: CouleurPack | null;
}

export enum StatutCommandeSinapps {
  EnAttente = "EnAttente",
  Succes = "Succes",
  RefuseSinapps = "RefuseSinapps",
  IgnoreUtilisateur = "IgnoreUtilisateur",
  InformationRenvoyeeParUtilisateur = "InformationRenvoyeeParUtilisateur",
  RelanceUtilisateur = "RelanceUtilisateur",
  Inconnu = "Inconnu",
}

export interface StatutCommandeSinappsEnumLabel {
  code: StatutCommandeSinapps;
  libelle?: string | null;
}

export interface StatutComposeDossier {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
}

export enum StatutDevis {
  Annule = "Annule",
  Valide = "Valide",
}

export interface StatutDevisEnumLabel {
  code: StatutDevis;
  libelle?: string | null;
}

export interface StatutDossier {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
}

export interface SuiviSimplifie {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  libelle: string;
  type: TypeSuiviEnumLabel;
  /** @format date-time */
  date?: string | null;
  /** @minLength 1 */
  commentaire: string;
  /** @minLength 1 */
  emetteur: string;
  destinataires: string[];
  pieceJointes: PieceJointeSimplifie[];
  /** @minLength 1 */
  statut: string;
}

export interface SuppressionCause {
  causeSupprimee: boolean;
  motif?: string | null;
  precision?: string | null;
  /** @format date-time */
  dateReparation?: string | null;
}

export interface Surface {
  id?: string | null;
  libelle?: string | null;
  type: TypeSurface;
  /** @format double */
  longueur?: number | null;
  /** @format double */
  largeur?: number | null;
  /** @format double */
  superficie?: number | null;
  deleteOperation?: boolean;
  decotes?: Decote[] | null;
  articles?: Article[] | null;
}

export interface SyntheseDossier {
  assure?: Assure | null;
  compagnie?: Compagnie | null;
  sinistre?: Sinistre | null;
  documents?: PieceJointe[] | null;
}

export interface TauxHumidite {
  /** @format int32 */
  valeur: number;
  localisationDuReleve?: string | null;
  assechementEngage?: boolean | null;
}

export interface TauxTVA {
  /** TVA */
  tva: CodeLabel;
  /**
   * Taux de TVA
   * @format float
   */
  taux: number;
}

export interface TransfertEchangeInterneRequest {
  destinataires: string[];
}

export interface TravauxSimplifie {
  technicien?: CodeLabel | null;
  commentaire?: string | null;
}

export interface TypeAssureRequest {
  /** @format int32 */
  code: number;
  /** @minLength 1 */
  libelle: string;
}

export enum TypeCommentaire {
  EVT = "EVT",
  MAIL = "MAIL",
  SMS = "SMS",
}

export interface TypeCommentaireCodeLabelEnum {
  enum: TypeCommentaire;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface TypeCommentaireCodeLabelEnumRequest {
  enumType: TypeCommentaire;
  /** @minLength 1 */
  code: string;
  libelle?: string | null;
}

export interface TypeCommentaireEnumLabel {
  code: TypeCommentaire;
  libelle?: string | null;
}

export interface TypeCondition {
  conditions?: string[] | null;
  toutes?: boolean;
}

export enum TypeDestinataireEmetteur {
  Expert = "Expert",
  Assistante = "Assistante",
  Assure = "Assuré",
  Tiers = "Tiers",
  Intervenant = "Intervenant",
  Cabinet = "Cabinet",
  Bureau = "Bureau",
  Moi = "Moi",
}

export enum TypeDocument {
  AttestationTva = "AttestationTva",
  AttestationFinDeTravaux = "AttestationFinDeTravaux",
  BonDeCommande = "BonDeCommande",
  ConfirmationDeVisite = "ConfirmationDeVisite",
  RapportRechercheDeFuite = "RapportRechercheDeFuite",
  Devis = "Devis",
  Facture = "Facture",
  AvisAssurance = "AvisAssurance",
  Metre = "Metre",
  PieceJointeCompteRenduRDF = "PieceJointeCompteRenduRDF",
}

export enum TypeElementMediatheque {
  Image = "Image",
  Document = "Document",
}

export enum TypeModif {
  Creation = "Creation",
  Update = "Update",
}

export enum TypePersonneEnum {
  None = "None",
  PersonnePhysique = "PersonnePhysique",
  PersonneMorale = "PersonneMorale",
}

export interface TypePiece {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  libelle: string;
}

export enum TypePrestation {
  ReparationEnNature = "ReparationEnNature",
  RechercheDeFuite = "RechercheDeFuite",
}

export interface TypePrestationEnumLabel {
  code: TypePrestation;
  libelle?: string | null;
}

export enum TypeRecurrence {
  Quotidienne = "Quotidienne",
  Hebdomadaire = "Hebdomadaire",
  Mensuelle = "Mensuelle",
  Annuelle = "Annuelle",
}

export interface TypeRecurrenceEnumLabel {
  code: TypeRecurrence;
  libelle?: string | null;
}

export enum TypeRefus {
  ANNULE = "ANNULE",
  REFUSE = "REFUSE",
}

export enum TypeRendezVous {
  Travaux = "Travaux",
  Urgent = "Urgent",
  Metre = "Metre",
  RDF = "RDF",
}

export interface TypeRendezVousEnumLabel {
  code: TypeRendezVous;
  libelle?: string | null;
}

export enum TypeSMS {
  RDVCONFIRMATION = "RDVCONFIRMATION",
  RDVRAPPEL = "RDVRAPPEL",
  RAPPORT = "RAPPORT",
  PONCTUEL = "PONCTUEL",
}

export enum TypeSuivi {
  DocumentAlaFrappe = "DocumentAlaFrappe",
  Rapport = "Rapport",
  TravailAdministratif = "TravailAdministratif",
  EchangeInterne = "EchangeInterne",
  DelaisCharte = "DelaisCharte",
  RendezVous = "RendezVous",
  RendezVousAnnule = "RendezVousAnnule",
  Email = "Email",
  Relance = "Relance",
  Differe = "Differe",
  Datable = "Datable",
  Info = "Info",
  Systeme = "Système",
  Divers = "Divers",
  Facture = "Facture",
  Avoir = "Avoir",
  Devis = "Devis",
  Extranets = "Extranets",
  SMS = "SMS",
  PJ = "PJ",
}

export interface TypeSuiviEnumLabel {
  code: TypeSuivi;
  libelle?: string | null;
}

export enum TypeSurface {
  Sol = "Sol",
  Mur = "Mur",
  Plafond = "Plafond",
}

export interface UpdateBanqueRequest {
  codeEtablissement?: string | null;
  codeLong?: string | null;
  nomBeneficiaire?: string | null;
  codeGuichet?: string | null;
  compteBeneficiaire?: string | null;
  libelle?: string | null;
}

export interface UpdateBordereauRequest {
  /** @pattern ^[a-zA-Z0-9]{1,5}$ */
  famille?: string | null;
  /** @maxLength 80 */
  codeArticle?: string | null;
  libelle?: string | null;
  description?: string | null;
  unite?: string | null;
  /** @maxItems 15 */
  tarifs?: number[] | null;
}

export interface UpdateCompteEchange {
  email?: string | null;
  motDePasse?: string | null;
  active?: boolean;
}

export interface UpdateFamilleAnnuaire {
  professionnel?: boolean | null;
  libelle?: string | null;
  typePersonne?: TypePersonneEnum | null;
  visible?: boolean | null;
}

export interface UpdateIntervenantRequest {
  /** @maxLength 32 */
  nom?: string | null;
  /** @maxLength 32 */
  prenom?: string | null;
  civilite?: string | null;
  formulePolitesse?: string | null;
  qualite?: string | null;
  adresse?: IntervenantAdresse | null;
  email?: string | null;
  telephone?: string | null;
}

export interface UpdateMissionRequest {
  entiteMissionnee?: string | null;
  /** @format date-time */
  dateReception?: string | null;
  /** @format double */
  montantOuverture?: number | null;
  codeEtat?: string | null;
  typeActivite?: string | null;
  /** @format double */
  thInitial?: number | null;
  gestionnaire?: string | null;
  missionUrgente: boolean;
  franchise?: Franchise | null;
  autreReferance?: string | null;
  metreur?: string | null;
  commentaireMetreur?: string | null;
  technicien?: string | null;
  commentaireTravaux?: string | null;
}

export interface UpdateModelEmail {
  objet?: string | null;
  libelle?: string | null;
  texte?: string | null;
  sources?: TypeCondition | null;
  typesSinistre?: TypeCondition | null;
  companies?: TypeCondition | null;
}

export interface UpdateModeleSMSRequest {
  libelle?: string | null;
  texte?: string | null;
  typeSMS?: TypeSMS | null;
  /** @format int32 */
  delai?: number | null;
  sources?: TypeCondition | null;
  typesSinistre?: TypeCondition | null;
  companies?: TypeCondition | null;
}

export interface UpdateParametrageLogicielRequest {
  libelle?: string | null;
  version?: string | null;
  nom?: string | null;
  chemin?: string | null;
}

export interface UpdateSinistreRequest {
  /** @format date-time */
  date?: string | null;
  referanceSinistre?: string | null;
  commentaire?: string | null;
  nom?: string | null;
  nature?: string | null;
  adresse?: AdresseSinistre | null;
}

export interface UpdateSourceMandant {
  prestatire?: string | null;
  /** @format int32 */
  tarification?: number | null;
}

export interface UpdateTVARequest {
  libelle?: string | null;
  /** @format float */
  taux?: number | null;
  dE0235?: string | null;
}

export interface ValidationProblemDetails {
  errors?: Record<string, string[]>;
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface WorkflowEntreprise {
  jalonCreation: JalonCreation;
  jalonMissionnement: JalonMissionnement;
  jalonIntervention?: JalonIntervention | null;
  jalonPostIntervention?: JalonPostIntervention | null;
  jalonPriseDeContact: JalonPriseDeContact;
  jalonRDVMetre: JalonRendezVousMetre;
  jalonPostRdvMetre: JalonPostRdvMetre;
  jalonDevis: JalonDevis;
  jalonRDVTravaux: JalonRendezVousTravaux;
  jalonFinDeTravaux: JalonFinDeTravaux;
  jalonFacture: JalonFacture;
  jalonDossierTermine: JalonSimplifie;
  dossierUrgent: boolean;
  dossierSinapps: boolean;
  jalonCompteRenduVisite?: JalonCompteRenduVisite | null;
}

export enum WorkflowEtat {
  Fait = "Fait",
  NonFait = "NonFait",
  Annule = "Annule",
  EnAttente = "EnAttente",
  NonAboutie = "NonAboutie",
  Brouillon = "Brouillon",
  Anomalie = "Anomalie",
}

export enum WorkflowJalon {
  Reception = "Reception",
  Contact = "Contact",
  RDVMetre = "RDVMetre",
  CompteRenduVisite = "CompteRenduVisite",
  Devis = "Devis",
  RDVTravaux = "RDVTravaux",
  FinDeTravaux = "FinDeTravaux",
  DossierTermine = "DossierTermine",
  Facture = "Facture",
  Missionnement = "Missionnement",
  AttestationTva = "AttestationTva",
  PostRdvMetre = "PostRdvMetre",
  Creation = "Creation",
  Intervention = "Intervention",
  PostIntervention = "PostIntervention",
}

export interface WorkflowJalonEnumLabel {
  code: WorkflowJalon;
  libelle?: string | null;
}

export interface ZoneDeCouverture {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  codePostal: string;
  autoriseMissionClassique: boolean;
  autoriseMissionUrgente: boolean;
}

export interface ZoneDeCouvertureRequest {
  /** @minLength 1 */
  codePostal: string;
  autoriseMissionClassique: boolean;
  autoriseMissionUrgente: boolean;
}

export type AnomalieDossierUpdatePayload = AnomalieModificationRequest;

export type AnomalieDossierCreatePayload = AnomalieRequest;

export type AnomalieTypeUpdatePayload = AnomalieTypeModificationRequest;

export type AnomalieTypeCreatePayload = AnomalieTypeRequest;

export type LoginAssureurCreatePayload = AnnuaireLoginExtranetAssureur;

export type LoginAuthentificationCreatePayload = LoginRequest;

export type RegisterAuthentificationCreatePayload = RegisterRequest;

export interface LoginExtranetAssureAuthentificationCreateParams {
  refExtranet?: string;
}

export type LoginExtranetAssureurAuthentificationCreatePayload = LoginRequest;

export type LoginExtranetEntrepriseAuthentificationCreatePayload = LoginRequest;

export type RegisterExtranetEntrepriseAuthentificationCreatePayload = RegisterEntrepriseRequest;

export type ResetPasswordExtranetEntrepriseAuthentificationCreatePayload = ResetPasswordEntrepriseRequest;

export type AskForPasswordResetExtranetEntrepriseAuthentificationCreatePayload = AskForPasswordResetEntrepriseRequest;

export type LoginIrenAuthentificationCreatePayload = LoginRequest;

export interface LoginIrenVerrueAuthentificationCreateParams {
  encryptedString?: string;
}

export type LoginIrenMobileAuthentificationCreatePayload = LoginRequest;

export interface LoginIrenExtranetAuthentificationCreateParams {
  encryptedString?: string;
  typeCollaborateur?: CurrentCollaborateurType;
}

export interface FacturesDashboardListParams {
  Etats?: EtatFacture[];
  CompagnieAssurance?: string[];
  NatureSinistre?: string[];
  /** @format date-time */
  DateDebut?: string;
  /** @format date-time */
  DateFin?: string;
  /** @format int32 */
  IndexDebut?: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  Limite?: number;
  RechercheLibre?: string;
  Departement?: string;
}

export interface DevisDashboardListParams {
  Etats?: EtatDevis[];
  CompagnieAssurance?: string[];
  NatureSinistre?: string[];
  /** @format date-time */
  DateDebut?: string;
  /** @format date-time */
  DateFin?: string;
  /** @format int32 */
  IndexDebut?: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  Limite?: number;
  RechercheLibre?: string;
  Departement?: string;
}

export interface CommandesDashboardListParams {
  Etats?: EtatCommande[];
  CompagnieAssurance?: string[];
  NatureSinistre?: string[];
  /** @format date-time */
  DateDebut?: string;
  /** @format date-time */
  DateFin?: string;
  /** @format int32 */
  IndexDebut?: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  Limite?: number;
  RechercheLibre?: string;
  Departement?: string;
}

export type PiedDevisCreatePayload = DevisRequest;

export interface BrouillonDevisDetailParams {
  refuse?: boolean;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export type BrouillonDevisCreatePayload = DevisRequest;

export type EditerDevisCreatePayload = DevisRequest;

export interface EditerDevisCreateParams {
  numeroDevis?: string;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export interface ImportDevisCreatePayload {
  /** @format binary */
  Fichier: File;
  Nom: string;
}

export interface ChangerStatutDevisPartialUpdatePayload {
  /** @format int32 */
  numeroDevis: number;
  statut: StatutDevis;
  motifRefus?: string;
  /** @format binary */
  pieceJointe?: File;
}

export interface MediathequeDossierDetailParams {
  Categorie?: string;
  SousCategorie?: string;
  Type?: MediathequeType;
  dossier: string;
}

export interface MediathequeDossierCreatePayload {
  elementMediathequeRequest?: ElementMediathequeRequest[];
}

export interface DocumentDossierDetailParams {
  AvecBrouillon?: boolean;
  /** @format int32 */
  CodeAction?: number;
  Type?: TypeDocument;
  dossier: string;
}

export interface DocumentDossierCreatePayload {
  documentRequests?: DocumentRequest[];
}

export type NotationAssureDossierCreatePayload = ExpAssureReponsesRequest[];

export interface NotationAssureDossierCreateParams {
  commentaire?: string;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export type DossierCreatePayload = CreationDossierRequest;

export type DossierIrenCreatePayload = CreationDossierIrenRequest;

export type SinistreDossierPartialUpdatePayload = UpdateSinistreRequest;

export interface ChangesetDossierListParams {
  /** @format date-time */
  from: string;
}

export interface EchangeInterneListParams {
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  destinataire?: string;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier?: string;
  emetteur?: string;
  /** @format int32 */
  startIndex?: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   * @default 100
   */
  limit?: number;
}

export type NouveauEchangeInterneCreatePayload = EchangeInterneRequest;

export type RepondreEchangeInterneCreatePayload = RepondreEchangeInterneRequest;

export type TransfertEchangeInterneCreatePayload = TransfertEchangeInterneRequest;

export interface DestinatairesEchangeInterneDetailParams {
  /**
   * retourner les assistantes
   * @default true
   */
  assistante?: boolean;
  /**
   * retourner les experts
   * @default true
   */
  expert?: boolean;
  /** @default false */
  responsables?: boolean;
  /**
   * la référence du dossier
   * @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$
   */
  dossier: string;
}

export type EntreprisePartenaireUpdatePayload = EntreprisePartenaireRequest;

export type EntreprisePartenaireCreatePayload = EntreprisePartenaireRequest;

export type DocumentEntreprisePartenaireCreatePayload = DocumentJuridiqueRequest;

export type DocumentEntreprisePartenaireUpdatePayload = DocumentJuridiqueRequest;

export type EtablissementEntreprisePartenaireCreatePayload = EtablissementRequest;

export type EtablissementEntreprisePartenaireUpdatePayload = EtablissementRequest;

export type CongeEtablissementCreatePayload = EtablissementCongeRequest;

export type CongeEtablissementUpdatePayload = EtablissementCongeRequest;

export type HoraireEtablissementCreatePayload = EtablissementHoraireRequest;

export type HoraireEtablissementUpdatePayload = EtablissementHoraireRequest;

export type ContactEtablissementCreatePayload = ContactEntrepriseRequest;

export type ContactEtablissementUpdatePayload = ContactEntrepriseRequest;

export type MetiersEtablissementUpdatePayload = AffectationMetierRequest[];

export type ZoneDeCouvertureEtablissementUpdatePayload = ZoneDeCouvertureRequest[];

export type PiedFactureCreatePayload = FactureRequest;

export interface BrouillonFactureDetailParams {
  refuse?: boolean;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export type BrouillonFactureCreatePayload = FactureRequest;

export type EditerFactureCreatePayload = FactureRequest;

export interface EditerFactureCreateParams {
  numeroFacture?: string;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export interface ImportFactureCreatePayload {
  /** @format binary */
  Fichier: File;
  Nom: string;
}

export type ChangerStatutFacturePartialUpdatePayload = ChangerStatutRequest;

export type InterventionDossierCreatePayload = InterventionRequest;

export type InterventionUpdatePayload = InterventionRequest;

export interface LogsListParams {
  /** @format date-time */
  date?: string;
  /**
   * @format int32
   * @min 0
   * @max 10
   * @default 10
   */
  nbJours?: number;
  /** @pattern ^[ON]{1}¤[a-zA-Z]{2}¤.{1,32}$ */
  refCollaborateur?: string;
  path?: string;
}

export interface DestinatairesMailDetailParams {
  /** récupérer les destinataires en interne */
  interne?: boolean;
  /** récupérer les destinataires  tiers et intervenants */
  externe?: boolean;
  /**
   * référence du dossier
   * @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$
   */
  dossier: string;
}

export interface MailDossierDetailParams {
  brouillon?: boolean;
  /** @pattern ^[0-9]{4}-[a-zA-Z0-9]{2}-[0-9]{8}$ */
  dossier: string;
}

export type MailDossierCreatePayload = MailRequest;

export type MessagesDossierCreatePayload = MessageRequest;

export type RepondreMessagesCreatePayload = RepondreMessageRequest;

export type MetreCreatePayload = MetreRequest;

export type MissionnementDossierCreatePayload = MissionnementRequest;

export interface MissionnementMotifsRefusListParams {
  TypeRefus: TypeRefus;
  IsUrgent?: boolean;
  ForceUserDonneurDordre?: boolean;
}

export type EtatMissionnementPartialUpdatePayload = MissionnementEtatRequest;

export type MissionDossierPartialUpdatePayload = UpdateMissionRequest;

export type FormulePolitesseParametrageAnnuaireCreatePayload = CodeLabelRequest;

export type IntituleParametrageAnnuaireCreatePayload = CodeLabelRequest;

export type TypeMediaParametrageAnnuaireCreatePayload = CodeLabelRequest;

export type FamilleAnnuaireParametrageAnnuaireCreatePayload = CreateFamilleRequest;

export type FamillesAnnuaireParametrageAnnuairePartialUpdatePayload = UpdateFamilleAnnuaire;

export type CompteEchangeParametrageAnnuaireCreatePayload = CreateCompteEchange;

export type CompteEchangeParametrageAnnuairePartialUpdatePayload = UpdateCompteEchange;

export type RoleParametrageAnnuaireCreatePayload = PeiRoleRequest;

export type QualiteParametrageAnnuaireCreatePayload = QualiteRequest;

export type QualifiantParametrageAnnuaireCreatePayload = CodeLabelRequest;

export type AssujettiParametrageAnnuaireCreatePayload = CodeLabelRequest;

export type SourceMandantParametrageBordereauxCreatePayload = CreateSourceMandant;

export type SourceMandantParametrageBordereauxPartialUpdatePayload = UpdateSourceMandant;

export type BordereauxParametrageBordereauxCreatePayload = CreateBordereauRequest;

export type BordereauxParametrageBordereauxPartialUpdatePayload = UpdateBordereauRequest;

export type BibliotequeDiversParametrageBordereauxCreatePayload = BibliothequeDiversCategorieCodeLabelEnumRequest;

export type BanqueParametrageComptabiliteCreatePayload = CreateBanqueRequest;

export type BanqueParametrageComptabilitePartialUpdatePayload = UpdateBanqueRequest;

export type ParametrageLogicielParametrageComptabiliteCreatePayload = CreateParametrageLogicielRequest;

export type ParametrageLogicielParametrageComptabilitePartialUpdatePayload = UpdateParametrageLogicielRequest;

export type ModeReglementParametrageComptabiliteCreatePayload = CodeLabelRequest;

export type IntituleEchangeInterneParametrageDiversCreatePayload = CodeLabelRequest;

export type CategoriePieceJointeParametrageDiversCreatePayload = EtatCategorieCodeLabelEnumRequest;

export type SousCategoriePieceJointeParametrageDiversCreatePayload = EtatCategorieCodeLabelEnumRequest;

export type ActionGeneraleParametrageDiversCreatePayload = CodeLabelRequest;

export type FamilleNatureSinistreParametrageDossierCreatePayload = CodeLabelRequest;

export type NatureSinistreParametrageDossierCreatePayload = CodeLabelRequest;

export type TypeMissionParametrageDossierCreatePayload = CodeLabelRequest;

export type TypeAssureParametrageDossierCreatePayload = TypeAssureRequest;

export interface ModeleSmsParametrageEvenementListParams {
  Source?: string;
  Companie?: string;
  TypeSinistre?: string;
}

export type ModeleSmsParametrageEvenementCreatePayload = CreateModeleSMSRequest;

export type ModeleSmsParametrageEvenementPartialUpdatePayload = UpdateModeleSMSRequest;

export type CommentaireEvenementParametrageEvenementCreatePayload = TypeCommentaireCodeLabelEnumRequest;

export type ModeleMailParametrageEvenementCreatePayload = CreateModelEmail;

export type ModeleMailParametrageEvenementPartialUpdatePayload = UpdateModelEmail;

export interface ModelesMailParametrageEvenementListParams {
  Source?: string;
  Companie?: string;
  TypeSinistre?: string;
}

export type TypeRendezVousRdfParametrageEvenementCreatePayload = CodeLabelRequest;

export type TypeRendezVousTravauxParametrageEvenementCreatePayload = CodeLabelRequest;

export type EvenementExterieurParametrageEvenementCreatePayload = EtatEvenementExterieurCodeLabelEnumRequest;

export type StatutAlternatifCommandeParametrageVenteCreatePayload = StatusAvecCouleurRequest;

export type TauxTvaParametrageVenteCreatePayload = CreateTVARequest;

export type TauxTvaParametrageVentePartialUpdatePayload = UpdateTVARequest;

export type StatutAlternatifDevisParametrageVenteCreatePayload = StatusAvecCouleurRequest;

export type AssureDossierCreatePayload = PersonneRequest;

export type CoordonneesAssurePartialUpdatePayload = CoordoneesRequest;

export type IntervenantDossierCreatePayload = CreateIntervenantRequest;

export type IntervenantDossierPartialUpdatePayload = UpdateIntervenantRequest;

export type MandantDossierCreatePayload = ModifierMandantRequest;

export interface RechercheDossierListParams {
  Statut?: string[];
  CompagnieAssurance?: string[];
  NatureSinistre?: string[];
  /** @format date-time */
  DateDebut?: string;
  /** @format date-time */
  DateFin?: string;
  /** @format int32 */
  IndexDebut?: number;
  /**
   * @format int32
   * @min 1
   * @max 100
   */
  Limite?: number;
  RechercheLibre?: string;
  Departement?: string;
  /** @format int32 */
  TH?: number;
  Sinistre?: string;
  SinappsUniquement?: boolean;
  /** @format date-time */
  DateRDV?: string;
}

export interface RechercheBordereauListParams {
  rechercheLibre?: string;
}

export interface RechercheEntrepriseListParams {
  IdsCorpsMetier?: number[];
  CodePostal?: string;
  MissionUrgente?: boolean;
}

export type PriseDeContactDossierCreatePayload = PriseDeContactRequest;

export type MetreurRendezVousCreatePayload = RendezVousMetreurRequest;

export type MetreurRendezVousPartialUpdatePayload = RendezVousMetreurRequest;

export type TravauxRendezVousCreatePayload = RendezVousTravauxRequest;

export type TravauxRendezVousPartialUpdatePayload = RendezVousTravauxUpdateRequest;

export type RendezVousCreatePayload = RendezVousFilter;

export type CompteRenduVisiteSinappsCreatePayload = CompteRenduVisiteRequest;

export type CompteRenduRdfSinappsCreatePayload = CompteRenduRDFRequest;

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) => {
      const formData = new FormData();

      const typeIsArray = (array: any[], key: string) => {
        array.forEach((value: any, index: number) => {
          if (typeof value === "object") {
            Object.keys(value).forEach((k) => formData.append(`${key}[${index}].${k}`, value[k]));
          } else {
            formData.append(`${key}[${index}]`, value);
          }
        });
      };

      // Only if array was tested. Maybe the other paths need some fixes.
      Object.keys(input || {}).forEach((data) => {
        if (Array.isArray(input[data])) {
          typeIsArray(input[data], data);
        } else {
          if (typeof data === "object") {
            Object.keys(data).forEach((key, index) => formData.append(`${data}[${index}].${key}`, data[key]));
          } else {
            formData.append(`${data}`, input[data]);
          }
        }
      });
      return formData;
    },
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title IrenApi
 * @version 1.0.0.0904
 * @contact <euroinfo@euroinfo.fr> (http://www.euroinfo.fr )
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  dossier = {
    /**
     * No description
     *
     * @tags Anomalie
     * @name AnomaliesDossierDetail
     * @request GET:/Dossier/{dossier}/Anomalies
     * @secure
     */
    anomaliesDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Anomalie[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Anomalies`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Anomalie
     * @name AnomalieDossierDetail
     * @request GET:/Dossier/Anomalie/{id}
     * @secure
     */
    anomalieDossierDetail: (id: number, params: RequestParams = {}) =>
      this.request<Anomalie, ValidationProblemDetails>({
        path: `/Dossier/Anomalie/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Anomalie
     * @name AnomalieDossierUpdate
     * @request PUT:/Dossier/Anomalie/{id}
     * @secure
     */
    anomalieDossierUpdate: (id: number, data: AnomalieDossierUpdatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Anomalie/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Anomalie
     * @name AnomalieDossierCreate
     * @request POST:/Dossier/{dossier}/Anomalie
     * @secure
     */
    anomalieDossierCreate: (dossier: string, data: AnomalieDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Anomalie`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name PiedDevisCreate
     * @summary Calcule le pied d'un devis par rapport aux lignes saisies
     * @request POST:/Dossier/{dossier}/Devis/Pied
     * @secure
     */
    piedDevisCreate: (dossier: string, data: PiedDevisCreatePayload, params: RequestParams = {}) =>
      this.request<PiedDevis, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Devis/Pied`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name BrouillonDevisDetail
     * @summary Charge le devis brouillon de l'entreprise
     * @request GET:/Dossier/{dossier}/Devis/Brouillon
     * @secure
     */
    brouillonDevisDetail: ({ dossier, ...query }: BrouillonDevisDetailParams, params: RequestParams = {}) =>
      this.request<Devis, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Devis/Brouillon`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name BrouillonDevisCreate
     * @summary Création/Modification d'un nouveau devis en brouillon
     * @request POST:/Dossier/{dossier}/Devis/Brouillon
     * @secure
     */
    brouillonDevisCreate: (dossier: string, data: BrouillonDevisCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Devis/Brouillon`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name ReprendreRefuseDevisDetail
     * @request GET:/Dossier/{dossier}/Devis/ReprendreRefuse
     * @deprecated
     * @secure
     */
    reprendreRefuseDevisDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Devis, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Devis/ReprendreRefuse`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name EditerDevisCreate
     * @summary Génération du devis définitif (en double)
     * @request POST:/Dossier/{dossier}/Devis/Editer
     * @secure
     */
    editerDevisCreate: (
      { dossier, ...query }: EditerDevisCreateParams,
      data: EditerDevisCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Devis/Editer`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name ImportDevisCreate
     * @summary Importe le devis en PDF et crée un devis sans lignes en brouillon
     * @request POST:/Dossier/{dossier}/Devis/Import
     * @secure
     */
    importDevisCreate: (dossier: string, data: ImportDevisCreatePayload, params: RequestParams = {}) =>
      this.request<Devis, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Devis/Import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name ChangerStatutDevisPartialUpdate
     * @summary Validation d'un devis
     * @request PATCH:/Dossier/{dossier}/Devis/ChangerStatut
     * @secure
     */
    changerStatutDevisPartialUpdate: (
      dossier: string,
      data: ChangerStatutDevisPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Devis/ChangerStatut`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Devis
     * @name DevisDossierDetail
     * @summary Récuperer les devis d'un dossier
     * @request GET:/Dossier/{dossier}/Devis
     * @secure
     */
    devisDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<DevisAvecPiecesJointes[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Devis`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name MediathequeDossierDetail
     * @request GET:/Dossier/{dossier}/Mediatheque
     * @secure
     */
    mediathequeDossierDetail: ({ dossier, ...query }: MediathequeDossierDetailParams, params: RequestParams = {}) =>
      this.request<ElementMediatheque[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Mediatheque`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name MediathequeDossierCreate
     * @request POST:/Dossier/{dossier}/Mediatheque
     * @secure
     */
    mediathequeDossierCreate: (dossier: string, data: MediathequeDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Mediatheque`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DocumentDossierDetail
     * @request GET:/Dossier/{dossier}/Document
     * @secure
     */
    documentDossierDetail: ({ dossier, ...query }: DocumentDossierDetailParams, params: RequestParams = {}) =>
      this.request<Document[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Document`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name DocumentDossierCreate
     * @request POST:/Dossier/{dossier}/Document
     * @secure
     */
    documentDossierCreate: (dossier: string, data: DocumentDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Document`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name DossierDetail
     * @request GET:/Dossier/{id}
     * @secure
     */
    dossierDetail: (id: string, params: RequestParams = {}) =>
      this.request<Dossier, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name GetSyntheseDossierDossierDetail
     * @summary Récupère les informations de synthèse d'un dossier
     * @request GET:/Dossier/{dossier}/GetSyntheseDossier
     * @deprecated
     * @secure
     */
    getSyntheseDossierDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<SyntheseDossier, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/GetSyntheseDossier`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name HistoriqueDossierDetail
     * @summary Récupère l'historique du dossier
     * @request GET:/Dossier/{dossier}/Historique
     * @secure
     */
    historiqueDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<SuiviSimplifie[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Historique`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name HistoriqueCompletDossierDetail
     * @summary Récupère l'historique du dossier
     * @request GET:/Dossier/{dossier}/HistoriqueComplet
     * @secure
     */
    historiqueCompletDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<SuiviSimplifie[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/HistoriqueComplet`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name HasNotationAssureDossierDetail
     * @request GET:/Dossier/{dossier}/HasNotationAssure
     * @secure
     */
    hasNotationAssureDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<boolean, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/HasNotationAssure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name NotationAssureDossierCreate
     * @request POST:/Dossier/{dossier}/NotationAssure
     * @secure
     */
    notationAssureDossierCreate: (
      { dossier, ...query }: NotationAssureDossierCreateParams,
      data: NotationAssureDossierCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/NotationAssure`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name FranchiseDossierDetail
     * @summary Retourne la franchise d'un dossier
     * @request GET:/Dossier/{dossier}/Franchise
     * @secure
     */
    franchiseDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Franchise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name RseDossierDetail
     * @summary Retourne le taux RSE d'un dossier
     * @request GET:/Dossier/{dossier}/RSE
     * @secure
     */
    rseDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RSE`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name RemiseDossierDetail
     * @summary Retourne le taux de remise d'un dossier
     * @request GET:/Dossier/{dossier}/Remise
     * @secure
     */
    remiseDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Remise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name DossierCreate
     * @summary Missionnement par une plateforme
     * @request POST:/Dossier
     * @secure
     */
    dossierCreate: (data: DossierCreatePayload, params: RequestParams = {}) =>
      this.request<RefDossier, ValidationProblemDetails>({
        path: `/Dossier`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name SinistreDossierDetail
     * @summary Récupère le sinistre d'un dossier
     * @request GET:/Dossier/{dossier}/Sinistre
     * @secure
     */
    sinistreDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Sinistre, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Sinistre`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name SinistreDossierPartialUpdate
     * @request PATCH:/Dossier/{dossier}/Sinistre
     * @secure
     */
    sinistreDossierPartialUpdate: (
      dossier: string,
      data: SinistreDossierPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Sinistre`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dossier
     * @name ChangesetDossierList
     * @summary Récupère les dossiers qui ont changé depuis une date donnée
     * @request GET:/Dossier/Changeset
     * @secure
     */
    changesetDossierList: (query: ChangesetDossierListParams, params: RequestParams = {}) =>
      this.request<ChangeSet[], ValidationProblemDetails>({
        path: `/Dossier/Changeset`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name NouveauEchangeInterneCreate
     * @summary Création d'un nouvel échange interne
     * @request POST:/Dossier/{dossier}/EchangeInterne/Nouveau
     * @secure
     */
    nouveauEchangeInterneCreate: (
      dossier: string,
      data: NouveauEchangeInterneCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/EchangeInterne/Nouveau`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name DestinatairesEchangeInterneDetail
     * @summary Permet de récupérer la liste des destinataires d'échanges internes pour un dossier
     * @request GET:/Dossier/{dossier}/EchangeInterne/Destinataires
     * @secure
     */
    destinatairesEchangeInterneDetail: (
      { dossier, ...query }: DestinatairesEchangeInterneDetailParams,
      params: RequestParams = {},
    ) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/EchangeInterne/Destinataires`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name PiedFactureCreate
     * @summary Calcule le pied d'une facture par rapport aux lignes saisies
     * @request POST:/Dossier/{dossier}/Facture/Pied
     * @secure
     */
    piedFactureCreate: (dossier: string, data: PiedFactureCreatePayload, params: RequestParams = {}) =>
      this.request<PiedFacture, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Facture/Pied`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name BrouillonFactureDetail
     * @summary Charge la facture brouillon de l'entreprise
     * @request GET:/Dossier/{dossier}/Facture/Brouillon
     * @secure
     */
    brouillonFactureDetail: ({ dossier, ...query }: BrouillonFactureDetailParams, params: RequestParams = {}) =>
      this.request<FactureAPI, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Facture/Brouillon`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name BrouillonFactureCreate
     * @summary Création/Modification d'une nouvelle facture en brouillon
     * @request POST:/Dossier/{dossier}/Facture/Brouillon
     * @secure
     */
    brouillonFactureCreate: (dossier: string, data: BrouillonFactureCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Facture/Brouillon`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name EditerFactureCreate
     * @summary Génération de la facture définitive (en double)
     * @request POST:/Dossier/{dossier}/Facture/Editer
     * @secure
     */
    editerFactureCreate: (
      { dossier, ...query }: EditerFactureCreateParams,
      data: EditerFactureCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Facture/Editer`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name ImportFactureCreate
     * @summary Importe la facture en PDF et crée une facture sans lignes en brouillon
     * @request POST:/Dossier/{dossier}/Facture/Import
     * @secure
     */
    importFactureCreate: (dossier: string, data: ImportFactureCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Facture/Import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name GenererRetrocommissionFactureCreate
     * @summary Génération d'une facture de rétro commission
     * @request POST:/Dossier/Facture/{facture}/GenererRetrocommission
     * @secure
     */
    genererRetrocommissionFactureCreate: (facture: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/Facture/${facture}/GenererRetrocommission`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name ChangerStatutFacturePartialUpdate
     * @summary Changer Statut d'une facture par l'assureur
     * @request PATCH:/Dossier/Facture/{facture}/ChangerStatut
     * @secure
     */
    changerStatutFacturePartialUpdate: (
      facture: string,
      data: ChangerStatutFacturePartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/Facture/${facture}/ChangerStatut`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Facture
     * @name FacturesDossierDetail
     * @request GET:/Dossier/{dossier}/Factures
     * @secure
     */
    facturesDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<FactureItem[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Factures`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionDossierDetail
     * @request GET:/Dossier/{dossier}/Intervention
     * @secure
     */
    interventionDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Intervention[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Intervention`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionDossierCreate
     * @request POST:/Dossier/{dossier}/Intervention
     * @secure
     */
    interventionDossierCreate: (dossier: string, data: InterventionDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Intervention`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name DestinatairesMailDetail
     * @summary Récupère la liste des destinataires possible pour un dossier
     * @request GET:/Dossier/{dossier}/Mail/Destinataires
     * @secure
     */
    destinatairesMailDetail: ({ dossier, ...query }: DestinatairesMailDetailParams, params: RequestParams = {}) =>
      this.request<MailDestinataire[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Mail/Destinataires`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name EmetteursMailDetail
     * @request GET:/Dossier/{dossier}/Mail/Emetteurs
     * @secure
     */
    emetteursMailDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<MailDestinataire[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Mail/Emetteurs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name MailTypesMailDetail
     * @summary Récupère la liste des mails types
     * @request GET:/Dossier/{dossier}/Mail/MailTypes
     * @secure
     */
    mailTypesMailDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Mail/MailTypes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name MailDossierDetail
     * @request GET:/Dossier/{dossier}/Mail
     * @secure
     */
    mailDossierDetail: ({ dossier, ...query }: MailDossierDetailParams, params: RequestParams = {}) =>
      this.request<Mail[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Mail`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Mail
     * @name MailDossierCreate
     * @summary Création d'un nouveau mail (Simple pour l'instant)
     * @request POST:/Dossier/{dossier}/Mail
     * @secure
     */
    mailDossierCreate: (dossier: string, data: MailDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Mail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messagerie
     * @name MessagesDossierDetail
     * @summary Retourne tous les messages dont l'assuré est émetteur ou destinataire classés chronologiquement
     * @request GET:/Dossier/{dossier}/Messages
     * @secure
     */
    messagesDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Message[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Messages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messagerie
     * @name MessagesDossierCreate
     * @summary Création d'un nouvel échange interne
     * @request POST:/Dossier/{dossier}/Messages
     * @secure
     */
    messagesDossierCreate: (dossier: string, data: MessagesDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Messages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Messagerie
     * @name DestinatairesMessagesDetail
     * @summary Permet de récupérer la liste des destinataires des messages pour un dossier
     * @request GET:/Dossier/{dossier}/Messages/Destinataires
     * @secure
     */
    destinatairesMessagesDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Messages/Destinataires`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Metre
     * @name MetreDossierDetail
     * @summary Récupérer une liste de tous les métrés d'un dossier
     * @request GET:/Dossier/{refDossier}/Metre
     * @secure
     */
    metreDossierDetail: (refDossier: string, params: RequestParams = {}) =>
      this.request<MetreDossier[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${refDossier}/Metre`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Missionnement
     * @name MissionnementDossierDetail
     * @summary Récupère le missionnement actif d'un dossier
     * @request GET:/Dossier/{id}/Missionnement
     * @secure
     */
    missionnementDossierDetail: (id: string, params: RequestParams = {}) =>
      this.request<Missionnement, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${id}/Missionnement`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Missionnement
     * @name MissionnementDossierCreate
     * @summary Missionne un établissement sur un dossier
     * @request POST:/Dossier/{id}/Missionnement
     * @secure
     */
    missionnementDossierCreate: (id: string, data: MissionnementDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${id}/Missionnement`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Missionnement
     * @name EtatMissionnementPartialUpdate
     * @summary Modifie l'état d'un missionnement en cours
     * @request PATCH:/Dossier/{id}/Missionnement/Etat
     * @secure
     */
    etatMissionnementPartialUpdate: (
      id: string,
      data: EtatMissionnementPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ForbidResult | ValidationProblemDetails>({
        path: `/Dossier/${id}/Missionnement/Etat`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Missionnement
     * @name MissionDossierDetail
     * @request GET:/Dossier/{dossier}/Mission
     * @secure
     */
    missionDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Mission, ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Mission`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Missionnement
     * @name MissionDossierPartialUpdate
     * @request PATCH:/Dossier/{dossier}/Mission
     * @secure
     */
    missionDossierPartialUpdate: (
      dossier: string,
      data: MissionDossierPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Mission`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name AssureDossierDetail
     * @request GET:/Dossier/{dossier}/Assure
     * @secure
     */
    assureDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Personne, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Assure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name AssureDossierCreate
     * @request POST:/Dossier/{dossier}/Assure
     * @secure
     */
    assureDossierCreate: (dossier: string, data: AssureDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Assure`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name CoordonneesAssurePartialUpdate
     * @request PATCH:/Dossier/{dossier}/Assure/Coordonnees
     * @secure
     */
    coordonneesAssurePartialUpdate: (
      dossier: string,
      data: CoordonneesAssurePartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Assure/Coordonnees`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name IntervenantsDossierDetail
     * @summary Liste de tous les intervenants dans un dossier
     * @request GET:/Dossier/{dossier}/Intervenants
     * @secure
     */
    intervenantsDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Personne[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Intervenants`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name IntervenantDossierDetail
     * @request GET:/Dossier/{dossier}/Intervenant/{Id}
     * @secure
     */
    intervenantDossierDetail: (dossier: string, id: string, params: RequestParams = {}) =>
      this.request<Personne, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Intervenant/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name IntervenantDossierDelete
     * @request DELETE:/Dossier/{dossier}/Intervenant/{Id}
     * @secure
     */
    intervenantDossierDelete: (dossier: string, id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Intervenant/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name IntervenantDossierCreate
     * @summary Créer un intervenant
     * @request POST:/Dossier/{dossier}/Intervenant
     * @secure
     */
    intervenantDossierCreate: (dossier: string, data: IntervenantDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Intervenant`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name IntervenantDossierPartialUpdate
     * @summary Màj un intervenant
     * @request PATCH:/Dossier/{dossier}/Intervenant/{id}
     * @secure
     */
    intervenantDossierPartialUpdate: (
      dossier: string,
      id: string,
      data: IntervenantDossierPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Intervenant/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name MandantsDossierDetail
     * @summary Liste de tous les mandants d'un dossier
     * @request GET:/Dossier/{dossier}/Mandants
     * @secure
     */
    mandantsDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<Mandant[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Mandants`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Personne
     * @name MandantDossierCreate
     * @summary Màj un mandant d'un dossier
     * @request POST:/Dossier/{dossier}/Mandant
     * @secure
     */
    mandantDossierCreate: (dossier: string, data: MandantDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/Mandant`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recherche
     * @name RechercheDossierList
     * @request GET:/Dossier/Recherche
     * @secure
     */
    rechercheDossierList: (query: RechercheDossierListParams, params: RequestParams = {}) =>
      this.request<ResultatRechercheDossier, ValidationProblemDetails>({
        path: `/Dossier/Recherche`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recherche
     * @name RechercheParReferenceDossierDetail
     * @request GET:/Dossier/RechercheParReference/{codefamille}/{reference}
     * @secure
     */
    rechercheParReferenceDossierDetail: (codefamille: string, reference: string, params: RequestParams = {}) =>
      this.request<Dossier, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/RechercheParReference/${codefamille}/${reference}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name PriseDeContactDossierDetail
     * @request GET:/Dossier/{dossier}/PriseDeContact
     * @secure
     */
    priseDeContactDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<PriseDeContact[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/PriseDeContact`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name PriseDeContactDossierCreate
     * @request POST:/Dossier/{dossier}/PriseDeContact
     * @secure
     */
    priseDeContactDossierCreate: (
      dossier: string,
      data: PriseDeContactDossierCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<any, ValidationProblemDetails>({
        path: `/Dossier/${dossier}/PriseDeContact`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name ProchainRendezVousMetreurRendezVousDetail
     * @request GET:/Dossier/{dossier}/RendezVous/ProchainRendezVousMetreur
     * @secure
     */
    prochainRendezVousMetreurRendezVousDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<RendezVousMetreur, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RendezVous/ProchainRendezVousMetreur`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name MetreurRendezVousDetail
     * @request GET:/Dossier/{dossier}/RendezVous/Metreur
     * @secure
     */
    metreurRendezVousDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<RendezVousMetreur[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RendezVous/Metreur`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name MetreurRendezVousCreate
     * @request POST:/Dossier/{dossier}/RendezVous/Metreur
     * @secure
     */
    metreurRendezVousCreate: (dossier: string, data: MetreurRendezVousCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RendezVous/Metreur`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name TravauxRendezVousDetail
     * @request GET:/Dossier/{dossier}/RendezVous/Travaux
     * @secure
     */
    travauxRendezVousDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<RendezVousTravaux[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RendezVous/Travaux`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name TravauxRendezVousCreate
     * @request POST:/Dossier/{dossier}/RendezVous/Travaux
     * @secure
     */
    travauxRendezVousCreate: (dossier: string, data: TravauxRendezVousCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/RendezVous/Travaux`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name ListeSinappsDossierDetail
     * @summary Récupère une liste de clé valeur de sinapps
     * @request GET:/Dossier/{dossier}/ListeSinapps/{typeListe}
     * @secure
     */
    listeSinappsDossierDetail: (dossier: string, typeListe: EnumerationsMetier, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/Dossier/${dossier}/ListeSinapps/${typeListe}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowDossierDetail
     * @summary Récupère le Workflow du dossier
     * @request GET:/Dossier/{dossier}/Workflow
     * @secure
     */
    workflowDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<JalonSimplifie[], ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/Workflow`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowEntrepriseDossierDetail
     * @summary Récupère le Workflow Entreprise du dossier
     * @request GET:/Dossier/{dossier}/WorkflowEntreprise
     * @secure
     */
    workflowEntrepriseDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<WorkflowEntreprise, ValidationProblemDetails | ProblemDetails>({
        path: `/Dossier/${dossier}/WorkflowEntreprise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  anomalieTypes = {
    /**
     * No description
     *
     * @tags AnomalieType
     * @name AnomalieTypesList
     * @request GET:/AnomalieTypes
     * @secure
     */
    anomalieTypesList: (params: RequestParams = {}) =>
      this.request<AnomalieType[], ValidationProblemDetails>({
        path: `/AnomalieTypes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  anomalieType = {
    /**
     * No description
     *
     * @tags AnomalieType
     * @name AnomalieTypeDetail
     * @request GET:/AnomalieType/{id}
     * @secure
     */
    anomalieTypeDetail: (id: number, params: RequestParams = {}) =>
      this.request<AnomalieType, ValidationProblemDetails>({
        path: `/AnomalieType/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnomalieType
     * @name AnomalieTypeUpdate
     * @request PUT:/AnomalieType/{id}
     * @secure
     */
    anomalieTypeUpdate: (id: number, data: AnomalieTypeUpdatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/AnomalieType/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnomalieType
     * @name AnomalieTypeDelete
     * @request DELETE:/AnomalieType/{id}
     * @secure
     */
    anomalieTypeDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/AnomalieType/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AnomalieType
     * @name AnomalieTypeCreate
     * @request POST:/AnomalieType
     * @secure
     */
    anomalieTypeCreate: (data: AnomalieTypeCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/AnomalieType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  assureurs = {
    /**
     * No description
     *
     * @tags Assureur
     * @name AssureursList
     * @summary Liste des assureurs qui sont autorisés à se connecter à l'extranet
     * @request GET:/Assureurs
     * @secure
     */
    assureursList: (params: RequestParams = {}) =>
      this.request<AnnuaireResume[], ValidationProblemDetails | ProblemDetails>({
        path: `/Assureurs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  loginsAssureurs = {
    /**
     * No description
     *
     * @tags Assureur
     * @name LoginsAssureursList
     * @summary Retourne la liste des personnes paramétrées pour se connecter à l'extranet assureur
     * @request GET:/LoginsAssureurs
     * @secure
     */
    loginsAssureursList: (params: RequestParams = {}) =>
      this.request<AnnuaireLoginExtranetAssureurResume[], ValidationProblemDetails | ProblemDetails>({
        path: `/LoginsAssureurs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  loginAssureur = {
    /**
     * No description
     *
     * @tags Assureur
     * @name LoginAssureurCreate
     * @summary Création/Modification d'un login extranet Assureur
     * @request POST:/LoginAssureur
     * @secure
     */
    loginAssureurCreate: (data: LoginAssureurCreatePayload, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/LoginAssureur`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  supprimerLoginAssureur = {
    /**
     * No description
     *
     * @tags Assureur
     * @name SupprimerLoginAssureurDelete
     * @summary Suppression d'une période de congès
     * @request DELETE:/SupprimerLoginAssureur/{idLogin}
     * @secure
     */
    supprimerLoginAssureurDelete: (idLogin: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/SupprimerLoginAssureur/${idLogin}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  authentification = {
    /**
     * No description
     *
     * @tags Authentification
     * @name LoginAuthentificationCreate
     * @request POST:/Authentification/Login
     * @secure
     */
    loginAuthentificationCreate: (data: LoginAuthentificationCreatePayload, params: RequestParams = {}) =>
      this.request<LoginResponse, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/Login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name RegisterAuthentificationCreate
     * @request POST:/Authentification/Register
     * @secure
     */
    registerAuthentificationCreate: (data: RegisterAuthentificationCreatePayload, params: RequestParams = {}) =>
      this.request<LoginResponse, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/Register`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginExtranetAssureAuthentificationCreate
     * @summary Authentification à l'API en tant qu'utilisateur extranet assuré
     * @request POST:/Authentification/LoginExtranetAssure
     * @secure
     */
    loginExtranetAssureAuthentificationCreate: (
      query: LoginExtranetAssureAuthentificationCreateParams,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponseDossier, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginExtranetAssure`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginExtranetAssureurAuthentificationCreate
     * @summary Authentification à l'API en tant qu'utilisateur extranet assureur
     * @request POST:/Authentification/LoginExtranetAssureur
     * @secure
     */
    loginExtranetAssureurAuthentificationCreate: (
      data: LoginExtranetAssureurAuthentificationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponse, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginExtranetAssureur`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginExtranetEntrepriseAuthentificationCreate
     * @summary Authentification à l'API en tant qu'utilisateur extranet entreprise
     * @request POST:/Authentification/LoginExtranetEntreprise
     * @secure
     */
    loginExtranetEntrepriseAuthentificationCreate: (
      data: LoginExtranetEntrepriseAuthentificationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponse, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginExtranetEntreprise`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name RegisterExtranetEntrepriseAuthentificationCreate
     * @summary Creation d'un utilisateur extranet entreprise
     * @request POST:/Authentification/RegisterExtranetEntreprise
     * @secure
     */
    registerExtranetEntrepriseAuthentificationCreate: (
      data: RegisterExtranetEntrepriseAuthentificationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<ResetPasswordEntrepriseResponse, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/RegisterExtranetEntreprise`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name ResetPasswordExtranetEntrepriseAuthentificationCreate
     * @summary Reset de mot de passe extranet entreprise
     * @request POST:/Authentification/ResetPasswordExtranetEntreprise
     * @secure
     */
    resetPasswordExtranetEntrepriseAuthentificationCreate: (
      data: ResetPasswordExtranetEntrepriseAuthentificationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/ResetPasswordExtranetEntreprise`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name AskForPasswordResetExtranetEntrepriseAuthentificationCreate
     * @summary Demande de reset de mot de passe extranet entreprise
     * @request POST:/Authentification/AskForPasswordResetExtranetEntreprise
     * @secure
     */
    askForPasswordResetExtranetEntrepriseAuthentificationCreate: (
      data: AskForPasswordResetExtranetEntrepriseAuthentificationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Authentification/AskForPasswordResetExtranetEntreprise`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginIrenAuthentificationCreate
     * @summary Authentification à l'API en tant qu'utilisateur IREN
     * @request POST:/Authentification/LoginIren
     * @secure
     */
    loginIrenAuthentificationCreate: (data: LoginIrenAuthentificationCreatePayload, params: RequestParams = {}) =>
      this.request<LoginResponse, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginIren`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginIrenVerrueAuthentificationCreate
     * @summary Authentification à l'API en tant qu'utilisateur IREN
     * @request POST:/Authentification/LoginIrenVerrue
     * @secure
     */
    loginIrenVerrueAuthentificationCreate: (
      query: LoginIrenVerrueAuthentificationCreateParams,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponse, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginIrenVerrue`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginIrenMobileAuthentificationCreate
     * @summary Authentification à l'API en tant qu'utilisateur IREN Mobile
     * @request POST:/Authentification/LoginIrenMobile
     * @secure
     */
    loginIrenMobileAuthentificationCreate: (
      data: LoginIrenMobileAuthentificationCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponse, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginIrenMobile`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentification
     * @name LoginIrenExtranetAuthentificationCreate
     * @summary Login depuis IREN à l'extranet entreprise ou assureur
     * @request POST:/Authentification/LoginIrenExtranet
     * @secure
     */
    loginIrenExtranetAuthentificationCreate: (
      query: LoginIrenExtranetAuthentificationCreateParams,
      params: RequestParams = {},
    ) =>
      this.request<LoginResponseDossier, ValidationProblemDetails | ProblemDetails>({
        path: `/Authentification/LoginIrenExtranet`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  cache = {
    /**
     * No description
     *
     * @tags Cache
     * @name CacheList
     * @request GET:/Cache
     * @secure
     */
    cacheList: (params: RequestParams = {}) =>
      this.request<CacheInfo, ValidationProblemDetails>({
        path: `/Cache`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cache
     * @name ReinitialiserCacheCreate
     * @request POST:/Cache/Reinitialiser
     * @secure
     */
    reinitialiserCacheCreate: (params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Cache/Reinitialiser`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cache
     * @name ReinitialiserCacheCreate2
     * @request POST:/Cache/Reinitialiser/{database}
     * @originalName reinitialiserCacheCreate
     * @duplicate
     * @secure
     */
    reinitialiserCacheCreate2: (database: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Cache/Reinitialiser/${database}`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cache
     * @name ReinitialiserCacheCreate3
     * @request POST:/Cache/Reinitialiser/{database}/{key}
     * @originalName reinitialiserCacheCreate
     * @duplicate
     * @secure
     */
    reinitialiserCacheCreate3: (database: string, key: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Cache/Reinitialiser/${database}/${key}`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  dashboard = {
    /**
     * No description
     *
     * @tags Dashboard
     * @name FacturesDashboardList
     * @request GET:/Dashboard/Factures
     * @secure
     */
    facturesDashboardList: (query: FacturesDashboardListParams, params: RequestParams = {}) =>
      this.request<ResultatDashboardFacture, ValidationProblemDetails>({
        path: `/Dashboard/Factures`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name DevisDashboardList
     * @request GET:/Dashboard/Devis
     * @secure
     */
    devisDashboardList: (query: DevisDashboardListParams, params: RequestParams = {}) =>
      this.request<ResultatDashboardFacture, ValidationProblemDetails>({
        path: `/Dashboard/Devis`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dashboard
     * @name CommandesDashboardList
     * @request GET:/Dashboard/Commandes
     * @secure
     */
    commandesDashboardList: (query: CommandesDashboardListParams, params: RequestParams = {}) =>
      this.request<ResultatDashboardCommande, ValidationProblemDetails>({
        path: `/Dashboard/Commandes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  mediatheque = {
    /**
     * No description
     *
     * @tags Document
     * @name MediathequeDelete
     * @request DELETE:/Mediatheque/{id}
     * @secure
     */
    mediathequeDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Mediatheque/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Document
     * @name CategorieMediathequeList
     * @request GET:/Mediatheque/Categorie
     * @secure
     */
    categorieMediathequeList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/Mediatheque/Categorie`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  document = {
    /**
     * No description
     *
     * @tags Document
     * @name DocumentDelete
     * @request DELETE:/Document/{id}
     * @secure
     */
    documentDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Document/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  dossierIren = {
    /**
     * No description
     *
     * @tags Dossier
     * @name DossierIrenCreate
     * @request POST:/DossierIren
     * @secure
     */
    dossierIrenCreate: (data: DossierIrenCreatePayload, params: RequestParams = {}) =>
      this.request<RefDossier, ValidationProblemDetails | ProblemDetails>({
        path: `/DossierIren`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  echangeInterne = {
    /**
     * No description
     *
     * @tags EchangeInterne
     * @name EchangeInterneList
     * @summary Récupérer une liste filtrée de tous les échanges.
     * @request GET:/EchangeInterne
     * @secure
     */
    echangeInterneList: (query: EchangeInterneListParams, params: RequestParams = {}) =>
      this.request<EchangeInterne[], ValidationProblemDetails>({
        path: `/EchangeInterne`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name RepondreEchangeInterneCreate
     * @summary Permet de répondre à un échange interne
     * @request POST:/EchangeInterne/Repondre/{id}
     * @secure
     */
    repondreEchangeInterneCreate: (id: string, data: RepondreEchangeInterneCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/Repondre/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name TransfertEchangeInterneCreate
     * @summary Permet de transférer un échange interne vers une ou plusieurs autres personnes
     * @request POST:/EchangeInterne/Transfert/{id}
     * @secure
     */
    transfertEchangeInterneCreate: (
      id: string,
      data: TransfertEchangeInterneCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/Transfert/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name MarquerCommeLuEchangeInterneUpdate
     * @summary Marquer un message comme lu
     * @request PUT:/EchangeInterne/MarquerCommeLu/{id}
     * @secure
     */
    marquerCommeLuEchangeInterneUpdate: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/MarquerCommeLu/${id}`,
        method: "PUT",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EchangeInterne
     * @name MarquerCommeNonLuEchangeInterneUpdate
     * @summary Marquer un message comme non lu
     * @request PUT:/EchangeInterne/MarquerCommeNonLu/{id}
     * @secure
     */
    marquerCommeNonLuEchangeInterneUpdate: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EchangeInterne/MarquerCommeNonLu/${id}`,
        method: "PUT",
        secure: true,
        ...params,
      }),
  };
  entreprisePartenaires = {
    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EntreprisePartenairesList
     * @summary Retourne la liste des entreprise partenaire
     * @request GET:/EntreprisePartenaires
     * @secure
     */
    entreprisePartenairesList: (params: RequestParams = {}) =>
      this.request<EntreprisePartenaire[], ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaires`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  entreprisePartenaire = {
    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EntreprisePartenaireDetail
     * @summary Retourne un objet entreprise partenaire complet
     * @request GET:/EntreprisePartenaire/{id}
     * @secure
     */
    entreprisePartenaireDetail: (id: number, params: RequestParams = {}) =>
      this.request<EntreprisePartenaire, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EntreprisePartenaireUpdate
     * @summary Mettre à jour une entreprise Partenaire
     * @request PUT:/EntreprisePartenaire/{id}
     * @secure
     */
    entreprisePartenaireUpdate: (id: number, data: EntreprisePartenaireUpdatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EntreprisePartenaireCreate
     * @summary Ajouter une nouvelle entreprise Partenaire
     * @request POST:/EntreprisePartenaire
     * @secure
     */
    entreprisePartenaireCreate: (data: EntreprisePartenaireCreatePayload, params: RequestParams = {}) =>
      this.request<number, ValidationProblemDetails>({
        path: `/EntreprisePartenaire`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name DocumentEntreprisePartenaireCreate
     * @summary Ajouter un document juridique à une entreprise Partenaire
     * @request POST:/EntreprisePartenaire/{idEntreprise}/Document
     * @secure
     */
    documentEntreprisePartenaireCreate: (
      idEntreprise: number,
      data: DocumentEntreprisePartenaireCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/${idEntreprise}/Document`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name DocumentEntreprisePartenaireUpdate
     * @summary Mettre à jour un document juridique d'une entreprise Partenaire
     * @request PUT:/EntreprisePartenaire/Document/{idDocument}
     * @secure
     */
    documentEntreprisePartenaireUpdate: (
      idDocument: number,
      data: DocumentEntreprisePartenaireUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/Document/${idDocument}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name DocumentEntreprisePartenaireDelete
     * @summary Suppression d'un document juridique d'une entreprise Partenaire
     * @request DELETE:/EntreprisePartenaire/Document/{idDocument}
     * @secure
     */
    documentEntreprisePartenaireDelete: (idDocument: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/Document/${idDocument}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EtablissementEntreprisePartenaireCreate
     * @summary Ajoute un établissement à une entreprise Partenaire
     * @request POST:/EntreprisePartenaire/{idEntreprise}/Etablissement
     * @secure
     */
    etablissementEntreprisePartenaireCreate: (
      idEntreprise: number,
      data: EtablissementEntreprisePartenaireCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/${idEntreprise}/Etablissement`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name EtablissementEntreprisePartenaireUpdate
     * @summary Mettre à jour un etablissement d'une entreprise Partenaire
     * @request PUT:/EntreprisePartenaire/Etablissement/{idEtablissement}
     * @secure
     */
    etablissementEntreprisePartenaireUpdate: (
      idEtablissement: number,
      data: EtablissementEntreprisePartenaireUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/EntreprisePartenaire/Etablissement/${idEtablissement}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  etablissement = {
    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name CongeEtablissementCreate
     * @summary Ajoute un congé à un etblissement
     * @request POST:/Etablissement/{idEtablissement}/Conge
     * @secure
     */
    congeEtablissementCreate: (
      idEtablissement: number,
      data: CongeEtablissementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/Conge`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name CongeEtablissementUpdate
     * @summary Mettre à jour un conge d'un etablissement
     * @request PUT:/Etablissement/Conge/{idConge}
     * @secure
     */
    congeEtablissementUpdate: (idConge: number, data: CongeEtablissementUpdatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/Conge/${idConge}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name CongeEtablissementDelete
     * @summary Suppression d'une période de congès
     * @request DELETE:/Etablissement/Conge/{idConge}
     * @secure
     */
    congeEtablissementDelete: (idConge: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/Conge/${idConge}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name HoraireEtablissementCreate
     * @summary Ajoute un horaire à un établissement
     * @request POST:/Etablissement/{idEtablissement}/Horaire
     * @secure
     */
    horaireEtablissementCreate: (
      idEtablissement: number,
      data: HoraireEtablissementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/Horaire`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name HoraireEtablissementUpdate
     * @summary Mettre à jour un horaire d'un etablissement
     * @request PUT:/Etablissement/{idEtablissement}/Horaire/{idHoraire}
     * @secure
     */
    horaireEtablissementUpdate: (
      idEtablissement: number,
      idHoraire: number,
      data: HoraireEtablissementUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/Horaire/${idHoraire}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name ContactEtablissementCreate
     * @summary Ajoute un contact à un etblissement de l'entreprise partenaire
     * @request POST:/Etablissement/{idEtablissement}/Contact
     * @secure
     */
    contactEtablissementCreate: (
      idEtablissement: number,
      data: ContactEtablissementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<number, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/Contact`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name ContactEtablissementUpdate
     * @summary Mettre à jour un contact entreprise d'un etablissement
     * @request PUT:/Etablissement/Contact/{idContact}
     * @secure
     */
    contactEtablissementUpdate: (
      idContact: number,
      data: ContactEtablissementUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/Contact/${idContact}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name ContactEtablissementDelete
     * @summary Suppression d'un document juridique d'une entreprise Partenaire
     * @request DELETE:/Etablissement/Contact/{idContact}
     * @secure
     */
    contactEtablissementDelete: (idContact: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/Contact/${idContact}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name MetiersEtablissementUpdate
     * @summary Mettre à jour la liste des corps de metier de l'établissement
     * @request PUT:/Etablissement/{idEtablissement}/Metiers
     * @secure
     */
    metiersEtablissementUpdate: (
      idEtablissement: number,
      data: MetiersEtablissementUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/Metiers`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags EntreprisePartenaire
     * @name ZoneDeCouvertureEtablissementUpdate
     * @summary Mettre à jour la liste des zones de couverture de l'établissement
     * @request PUT:/Etablissement/{idEtablissement}/ZoneDeCouverture
     * @secure
     */
    zoneDeCouvertureEtablissementUpdate: (
      idEtablissement: number,
      data: ZoneDeCouvertureEtablissementUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Etablissement/${idEtablissement}/ZoneDeCouverture`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  extranetEntrepriseUser = {
    /**
     * No description
     *
     * @tags ExtranetEntrepriseUser
     * @name RegisterAllExtranetEntrepriseExtranetEntrepriseUserCreate
     * @summary Creation d'un utilisateur pour tous les établissements
     * @request POST:/ExtranetEntrepriseUser/RegisterAllExtranetEntreprise
     * @secure
     */
    registerAllExtranetEntrepriseExtranetEntrepriseUserCreate: (params: RequestParams = {}) =>
      this.request<ResetPasswordEntrepriseResponse[], ProblemDetails | ValidationProblemDetails>({
        path: `/ExtranetEntrepriseUser/RegisterAllExtranetEntreprise`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExtranetEntrepriseUser
     * @name RegisterOneExtranetEntrepriseExtranetEntrepriseUserCreate
     * @summary Creation d'un utilisateur pour tous les établissements d'une entreprise
     * @request POST:/ExtranetEntrepriseUser/RegisterOneExtranetEntreprise/{id}
     * @secure
     */
    registerOneExtranetEntrepriseExtranetEntrepriseUserCreate: (id: number, params: RequestParams = {}) =>
      this.request<ResetPasswordEntrepriseResponse[], ProblemDetails | ValidationProblemDetails>({
        path: `/ExtranetEntrepriseUser/RegisterOneExtranetEntreprise/${id}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ExtranetEntrepriseUser
     * @name ExtranetEntrepriseUserList
     * @request GET:/ExtranetEntrepriseUser
     * @secure
     */
    extranetEntrepriseUserList: (params: RequestParams = {}) =>
      this.request<ExtranetEntrepriseUser[], ValidationProblemDetails>({
        path: `/ExtranetEntrepriseUser`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  intervention = {
    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionUpdate
     * @request PUT:/Intervention/{id}
     * @secure
     */
    interventionUpdate: (id: number, data: InterventionUpdatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/Intervention/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name InterventionDelete
     * @request DELETE:/Intervention/{id}
     * @secure
     */
    interventionDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Intervention/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name MotifInterventionList
     * @request GET:/Intervention/Motif
     * @secure
     */
    motifInterventionList: (params: RequestParams = {}) =>
      this.request<InterventionMotifNonReparation[], ValidationProblemDetails>({
        path: `/Intervention/Motif`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name ResultatInterventionInterventionList
     * @request GET:/Intervention/ResultatIntervention
     * @secure
     */
    resultatInterventionInterventionList: (params: RequestParams = {}) =>
      this.request<ResultatInterventionEnumLabel[], ValidationProblemDetails>({
        path: `/Intervention/ResultatIntervention`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Intervention
     * @name OrigineDemandeInterventionDiffereeInterventionList
     * @request GET:/Intervention/OrigineDemandeInterventionDifferee
     * @secure
     */
    origineDemandeInterventionDiffereeInterventionList: (params: RequestParams = {}) =>
      this.request<OrigineDemandeInterventionDiffereeEnumLabel[], ValidationProblemDetails>({
        path: `/Intervention/OrigineDemandeInterventionDifferee`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  logs = {
    /**
     * No description
     *
     * @tags Log
     * @name LogsList
     * @summary Récupère les dossiers selon un filtre
     * @request GET:/Logs
     * @secure
     */
    logsList: (query: LogsListParams, params: RequestParams = {}) =>
      this.request<File, ValidationProblemDetails | ProblemDetails>({
        path: `/Logs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  mail = {
    /**
     * No description
     *
     * @tags Mail
     * @name MailTypeMailDetail
     * @summary Récupère un mail type par son code
     * @request GET:/Mail/MailType/{code}
     * @secure
     */
    mailTypeMailDetail: (code: string, params: RequestParams = {}) =>
      this.request<MailType, ValidationProblemDetails | ProblemDetails>({
        path: `/Mail/MailType/${code}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  messages = {
    /**
     * No description
     *
     * @tags Messagerie
     * @name RepondreMessagesCreate
     * @summary Permet de répondre à un échange interne
     * @request POST:/Messages/{id}/Repondre
     * @secure
     */
    repondreMessagesCreate: (id: string, data: RepondreMessagesCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Messages/${id}/Repondre`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  metre = {
    /**
     * No description
     *
     * @tags Metre
     * @name MetreDetail
     * @summary Récupérer un métré par sa référence
     * @request GET:/Metre/{refMetre}
     * @secure
     */
    metreDetail: (refMetre: string, params: RequestParams = {}) =>
      this.request<Metre, ValidationProblemDetails | ProblemDetails>({
        path: `/Metre/${refMetre}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Metre
 * @name MetreCreate
 * @summary Cet endpoint permet d'ajouter un nouveau mètre (numéro de mètre à 0 exemple: 2019-LO-032658-0), 
d'ajouter une ressource (référence null), 
et de mettre à jour ou supprimer un mètre ou l'une de ses ressources en fournissant la référence.
 * @request POST:/Metre/{refMetre}
 * @secure
 */
    metreCreate: (refMetre: string, data: MetreCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Metre/${refMetre}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Metre
     * @name MetreDelete
     * @summary Supprimer un métré
     * @request DELETE:/Metre/{refMetre}
     * @secure
     */
    metreDelete: (refMetre: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/Metre/${refMetre}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  missionnementMotifsRefus = {
    /**
     * No description
     *
     * @tags Missionnement
     * @name MissionnementMotifsRefusList
     * @summary Récupérer la liste des motifs du refus de la mission.
     * @request GET:/MissionnementMotifsRefus
     * @secure
     */
    missionnementMotifsRefusList: (query: MissionnementMotifsRefusListParams, params: RequestParams = {}) =>
      this.request<MissionnementMotifRefus[], ValidationProblemDetails | ProblemDetails>({
        path: `/MissionnementMotifsRefus`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  parametrage = {
    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name EurogexParametrageDetail
     * @request GET:/Parametrage/Eurogex/{section}/{rubrique}
     * @secure
     */
    eurogexParametrageDetail: (section: string, rubrique: string, params: RequestParams = {}) =>
      this.request<Eurogex, ValidationProblemDetails | ProblemDetails>({
        path: `/Parametrage/Eurogex/${section}/${rubrique}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name EurogexParametrageList
     * @request GET:/Parametrage/Eurogex
     * @secure
     */
    eurogexParametrageList: (params: RequestParams = {}) =>
      this.request<Eurogex[], ValidationProblemDetails>({
        path: `/Parametrage/Eurogex`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  parametrageAnnuaire = {
    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name FormulesPolitesseParametrageAnnuaireList
     * @summary Liste des formules de politesse
     * @request GET:/ParametrageAnnuaire/FormulesPolitesse
     * @secure
     */
    formulesPolitesseParametrageAnnuaireList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/FormulesPolitesse`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name FormulePolitesseParametrageAnnuaireCreate
     * @summary Créer  / Màj une formule de politesse
     * @request POST:/ParametrageAnnuaire/FormulePolitesse
     * @secure
     */
    formulePolitesseParametrageAnnuaireCreate: (
      data: FormulePolitesseParametrageAnnuaireCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/FormulePolitesse`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name FormulePolitesseParametrageAnnuaireDelete
     * @summary Suppression logique d'une formule de politesse
     * @request DELETE:/ParametrageAnnuaire/FormulePolitesse/{code}
     * @secure
     */
    formulePolitesseParametrageAnnuaireDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/FormulePolitesse/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name IntitulesParametrageAnnuaireList
     * @summary Liste des Intitules
     * @request GET:/ParametrageAnnuaire/Intitules
     * @secure
     */
    intitulesParametrageAnnuaireList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/Intitules`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name IntituleParametrageAnnuaireCreate
     * @summary Créer  / Màj une Intitules
     * @request POST:/ParametrageAnnuaire/Intitule
     * @secure
     */
    intituleParametrageAnnuaireCreate: (data: IntituleParametrageAnnuaireCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/Intitule`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name IntituleParametrageAnnuaireDelete
     * @summary Suppression logique d'une formule de politesse
     * @request DELETE:/ParametrageAnnuaire/Intitule/{code}
     * @secure
     */
    intituleParametrageAnnuaireDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/Intitule/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name TypesMediaParametrageAnnuaireList
     * @summary Liste des Types de médias
     * @request GET:/ParametrageAnnuaire/TypesMedia
     * @secure
     */
    typesMediaParametrageAnnuaireList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/TypesMedia`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name TypeMediaParametrageAnnuaireCreate
     * @summary Créer  / Màj un media
     * @request POST:/ParametrageAnnuaire/TypeMedia
     * @secure
     */
    typeMediaParametrageAnnuaireCreate: (data: TypeMediaParametrageAnnuaireCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/TypeMedia`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name TypeMediaParametrageAnnuaireDelete
     * @summary Suppression logique d'une formule de politesse
     * @request DELETE:/ParametrageAnnuaire/TypeMedia/{code}
     * @secure
     */
    typeMediaParametrageAnnuaireDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/TypeMedia/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name FamilleAnnuaireParametrageAnnuaireCreate
     * @request POST:/ParametrageAnnuaire/FamilleAnnuaire
     * @secure
     */
    familleAnnuaireParametrageAnnuaireCreate: (
      data: FamilleAnnuaireParametrageAnnuaireCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/FamilleAnnuaire`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name FamilleAnnuaireParametrageAnnuaireList
     * @request GET:/ParametrageAnnuaire/FamilleAnnuaire
     * @secure
     */
    familleAnnuaireParametrageAnnuaireList: (params: RequestParams = {}) =>
      this.request<FamilleAnnuaire[], ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/FamilleAnnuaire`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name FamillesAnnuaireParametrageAnnuairePartialUpdate
     * @request PATCH:/ParametrageAnnuaire/FamillesAnnuaire/{code}
     * @secure
     */
    famillesAnnuaireParametrageAnnuairePartialUpdate: (
      code: string,
      data: FamillesAnnuaireParametrageAnnuairePartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/FamillesAnnuaire/${code}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name FamilleAnnuaireParametrageAnnuaireDelete
     * @request DELETE:/ParametrageAnnuaire/FamilleAnnuaire/{code}
     * @secure
     */
    familleAnnuaireParametrageAnnuaireDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/FamilleAnnuaire/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name CompteEchangeParametrageAnnuaireCreate
     * @request POST:/ParametrageAnnuaire/CompteEchange
     * @secure
     */
    compteEchangeParametrageAnnuaireCreate: (
      data: CompteEchangeParametrageAnnuaireCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/CompteEchange`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name ComptesEchangesParametrageAnnuaireList
     * @request GET:/ParametrageAnnuaire/ComptesEchanges
     * @secure
     */
    comptesEchangesParametrageAnnuaireList: (params: RequestParams = {}) =>
      this.request<CompteEchange[], ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/ComptesEchanges`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name CompteEchangeParametrageAnnuairePartialUpdate
     * @request PATCH:/ParametrageAnnuaire/CompteEchange/{id}
     * @secure
     */
    compteEchangeParametrageAnnuairePartialUpdate: (
      id: string,
      data: CompteEchangeParametrageAnnuairePartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/CompteEchange/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name CompteEchangeParametrageAnnuaireDelete
     * @request DELETE:/ParametrageAnnuaire/CompteEchange/{id}
     * @secure
     */
    compteEchangeParametrageAnnuaireDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/CompteEchange/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name RolesParametrageAnnuaireList
     * @summary Liste des roles
     * @request GET:/ParametrageAnnuaire/Roles
     * @secure
     */
    rolesParametrageAnnuaireList: (params: RequestParams = {}) =>
      this.request<PeiRole[], ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/Roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name RoleParametrageAnnuaireCreate
     * @summary Créer  / Màj un role
     * @request POST:/ParametrageAnnuaire/Role
     * @secure
     */
    roleParametrageAnnuaireCreate: (data: RoleParametrageAnnuaireCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/Role`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name RoleParametrageAnnuaireDelete
     * @summary Suppression d'une role
     * @request DELETE:/ParametrageAnnuaire/Role/{code}/{qualite}
     * @secure
     */
    roleParametrageAnnuaireDelete: (code: string, qualite: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/Role/${code}/${qualite}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name QualiteParametrageAnnuaireList
     * @summary Liste des qualités
     * @request GET:/ParametrageAnnuaire/Qualite
     * @secure
     */
    qualiteParametrageAnnuaireList: (params: RequestParams = {}) =>
      this.request<Qualite[], ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/Qualite`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name QualiteParametrageAnnuaireCreate
     * @summary Créer  / Màj une qualité
     * @request POST:/ParametrageAnnuaire/Qualite
     * @secure
     */
    qualiteParametrageAnnuaireCreate: (data: QualiteParametrageAnnuaireCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/Qualite`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name QualiteParametrageAnnuaireDelete
     * @summary Suppression d'une role
     * @request DELETE:/ParametrageAnnuaire/Qualite/{code}/{qualite}
     * @secure
     */
    qualiteParametrageAnnuaireDelete: (code: string, qualite: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/Qualite/${code}/${qualite}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name QualifiantsParametrageAnnuaireList
     * @summary Liste des qualifiants
     * @request GET:/ParametrageAnnuaire/Qualifiants
     * @secure
     */
    qualifiantsParametrageAnnuaireList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/Qualifiants`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name QualifiantParametrageAnnuaireCreate
     * @summary Créer  / Màj un qualifiant
     * @request POST:/ParametrageAnnuaire/Qualifiant
     * @secure
     */
    qualifiantParametrageAnnuaireCreate: (
      data: QualifiantParametrageAnnuaireCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/Qualifiant`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name QualifiantParametrageAnnuaireDelete
     * @summary Suppression d'un qualifiant
     * @request DELETE:/ParametrageAnnuaire/Qualifiant/{code}
     * @secure
     */
    qualifiantParametrageAnnuaireDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/Qualifiant/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name AssujettiParametrageAnnuaireList
     * @summary Liste des Assujetti TVA
     * @request GET:/ParametrageAnnuaire/Assujetti
     * @secure
     */
    assujettiParametrageAnnuaireList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/Assujetti`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name AssujettiParametrageAnnuaireCreate
     * @summary Créer  / Màj un Assujetti
     * @request POST:/ParametrageAnnuaire/Assujetti
     * @secure
     */
    assujettiParametrageAnnuaireCreate: (data: AssujettiParametrageAnnuaireCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageAnnuaire/Assujetti`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageAnnuaire
     * @name AssujettiParametrageAnnuaireDelete
     * @summary Suppression d'un Assujetti
     * @request DELETE:/ParametrageAnnuaire/Assujetti/{code}
     * @secure
     */
    assujettiParametrageAnnuaireDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageAnnuaire/Assujetti/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  parametrageBordereaux = {
    /**
     * No description
     *
     * @tags ParametrageBordereaux
     * @name SourceMandantParametrageBordereauxCreate
     * @request POST:/ParametrageBordereaux/SourceMandant
     * @secure
     */
    sourceMandantParametrageBordereauxCreate: (
      data: SourceMandantParametrageBordereauxCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageBordereaux/SourceMandant`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageBordereaux
     * @name SourcesMandantParametrageBordereauxList
     * @request GET:/ParametrageBordereaux/SourcesMandant
     * @secure
     */
    sourcesMandantParametrageBordereauxList: (params: RequestParams = {}) =>
      this.request<SourceMandant[], ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageBordereaux/SourcesMandant`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags ParametrageBordereaux
 * @name SourceMandantParametrageBordereauxPartialUpdate
 * @summary Modifier Liaison source Mandant
!!! Si le prestataire n'est pas précisé, cela modifiera les sources de tous les prestataires.
 * @request PATCH:/ParametrageBordereaux/SourceMandant/{id}
 * @secure
 */
    sourceMandantParametrageBordereauxPartialUpdate: (
      id: string,
      data: SourceMandantParametrageBordereauxPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageBordereaux/SourceMandant/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageBordereaux
     * @name SourceMandantParametrageBordereauxDelete
     * @request DELETE:/ParametrageBordereaux/SourceMandant/{id}/{prestataire}
     * @secure
     */
    sourceMandantParametrageBordereauxDelete: (id: string, prestataire: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageBordereaux/SourceMandant/${id}/${prestataire}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageBordereaux
     * @name BordereauxParametrageBordereauxCreate
     * @request POST:/ParametrageBordereaux/Bordereaux/{id}
     * @secure
     */
    bordereauxParametrageBordereauxCreate: (
      id: string,
      data: BordereauxParametrageBordereauxCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<SourceMandant[], ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageBordereaux/Bordereaux/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags ParametrageBordereaux
 * @name BordereauxParametrageBordereauxPartialUpdate
 * @summary Modifier Bordereaux de prix
!!! Si ni le code article ni la famille d'articles ne sont précisés, tous les bordereaux de la famille seront modifiés.
 * @request PATCH:/ParametrageBordereaux/Bordereaux/{id}
 * @secure
 */
    bordereauxParametrageBordereauxPartialUpdate: (
      id: string,
      data: BordereauxParametrageBordereauxPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageBordereaux/Bordereaux/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageBordereaux
     * @name BibliotequeDiversParametrageBordereauxCreate
     * @summary Créer  / Màj Biblioteque Divers
     * @request POST:/ParametrageBordereaux/BibliotequeDivers
     * @secure
     */
    bibliotequeDiversParametrageBordereauxCreate: (
      data: BibliotequeDiversParametrageBordereauxCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageBordereaux/BibliotequeDivers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageBordereaux
     * @name BibliotequeDiversParametrageBordereauxCreate2
     * @summary Supprimer Biblioteque Divers
     * @request POST:/ParametrageBordereaux/BibliotequeDivers/{code}
     * @originalName bibliotequeDiversParametrageBordereauxCreate
     * @duplicate
     * @secure
     */
    bibliotequeDiversParametrageBordereauxCreate2: (code: string, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageBordereaux/BibliotequeDivers/${code}`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  parametrageComptabilite = {
    /**
     * No description
     *
     * @tags ParametrageComptabilite
     * @name BanquesParametrageComptabiliteList
     * @request GET:/ParametrageComptabilite/Banques
     * @secure
     */
    banquesParametrageComptabiliteList: (params: RequestParams = {}) =>
      this.request<Banque[], ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageComptabilite/Banques`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageComptabilite
     * @name BanqueParametrageComptabiliteCreate
     * @request POST:/ParametrageComptabilite/Banque
     * @secure
     */
    banqueParametrageComptabiliteCreate: (
      data: BanqueParametrageComptabiliteCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageComptabilite/Banque`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageComptabilite
     * @name BanqueParametrageComptabilitePartialUpdate
     * @request PATCH:/ParametrageComptabilite/Banque/{id}
     * @secure
     */
    banqueParametrageComptabilitePartialUpdate: (
      id: string,
      data: BanqueParametrageComptabilitePartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageComptabilite/Banque/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageComptabilite
     * @name BanqueParametrageComptabiliteDelete
     * @request DELETE:/ParametrageComptabilite/Banque/{id}
     * @secure
     */
    banqueParametrageComptabiliteDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageComptabilite/Banque/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageComptabilite
     * @name ParametragesLogicielParametrageComptabiliteList
     * @request GET:/ParametrageComptabilite/ParametragesLogiciel
     * @secure
     */
    parametragesLogicielParametrageComptabiliteList: (params: RequestParams = {}) =>
      this.request<ParametrageLogiciel[], ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageComptabilite/ParametragesLogiciel`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageComptabilite
     * @name ParametrageLogicielParametrageComptabiliteCreate
     * @request POST:/ParametrageComptabilite/ParametrageLogiciel
     * @secure
     */
    parametrageLogicielParametrageComptabiliteCreate: (
      data: ParametrageLogicielParametrageComptabiliteCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageComptabilite/ParametrageLogiciel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageComptabilite
     * @name ParametrageLogicielParametrageComptabilitePartialUpdate
     * @request PATCH:/ParametrageComptabilite/ParametrageLogiciel/{code}
     * @secure
     */
    parametrageLogicielParametrageComptabilitePartialUpdate: (
      code: string,
      data: ParametrageLogicielParametrageComptabilitePartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageComptabilite/ParametrageLogiciel/${code}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageComptabilite
     * @name ParametrageLogicielParametrageComptabiliteDelete
     * @request DELETE:/ParametrageComptabilite/ParametrageLogiciel/{code}
     * @secure
     */
    parametrageLogicielParametrageComptabiliteDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageComptabilite/ParametrageLogiciel/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageComptabilite
     * @name ModesReglementParametrageComptabiliteList
     * @request GET:/ParametrageComptabilite/ModesReglement
     * @secure
     */
    modesReglementParametrageComptabiliteList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageComptabilite/ModesReglement`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageComptabilite
     * @name ModeReglementParametrageComptabiliteCreate
     * @summary Créer  / Modifier un mode de reglement
     * @request POST:/ParametrageComptabilite/ModeReglement
     * @secure
     */
    modeReglementParametrageComptabiliteCreate: (
      data: ModeReglementParametrageComptabiliteCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageComptabilite/ModeReglement`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageComptabilite
     * @name ModeReglementParametrageComptabiliteDelete
     * @summary Supprimer un mode de reglement
     * @request DELETE:/ParametrageComptabilite/ModeReglement/{code}
     * @secure
     */
    modeReglementParametrageComptabiliteDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageComptabilite/ModeReglement/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  parametrageDivers = {
    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name IntitulesEchangeInterneParametrageDiversList
     * @request GET:/ParametrageDivers/IntitulesEchangeInterne
     * @secure
     */
    intitulesEchangeInterneParametrageDiversList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageDivers/IntitulesEchangeInterne`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name IntituleEchangeInterneParametrageDiversCreate
     * @summary Créer  / Modifier intitule echange interne
     * @request POST:/ParametrageDivers/IntituleEchangeInterne
     * @secure
     */
    intituleEchangeInterneParametrageDiversCreate: (
      data: IntituleEchangeInterneParametrageDiversCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageDivers/IntituleEchangeInterne`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name IntituleEchangeInterneParametrageDiversDelete
     * @summary Supprimer intitule echange interne
     * @request DELETE:/ParametrageDivers/IntituleEchangeInterne/{code}
     * @secure
     */
    intituleEchangeInterneParametrageDiversDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageDivers/IntituleEchangeInterne/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name CategoriesPiecesJointesParametrageDiversList
     * @request GET:/ParametrageDivers/CategoriesPiecesJointes
     * @secure
     */
    categoriesPiecesJointesParametrageDiversList: (params: RequestParams = {}) =>
      this.request<EtatCategorieCodeLabelEnum[], ValidationProblemDetails>({
        path: `/ParametrageDivers/CategoriesPiecesJointes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name CategoriePieceJointeParametrageDiversCreate
     * @summary Créer  / Modifier Categorie Piece Jointe
     * @request POST:/ParametrageDivers/CategoriePieceJointe
     * @secure
     */
    categoriePieceJointeParametrageDiversCreate: (
      data: CategoriePieceJointeParametrageDiversCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageDivers/CategoriePieceJointe`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name CategoriePieceJointeParametrageDiversDelete
     * @summary Supprimer Categorie Piece Jointe
     * @request DELETE:/ParametrageDivers/CategoriePieceJointe/{code}
     * @secure
     */
    categoriePieceJointeParametrageDiversDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageDivers/CategoriePieceJointe/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name SousCategoriesPiecesJointesParametrageDiversList
     * @request GET:/ParametrageDivers/SousCategoriesPiecesJointes
     * @secure
     */
    sousCategoriesPiecesJointesParametrageDiversList: (params: RequestParams = {}) =>
      this.request<EtatCategorieCodeLabelEnum[], ValidationProblemDetails>({
        path: `/ParametrageDivers/SousCategoriesPiecesJointes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name SousCategoriePieceJointeParametrageDiversCreate
     * @summary Créer  / Modifier Sous-Categorie Piece Jointe
     * @request POST:/ParametrageDivers/SousCategoriePieceJointe
     * @secure
     */
    sousCategoriePieceJointeParametrageDiversCreate: (
      data: SousCategoriePieceJointeParametrageDiversCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageDivers/SousCategoriePieceJointe`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name SousCategoriePieceJointeParametrageDiversDelete
     * @summary Supprimer Categorie Piece Jointe
     * @request DELETE:/ParametrageDivers/SousCategoriePieceJointe/{code}
     * @secure
     */
    sousCategoriePieceJointeParametrageDiversDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageDivers/SousCategoriePieceJointe/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name ActionsGeneralesParametrageDiversList
     * @request GET:/ParametrageDivers/ActionsGenerales
     * @secure
     */
    actionsGeneralesParametrageDiversList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageDivers/ActionsGenerales`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name ActionGeneraleParametrageDiversCreate
     * @summary Créer  / Modifier action generale
     * @request POST:/ParametrageDivers/ActionGenerale
     * @secure
     */
    actionGeneraleParametrageDiversCreate: (
      data: ActionGeneraleParametrageDiversCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageDivers/ActionGenerale`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDivers
     * @name ActionGeneraleParametrageDiversDelete
     * @summary Supprimer action generale
     * @request DELETE:/ParametrageDivers/ActionGenerale/{code}
     * @secure
     */
    actionGeneraleParametrageDiversDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageDivers/ActionGenerale/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  parametrageDossier = {
    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name FamillesNatureSinistreParametrageDossierList
     * @request GET:/ParametrageDossier/FamillesNatureSinistre
     * @secure
     */
    famillesNatureSinistreParametrageDossierList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageDossier/FamillesNatureSinistre`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name FamilleNatureSinistreParametrageDossierCreate
     * @summary Créer  / Modifier famille nature sinistre
     * @request POST:/ParametrageDossier/FamilleNatureSinistre
     * @secure
     */
    familleNatureSinistreParametrageDossierCreate: (
      data: FamilleNatureSinistreParametrageDossierCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageDossier/FamilleNatureSinistre`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name FamilleNatureSinistreParametrageDossierDelete
     * @summary Supprimer famille nature sinistre
     * @request DELETE:/ParametrageDossier/FamilleNatureSinistre/{code}
     * @secure
     */
    familleNatureSinistreParametrageDossierDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageDossier/FamilleNatureSinistre/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name NaturesSinistreParametrageDossierList
     * @request GET:/ParametrageDossier/NaturesSinistre
     * @secure
     */
    naturesSinistreParametrageDossierList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageDossier/NaturesSinistre`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name NatureSinistreParametrageDossierCreate
     * @summary Créer  / Modifier nature sinistre
     * @request POST:/ParametrageDossier/NatureSinistre
     * @secure
     */
    natureSinistreParametrageDossierCreate: (
      data: NatureSinistreParametrageDossierCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageDossier/NatureSinistre`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name NatureSinistreParametrageDossierDelete
     * @summary Supprimer  nature sinistre
     * @request DELETE:/ParametrageDossier/NatureSinistre/{code}
     * @secure
     */
    natureSinistreParametrageDossierDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageDossier/NatureSinistre/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name TypesMissionParametrageDossierList
     * @request GET:/ParametrageDossier/TypesMission
     * @secure
     */
    typesMissionParametrageDossierList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageDossier/TypesMission`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name TypeMissionParametrageDossierCreate
     * @summary Créer  / Modifier type mission
     * @request POST:/ParametrageDossier/TypeMission
     * @secure
     */
    typeMissionParametrageDossierCreate: (
      data: TypeMissionParametrageDossierCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageDossier/TypeMission`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name TypeMissionParametrageDossierDelete
     * @summary Supprimer type mission
     * @request DELETE:/ParametrageDossier/TypeMission/{code}
     * @secure
     */
    typeMissionParametrageDossierDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageDossier/TypeMission/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name TypesAssureParametrageDossierList
     * @request GET:/ParametrageDossier/TypesAssure
     * @secure
     */
    typesAssureParametrageDossierList: (params: RequestParams = {}) =>
      this.request<IdLabel[], ValidationProblemDetails>({
        path: `/ParametrageDossier/TypesAssure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name TypeAssureParametrageDossierCreate
     * @summary Créer  / Modifier type assure
     * @request POST:/ParametrageDossier/TypeAssure
     * @secure
     */
    typeAssureParametrageDossierCreate: (data: TypeAssureParametrageDossierCreatePayload, params: RequestParams = {}) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageDossier/TypeAssure`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageDossier
     * @name TypeAssureParametrageDossierDelete
     * @summary Supprimer type assure
     * @request DELETE:/ParametrageDossier/TypeAssure/{code}
     * @secure
     */
    typeAssureParametrageDossierDelete: (code: number, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageDossier/TypeAssure/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  parametrageEvenement = {
    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name ModeleSmsParametrageEvenementList
     * @request GET:/ParametrageEvenement/ModeleSMS
     * @secure
     */
    modeleSmsParametrageEvenementList: (query: ModeleSmsParametrageEvenementListParams, params: RequestParams = {}) =>
      this.request<ModeleSMS[], ValidationProblemDetails>({
        path: `/ParametrageEvenement/ModeleSMS`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name ModeleSmsParametrageEvenementCreate
     * @request POST:/ParametrageEvenement/ModeleSMS
     * @secure
     */
    modeleSmsParametrageEvenementCreate: (
      data: ModeleSmsParametrageEvenementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/ParametrageEvenement/ModeleSMS`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name ModeleSmsParametrageEvenementPartialUpdate
     * @request PATCH:/ParametrageEvenement/ModeleSMS/{code}
     * @secure
     */
    modeleSmsParametrageEvenementPartialUpdate: (
      code: string,
      data: ModeleSmsParametrageEvenementPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageEvenement/ModeleSMS/${code}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name ModeleSmsParametrageEvenementDelete
     * @request DELETE:/ParametrageEvenement/ModeleSMS/{code}
     * @secure
     */
    modeleSmsParametrageEvenementDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageEvenement/ModeleSMS/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name CommentairesEvenementParametrageEvenementList
     * @request GET:/ParametrageEvenement/CommentairesEvenement
     * @secure
     */
    commentairesEvenementParametrageEvenementList: (params: RequestParams = {}) =>
      this.request<TypeCommentaireCodeLabelEnum[], ValidationProblemDetails>({
        path: `/ParametrageEvenement/CommentairesEvenement`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name CommentaireEvenementParametrageEvenementCreate
     * @summary Créer  / Modifier Commentaires événements
     * @request POST:/ParametrageEvenement/CommentaireEvenement
     * @secure
     */
    commentaireEvenementParametrageEvenementCreate: (
      data: CommentaireEvenementParametrageEvenementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageEvenement/CommentaireEvenement`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name CommentaireEvenementParametrageEvenementDelete
     * @summary Supprimer Commentaires événements
     * @request DELETE:/ParametrageEvenement/CommentaireEvenement/{code}
     * @secure
     */
    commentaireEvenementParametrageEvenementDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageEvenement/CommentaireEvenement/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name ModeleMailParametrageEvenementCreate
     * @request POST:/ParametrageEvenement/ModeleMail
     * @secure
     */
    modeleMailParametrageEvenementCreate: (
      data: ModeleMailParametrageEvenementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/ParametrageEvenement/ModeleMail`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name ModeleMailParametrageEvenementPartialUpdate
     * @request PATCH:/ParametrageEvenement/ModeleMail/{code}
     * @secure
     */
    modeleMailParametrageEvenementPartialUpdate: (
      code: string,
      data: ModeleMailParametrageEvenementPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageEvenement/ModeleMail/${code}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name ModeleMailParametrageEvenementDelete
     * @request DELETE:/ParametrageEvenement/ModeleMail/{code}
     * @secure
     */
    modeleMailParametrageEvenementDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageEvenement/ModeleMail/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name ModelesMailParametrageEvenementList
     * @request GET:/ParametrageEvenement/ModelesMail
     * @secure
     */
    modelesMailParametrageEvenementList: (
      query: ModelesMailParametrageEvenementListParams,
      params: RequestParams = {},
    ) =>
      this.request<ModeleMail[], ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageEvenement/ModelesMail`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name TypesRendezVousRdfParametrageEvenementList
     * @request GET:/ParametrageEvenement/TypesRendezVousRDF
     * @secure
     */
    typesRendezVousRdfParametrageEvenementList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageEvenement/TypesRendezVousRDF`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name TypeRendezVousRdfParametrageEvenementCreate
     * @summary Créer  / Modifier type rendez-vous RDF
     * @request POST:/ParametrageEvenement/TypeRendezVousRDF
     * @secure
     */
    typeRendezVousRdfParametrageEvenementCreate: (
      data: TypeRendezVousRdfParametrageEvenementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageEvenement/TypeRendezVousRDF`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name TypeRendezVousRdfParametrageEvenementDelete
     * @summary Supprimer type rendez-vous RDF
     * @request DELETE:/ParametrageEvenement/TypeRendezVousRDF/{code}
     * @secure
     */
    typeRendezVousRdfParametrageEvenementDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageEvenement/TypeRendezVousRDF/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name TypesRendezVousTravauxParametrageEvenementList
     * @request GET:/ParametrageEvenement/TypesRendezVousTravaux
     * @secure
     */
    typesRendezVousTravauxParametrageEvenementList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/ParametrageEvenement/TypesRendezVousTravaux`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name TypeRendezVousTravauxParametrageEvenementCreate
     * @summary Créer  / Modifier type rendez-vous Travaux
     * @request POST:/ParametrageEvenement/TypeRendezVousTravaux
     * @secure
     */
    typeRendezVousTravauxParametrageEvenementCreate: (
      data: TypeRendezVousTravauxParametrageEvenementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageEvenement/TypeRendezVousTravaux`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name TypeRendezVousTravauxParametrageEvenementDelete
     * @summary Supprimer type rendez-vous Travaux
     * @request DELETE:/ParametrageEvenement/TypeRendezVousTravaux/{code}
     * @secure
     */
    typeRendezVousTravauxParametrageEvenementDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageEvenement/TypeRendezVousTravaux/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name EvenementsExterieursParametrageEvenementList
     * @request GET:/ParametrageEvenement/EvenementsExterieurs
     * @secure
     */
    evenementsExterieursParametrageEvenementList: (params: RequestParams = {}) =>
      this.request<EtatEvenementExterieurCodeLabelEnum[], ValidationProblemDetails>({
        path: `/ParametrageEvenement/EvenementsExterieurs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name EvenementExterieurParametrageEvenementCreate
     * @summary Créer  / Modifier événement extérieur
     * @request POST:/ParametrageEvenement/EvenementExterieur
     * @secure
     */
    evenementExterieurParametrageEvenementCreate: (
      data: EvenementExterieurParametrageEvenementCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageEvenement/EvenementExterieur`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageEvenement
     * @name EvenementExterieurParametrageEvenementDelete
     * @summary Supprimer événement extérieur
     * @request DELETE:/ParametrageEvenement/EvenementExterieur/{code}
     * @secure
     */
    evenementExterieurParametrageEvenementDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageEvenement/EvenementExterieur/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  parametrageVente = {
    /**
     * No description
     *
     * @tags ParametrageVente
     * @name StatutsAlternatifCommandeParametrageVenteList
     * @request GET:/ParametrageVente/StatutsAlternatifCommande
     * @secure
     */
    statutsAlternatifCommandeParametrageVenteList: (params: RequestParams = {}) =>
      this.request<CodeLabelCouleur[], ValidationProblemDetails>({
        path: `/ParametrageVente/StatutsAlternatifCommande`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageVente
     * @name StatutAlternatifCommandeParametrageVenteCreate
     * @summary Créer  / Modifier statut commande
     * @request POST:/ParametrageVente/StatutAlternatifCommande
     * @secure
     */
    statutAlternatifCommandeParametrageVenteCreate: (
      data: StatutAlternatifCommandeParametrageVenteCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageVente/StatutAlternatifCommande`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageVente
     * @name StatutAlternatifCommandeParametrageVenteDelete
     * @summary Supprimer statut commande
     * @request DELETE:/ParametrageVente/StatutAlternatifCommande/{code}
     * @secure
     */
    statutAlternatifCommandeParametrageVenteDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageVente/StatutAlternatifCommande/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageVente
     * @name TauxTvaParametrageVenteCreate
     * @request POST:/ParametrageVente/TauxTVA
     * @secure
     */
    tauxTvaParametrageVenteCreate: (data: TauxTvaParametrageVenteCreatePayload, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails>({
        path: `/ParametrageVente/TauxTVA`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageVente
     * @name TauxTvaParametrageVentePartialUpdate
     * @request PATCH:/ParametrageVente/TauxTVA/{code}
     * @secure
     */
    tauxTvaParametrageVentePartialUpdate: (
      code: string,
      data: TauxTvaParametrageVentePartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageVente/TauxTVA/${code}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageVente
     * @name StatutsAlternatifDevisParametrageVenteList
     * @request GET:/ParametrageVente/StatutsAlternatifDevis
     * @secure
     */
    statutsAlternatifDevisParametrageVenteList: (params: RequestParams = {}) =>
      this.request<CodeLabelCouleur[], ValidationProblemDetails>({
        path: `/ParametrageVente/StatutsAlternatifDevis`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageVente
     * @name StatutAlternatifDevisParametrageVenteCreate
     * @summary Créer  / Modifier statut devis
     * @request POST:/ParametrageVente/StatutAlternatifDevis
     * @secure
     */
    statutAlternatifDevisParametrageVenteCreate: (
      data: StatutAlternatifDevisParametrageVenteCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | ValidationProblemDetails>({
        path: `/ParametrageVente/StatutAlternatifDevis`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ParametrageVente
     * @name StatutAlternatifDevisParametrageVenteDelete
     * @summary Supprimer statut commande
     * @request DELETE:/ParametrageVente/StatutAlternatifDevis/{code}
     * @secure
     */
    statutAlternatifDevisParametrageVenteDelete: (code: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/ParametrageVente/StatutAlternatifDevis/${code}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  bordereau = {
    /**
     * No description
     *
     * @tags Recherche
     * @name RechercheBordereauList
     * @request GET:/Bordereau/Recherche
     * @secure
     */
    rechercheBordereauList: (query: RechercheBordereauListParams, params: RequestParams = {}) =>
      this.request<Bordereau[], ValidationProblemDetails>({
        path: `/Bordereau/Recherche`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  entreprise = {
    /**
     * No description
     *
     * @tags Recherche
     * @name RechercheEntrepriseList
     * @request GET:/Entreprise/Recherche
     * @secure
     */
    rechercheEntrepriseList: (query: RechercheEntrepriseListParams, params: RequestParams = {}) =>
      this.request<Etablissement[], ValidationProblemDetails>({
        path: `/Entreprise/Recherche`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  rendezVous = {
    /**
     * No description
     *
     * @tags RendezVous
     * @name MetreurRendezVousPartialUpdate
     * @request PATCH:/RendezVous/Metreur/{id}
     * @secure
     */
    metreurRendezVousPartialUpdate: (
      id: string,
      data: MetreurRendezVousPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/RendezVous/Metreur/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name TravauxRendezVousPartialUpdate
     * @request PATCH:/RendezVous/Travaux/{id}
     * @secure
     */
    travauxRendezVousPartialUpdate: (
      id: string,
      data: TravauxRendezVousPartialUpdatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/RendezVous/Travaux/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name RendezVousCreate
     * @summary Récupérer la liste des RDV
     * @request POST:/RendezVous
     * @secure
     */
    rendezVousCreate: (data: RendezVousCreatePayload, params: RequestParams = {}) =>
      this.request<RendezVous[], ValidationProblemDetails | ProblemDetails>({
        path: `/RendezVous`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RendezVous
     * @name RendezVousDelete
     * @request DELETE:/RendezVous/{id}
     * @secure
     */
    rendezVousDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, ValidationProblemDetails | ProblemDetails>({
        path: `/RendezVous/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  selectList = {
    /**
     * No description
     *
     * @tags SelectList
     * @name GetQuestionsExperienceAssureList
     * @summary Liste des questions à poser à l'assuré
     * @request GET:/SelectList/GetQuestionsExperienceAssure
     * @secure
     */
    getQuestionsExperienceAssureList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/GetQuestionsExperienceAssure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesDeLigneDevisList
     * @summary Liste des types de ligne pour les devis
     * @request GET:/SelectList/TypesDeLigneDevis
     * @secure
     */
    typesDeLigneDevisList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/TypesDeLigneDevis`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TarifsList
     * @summary Liste des tarifs les devis
     * @request GET:/SelectList/Tarifs
     * @secure
     */
    tarifsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/Tarifs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name UnitesMesureList
     * @summary Liste des unités de mesure pour les devis
     * @request GET:/SelectList/UnitesMesure
     * @secure
     */
    unitesMesureList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails | ProblemDetails>({
        path: `/SelectList/UnitesMesure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TauxTvaList
     * @summary Liste des taux de TVA
     * @request GET:/SelectList/TauxTVA
     * @secure
     */
    tauxTvaList: (params: RequestParams = {}) =>
      this.request<TauxTVA[], ValidationProblemDetails>({
        path: `/SelectList/TauxTVA`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name StatutsDevisList
     * @request GET:/SelectList/StatutsDevis
     * @secure
     */
    statutsDevisList: (params: RequestParams = {}) =>
      this.request<StatutDevisEnumLabel[], ValidationProblemDetails>({
        path: `/SelectList/StatutsDevis`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name StatutsDossierList
     * @request GET:/SelectList/StatutsDossier
     * @secure
     */
    statutsDossierList: (params: RequestParams = {}) =>
      this.request<StatutDossier[], ValidationProblemDetails>({
        path: `/SelectList/StatutsDossier`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name DepartementsList
     * @request GET:/SelectList/Departements
     * @secure
     */
    departementsList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/Departements`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CompagniesDAssuranceList
     * @request GET:/SelectList/CompagniesDAssurance
     * @secure
     */
    compagniesDAssuranceList: (params: RequestParams = {}) =>
      this.request<CompagnieAssurance[], ValidationProblemDetails>({
        path: `/SelectList/CompagniesDAssurance`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesSinistresList
     * @request GET:/SelectList/NaturesSinistres
     * @secure
     */
    naturesSinistresList: (params: RequestParams = {}) =>
      this.request<NatureSinistre[], ValidationProblemDetails>({
        path: `/SelectList/NaturesSinistres`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NaturesCausesDetail
     * @request GET:/SelectList/{dossier}/NaturesCauses
     * @secure
     */
    naturesCausesDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<NestedCodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/${dossier}/NaturesCauses`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name CorpsMetierList
     * @request GET:/SelectList/CorpsMetier
     * @secure
     */
    corpsMetierList: (params: RequestParams = {}) =>
      this.request<IdLabel[], ValidationProblemDetails>({
        path: `/SelectList/CorpsMetier`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ContratEntrepriseList
     * @request GET:/SelectList/ContratEntreprise
     * @secure
     */
    contratEntrepriseList: (params: RequestParams = {}) =>
      this.request<IdLabel[], ValidationProblemDetails>({
        path: `/SelectList/ContratEntreprise`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name BordereauxCabinetList
     * @request GET:/SelectList/Cabinet/Bordereaux
     * @secure
     */
    bordereauxCabinetList: (params: RequestParams = {}) =>
      this.request<Bordereau[], ValidationProblemDetails>({
        path: `/SelectList/Cabinet/Bordereaux`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name BibliothequeDiversList
     * @request GET:/SelectList/BibliothequeDivers
     * @secure
     */
    bibliothequeDiversList: (params: RequestParams = {}) =>
      this.request<BibliothequeDivers[], ValidationProblemDetails>({
        path: `/SelectList/BibliothequeDivers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesDocumentsJuridiquesList
     * @request GET:/SelectList/TypesDocumentsJuridiques
     * @secure
     */
    typesDocumentsJuridiquesList: (params: RequestParams = {}) =>
      this.request<DocumentJuridiqueType[], ValidationProblemDetails>({
        path: `/SelectList/TypesDocumentsJuridiques`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name PostesContactsList
     * @request GET:/SelectList/PostesContacts
     * @secure
     */
    postesContactsList: (params: RequestParams = {}) =>
      this.request<string[], ValidationProblemDetails>({
        path: `/SelectList/PostesContacts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesPrestationList
     * @request GET:/SelectList/TypesPrestation
     * @secure
     */
    typesPrestationList: (params: RequestParams = {}) =>
      this.request<TypePrestationEnumLabel[], ValidationProblemDetails>({
        path: `/SelectList/TypesPrestation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MotifsRefusFactureList
     * @request GET:/SelectList/MotifsRefusFacture
     * @secure
     */
    motifsRefusFactureList: (params: RequestParams = {}) =>
      this.request<IdLabel[], ValidationProblemDetails>({
        path: `/SelectList/MotifsRefusFacture`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EtatsFactureList
     * @request GET:/SelectList/EtatsFacture
     * @secure
     */
    etatsFactureList: (params: RequestParams = {}) =>
      this.request<EtatFactureEnumLabel, ValidationProblemDetails>({
        path: `/SelectList/EtatsFacture`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EtatsDevisList
     * @request GET:/SelectList/EtatsDevis
     * @secure
     */
    etatsDevisList: (params: RequestParams = {}) =>
      this.request<EtatDevisEnumLabel, ValidationProblemDetails>({
        path: `/SelectList/EtatsDevis`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EtatsCommandesList
     * @request GET:/SelectList/EtatsCommandes
     * @secure
     */
    etatsCommandesList: (params: RequestParams = {}) =>
      this.request<EtatCommandeEnumLabel, ValidationProblemDetails>({
        path: `/SelectList/EtatsCommandes`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name MetreursDossierDetail
     * @request GET:/SelectList/Dossier/{dossier}/Metreurs
     * @secure
     */
    metreursDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/Dossier/${dossier}/Metreurs`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TechniciensDossierDetail
     * @request GET:/SelectList/Dossier/{dossier}/Techniciens
     * @secure
     */
    techniciensDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/Dossier/${dossier}/Techniciens`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name GestionnairesDossierDetail
     * @request GET:/SelectList/Dossier/{dossier}/Gestionnaires
     * @secure
     */
    gestionnairesDossierDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/Dossier/${dossier}/Gestionnaires`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypesActivitesList
     * @request GET:/SelectList/TypesActivites
     * @secure
     */
    typesActivitesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/TypesActivites`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name EntitesMissionnablesList
     * @request GET:/SelectList/EntitesMissionnables
     * @secure
     */
    entitesMissionnablesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/EntitesMissionnables`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeAssureList
     * @request GET:/SelectList/TypeAssure
     * @secure
     */
    typeAssureList: (params: RequestParams = {}) =>
      this.request<IdLabel[], ValidationProblemDetails>({
        path: `/SelectList/TypeAssure`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name PaysList
     * @request GET:/SelectList/Pays
     * @secure
     */
    paysList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/Pays`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypePieceList
     * @request GET:/SelectList/TypePiece
     * @secure
     */
    typePieceList: (params: RequestParams = {}) =>
      this.request<TypePiece[], ValidationProblemDetails>({
        path: `/SelectList/TypePiece`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name QualitesList
     * @request GET:/SelectList/Qualites
     * @secure
     */
    qualitesList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/Qualites`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name FamillesArticleList
     * @request GET:/SelectList/FamillesArticle
     * @secure
     */
    famillesArticleList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/FamillesArticle`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name TypeCommentaireList
     * @request GET:/SelectList/TypeCommentaire
     * @secure
     */
    typeCommentaireList: (params: RequestParams = {}) =>
      this.request<TypeCommentaireEnumLabel[], ValidationProblemDetails>({
        path: `/SelectList/TypeCommentaire`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name RisquesAggravesList
     * @request GET:/SelectList/RisquesAggraves
     * @secure
     */
    risquesAggravesList: (params: RequestParams = {}) =>
      this.request<RisqueAggraveEnumLabel[], ValidationProblemDetails>({
        path: `/SelectList/RisquesAggraves`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NatureOperationList
     * @request GET:/SelectList/NatureOperation
     * @secure
     */
    natureOperationList: (params: RequestParams = {}) =>
      this.request<NatureOperationEnumLabel[], ValidationProblemDetails>({
        path: `/SelectList/NatureOperation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name ComplexiteRdfList
     * @request GET:/SelectList/ComplexiteRDF
     * @secure
     */
    complexiteRdfList: (params: RequestParams = {}) =>
      this.request<ComplexiteDDEEnumLabel[], ValidationProblemDetails>({
        path: `/SelectList/ComplexiteRDF`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name NatureInscriptionList
     * @request GET:/SelectList/NatureInscription
     * @secure
     */
    natureInscriptionList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/NatureInscription`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SelectList
     * @name StatutJuridiqueList
     * @request GET:/SelectList/StatutJuridique
     * @secure
     */
    statutJuridiqueList: (params: RequestParams = {}) =>
      this.request<CodeLabel[], ValidationProblemDetails>({
        path: `/SelectList/StatutJuridique`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  sinapps = {
    /**
     * No description
     *
     * @tags Sinapps
     * @name CompteRenduVisiteSinappsCreate
     * @summary Déposer un compte rendu après le rendez-vous métreur
     * @request POST:/Sinapps/{dossier}/CompteRenduVisite
     * @secure
     */
    compteRenduVisiteSinappsCreate: (
      dossier: string,
      data: CompteRenduVisiteSinappsCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Sinapps/${dossier}/CompteRenduVisite`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name CompteRenduVisiteSinappsDetail
     * @summary Récupérer le compte rendu d'un dossier
     * @request GET:/Sinapps/{dossier}/CompteRenduVisite
     * @secure
     */
    compteRenduVisiteSinappsDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CompteRenduVisite, ValidationProblemDetails>({
        path: `/Sinapps/${dossier}/CompteRenduVisite`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name CompteRenduRdfSinappsCreate
     * @request POST:/Sinapps/{dossier}/CompteRenduRDF
     * @secure
     */
    compteRenduRdfSinappsCreate: (
      dossier: string,
      data: CompteRenduRdfSinappsCreatePayload,
      params: RequestParams = {},
    ) =>
      this.request<void, ValidationProblemDetails>({
        path: `/Sinapps/${dossier}/CompteRenduRDF`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sinapps
     * @name CompteRenduRdfSinappsDetail
     * @summary Récupérer le compte rendu RDF d'un dossier
     * @request GET:/Sinapps/{dossier}/CompteRenduRDF
     * @secure
     */
    compteRenduRdfSinappsDetail: (dossier: string, params: RequestParams = {}) =>
      this.request<CompteRenduRDF, ValidationProblemDetails>({
        path: `/Sinapps/${dossier}/CompteRenduRDF`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
